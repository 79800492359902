import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AlertMessageService } from '@core/services';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import {
  ConnectionState,
  GetAllConnections,
  SetSelectedConnection,
} from 'app/state/connection';
import {
  ConnectionDataStore,
  ConnectionType,
  FrequencyListEnum,
} from '@core/enums';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { Select, Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { BoxContentPickerComponent } from '@shared/components/box-content-picker/box-content-picker.component';
import { ComponentType } from '@angular/cdk/portal';
import { 
  ConnectionModel, 
  ScheduleModel, 
  MprFolderPathCollection, 
  MPRFolderModel 
} from '@shared/interfaces';
import { MatDialog } from '@angular/material/dialog';
import { catchError, Observable, of, throwError } from 'rxjs';
import {
  SetScheduleFormData,
  EditSchedule,
} from 'app/state/uploads/schedule/schedule.action';
import { scheduleDateValidator } from '@shared/validators';
import { UntilDestroy } from '@ngneat/until-destroy';
import * as frequencyConstants from '@shared/components/schedule-form/frequency-constants';
import { S3ContentPickerComponent } from '@shared';
import { ScheduleState } from 'app/state/uploads/schedule/schedule.state';
import { environment } from '@env/environment';
import { SkipMainContentService } from '@shared/services';
import { MatSelect } from '@angular/material/select';
import * as constants from '@core/constants';
import { LoggedinUserState, FeatureFlagsState, UserProjectState } from 'app/state';
import { UserProject } from '@theme/interfaces';
import { ProjectSurveyState , ProjectSurveyModel, LoadProjectSurveys} from 'app/state/project-survey';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-edit-schedule',
  templateUrl: './edit-schedule.component.html',
  styleUrls: ['./edit-schedule.component.scss'],
})
export class EditScheduleComponent implements OnInit {
  @Select(ConnectionState.getConnectionLoading)
  public connectionLoading$?: Observable<boolean>;
  @Select(ConnectionState.getAllActiveIngestionConnections)
  public connections$!: Observable<ConnectionModel[]>;
  @Select(ConnectionState.getConnectionsLoading)
  public connectionsLoading$?: Observable<boolean>;
  @Output() public proceedToMetaData = new EventEmitter<any>();
  @Select(ProjectSurveyState.GetProjectSurveyLoader)
  public projectSurveyLoader$?: Observable<boolean>;
  @Select(ProjectSurveyState.GetProjectSurveys)
  public projectSurveys$!: Observable<ProjectSurveyModel[] | []>;
  @ViewChild('typeOfDataSource') public skipper!: MatSelect;
  public ckanMetaDataUrl = '';
  public connectionList: ConnectionModel[] = [];
  public connectionType = ConnectionType;
  public dL_S3Connection = constants.DL_S3_CONNECTION;
  public daysOfTheMonth = frequencyConstants.daysOfTheMonth;
  public daysOfTheWeek = frequencyConstants.daysOfTheWeek;
  public defaultValue: ConnectionModel = {
    connectionId: '',
    connectionName: '',
    connectionStatus: '',
    createdBy: '',
    createdByName: '',
    createdDate: '',
    connectionType: ConnectionType.INGESTION,
    dataStore: ConnectionDataStore.LOCAL,
    emailId: '',
    modifiedBy: '',
    modifiedDate: '',
    projectId: '',
  };
  public displayConnectionList: ConnectionModel[] = [];
  public displayDestinationPath = '';
  public editScheduleForm: FormGroup;
  public editScheduleObj!: ScheduleModel;
  public externalConnectionId = '';
  public forstaConnection = constants.FORSTA_SFTP_CONNECTION;
  public frequencyList = Object.values(FrequencyListEnum);
  public frequencyListEnum = FrequencyListEnum;
  public hideRequiredMarker = true;
  public hoursOfTheDay = frequencyConstants.hoursOfTheDay;
  public isloggedinUserSchedule = true;
  public projectSurveys: ProjectSurveyModel[] = [];
  public returnPath: string;
  public scheduleId!: string;
  public scheduleType = '';
  public selectedConnection: ConnectionModel = this.defaultValue;
  public selectedProjectId = '';
  public showForstaInDropdown = false;
  private selectedProject!: UserProject;
  
  constructor(
    private alertMsgService: AlertMessageService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private store: Store,
    private dialog: MatDialog,
    private skipMainContentService: SkipMainContentService,
  ) {
    this.returnPath = route.snapshot.data['back'];
    this.scheduleType = this.router.url.includes('staging')? 'staging': '';
    this.editScheduleForm = this.fb.group({
      connectionId: ['', Validators.required],
      scheduleName: ['', Validators.required],
      folderId: [''],
      folderPath: ['', Validators.required],
      frequency: ['', Validators.required],
      hourOfTheDay: [''],
      frequencyDetail: [''],
    });
    if(this.scheduleType === 'staging') {
      this.editScheduleForm.addControl('stagingTargetPath', new FormControl('', Validators.required));
    }
    this.editScheduleForm.addControl('surveyId', new FormControl('', Validators.required));
    this.store.dispatch(new GetAllConnections());
  }
  

  public frequencyChanged(resetValue = true): void {
    const formControl = this.editScheduleForm.controls;
    if (resetValue) {
      this.clearValueAndValidators(formControl['hourOfTheDay']);
      this.clearValueAndValidators(formControl['frequencyDetail']);
    }
    switch (formControl['frequency'].value) {
      case this.frequencyList[0]:
        formControl['hourOfTheDay'].setValue('01');
        break;
      case this.frequencyList[1]:
        formControl['hourOfTheDay'].setValidators(Validators.required);
        break;
      case this.frequencyList[2]:
      case this.frequencyList[3]:
        formControl['hourOfTheDay'].setValidators(Validators.required);
        formControl['frequencyDetail'].setValidators(Validators.required);
        break;
      case this.frequencyList[4]:
        formControl['hourOfTheDay'].setValidators(Validators.required);
        formControl['frequencyDetail'].setValidators([
          Validators.required,
          scheduleDateValidator(),
        ]);
        break;
      default:
        break;
    }
    formControl['hourOfTheDay'].updateValueAndValidity();
    formControl['frequencyDetail'].updateValueAndValidity();
  }

  public goToCkanMetadata(): void {
    window.open(this.ckanMetaDataUrl);
  }

  public isForstaConnectionSelected(): boolean {
    const connectionId = this.editScheduleForm.get('connectionId')?.value;
    return connectionId === constants?.FORSTA_SFTP_CONNECTION?.connectionId;
  }
  
  public isStagingForstaConnection(): boolean{
    return this.scheduleType === 'staging' && this.isForstaConnectionSelected();
  }

  public isSubmitDisabled(): boolean {
    return !this.editScheduleForm.valid || Object.values(this.editScheduleForm.value).every(val =>
      Object.values(this.editScheduleObj).includes(val)
    );
  }
  
  ngOnInit(): void {
    this.selectedProjectId = this.store.selectSnapshot(UserProjectState.getSelectedProjectId);
    this.selectedProject = this.store.selectSnapshot(
      UserProjectState.getSelectedProject
    );
    if (this.showForstaConnection()) {
      const requestHeaders: MprHttpHeaderModal = {};
      requestHeaders[HeaderParams.PROJECTID] = this.selectedProjectId;
      requestHeaders[HeaderParams.ROLENAME] = this.selectedProject.roleName;
      this.store.dispatch(new LoadProjectSurveys(requestHeaders));
      this.projectSurveys$.subscribe((surveys: ProjectSurveyModel[]) => {
        this.projectSurveys = [...surveys];
        this.sortProjectsSurveysBySurveyId();
        if (this.showForstaConnection() && this.projectSurveys.length !== 0)
        {
          this.showForstaInDropdown = true;
        }
      });
    }
    this.connections$
    .subscribe(( connections) => {
      this.connectionList = [...connections];
      this.displayConnectionList = this.connectionList;
      if(!this.selectedConnection){
        const externalConnection: ConnectionModel = this.defaultValue;
        externalConnection.connectionId = this.editScheduleObj.connectionId || '';
        externalConnection.connectionName = this.editScheduleObj.connectionName || '';
        this.externalConnectionId = externalConnection.connectionId;
        this.displayConnectionList.push(externalConnection);
      }
    });
    this.route.params.subscribe((params) => {
      this.scheduleId = params['scheduleId'];
    });
    this.setupSurveyIdValidation();
    this.skipMainContentService.skip$.subscribe((res) =>{
      this.skipper.focus();
    })
    this.store
      .select(ScheduleState.getAllSchedules)
      .subscribe((schedules: ScheduleModel[]) => {
        this.editScheduleObj = schedules.filter(
          (e: { scheduleId: string }) => e.scheduleId === this.scheduleId
        )[0];
        this.editScheduleForm.get('surveyId')?.valueChanges.subscribe((surveyId) => {
          if(surveyId !== ''){
            const formControl = this.editScheduleForm.controls;
            formControl['folderPath'].setValue(surveyId);
            formControl['folderId'].setValue(surveyId);
          }
        });
        const loggedInUserId = this.store.selectSnapshot(
          LoggedinUserState.getLoggedInUserId
        );
        this.isloggedinUserSchedule = loggedInUserId === this.editScheduleObj.userId;
        this.selectedConnection = this.connectionList.filter(
          (e) => e.connectionId === this.editScheduleObj.connectionId
        )[0];
        if(!this.selectedConnection){
          if(this.editScheduleObj.connectionId === this.dL_S3Connection.connectionId){
            this.selectedConnection = this.dL_S3Connection;
          } else if(this.editScheduleObj.connectionId === this.forstaConnection.connectionId){
            this.selectedConnection = this.forstaConnection;
          }
        }
        this.ckanMetaDataUrl = `${environment.ckanUrl}${'/dataset/'}${this.editScheduleObj.datasetName}`;
        this.frequencyChanged(false);
        this.displayConnectionList = this.connectionList;
        if (this.selectedConnection)
        this.store.dispatch(new SetSelectedConnection(this.selectedConnection));
        else {
          const externalConnection: ConnectionModel = this.defaultValue;
          externalConnection.connectionId = this.editScheduleObj.connectionId || '';
          externalConnection.connectionName = this.editScheduleObj.connectionName || '';
          this.externalConnectionId = externalConnection.connectionId;
          this.displayConnectionList.push(externalConnection);
        }
        this.editScheduleForm.patchValue(this.editScheduleObj);
        if (this.editScheduleObj) {
          if(this.editScheduleObj.connectionId === this.forstaConnection.connectionId){
            this.editScheduleForm.patchValue({
              surveyId: this.editScheduleObj.folderId,
            });
          }
        }
      });
  }

  public setSelectedConnection(): void {
    const formControl = this.editScheduleForm.controls;
    const selectedConnectionId = formControl['connectionId'].value;
    if(selectedConnectionId === this.forstaConnection.connectionId ){
      this.selectedConnection = this.forstaConnection
    } else if(selectedConnectionId === this.dL_S3Connection.connectionId){
      this.selectedConnection = this.dL_S3Connection
    } else {
      const connection: ConnectionModel =
        this.connectionList.find(
          (connectionObject) =>
            connectionObject.connectionId === selectedConnectionId
        ) ?? this.defaultValue;
      this.selectedConnection = connection;
      }
    formControl['surveyId'].setValue('');
    formControl['folderPath'].setValue('');
    // formControl['folderId'].setValue('');
    this.store.dispatch(new SetSelectedConnection(this.selectedConnection));
  }

  public showDestinationFolderPickerDialog(): void {
    this.dialog
      .open(S3ContentPickerComponent, {
        data: {
          pickerType: 'folder',
          selectedProject: this.selectedProject,
          showDefaultProjectBucket: true,
        },
      })
      .afterClosed()
      .subscribe((selectedFolder) => {
        const formControl = this.editScheduleForm.controls;
        if (selectedFolder) {
           this.displayDestinationPath = selectedFolder
            .split('/')
            .slice(1)
            .join('/')
            .slice(0, -1);
            formControl['stagingTargetPath'].setValue(this.displayDestinationPath);
            formControl['stagingTargetPath'].updateValueAndValidity();
          }
      });
  }

  public showFilePicker(): void {
    switch (this.selectedConnection.dataStore) {
      case ConnectionDataStore.BOX:
        this.showPickerDialog(BoxContentPickerComponent);
        break;
      case ConnectionDataStore.S3:
      case ConnectionDataStore.DL_S3:
        this.showPickerDialog(S3ContentPickerComponent);
        break;
      default:
        break;
    }
  }

   public showForstaConnection(): boolean {
    return ( this.scheduleType === 'staging' &&
      this.store
        .selectSnapshot(FeatureFlagsState.returnFeatureFlags)
        .isForstaFeatureFlagEnabled.toLowerCase() === 'true'
    );
  }

  public showScheduleForm(loaders: any) : boolean {
    return this.scheduleType === 'staging' ? !loaders.connections && (this.showForstaConnection() && !loaders.projectSurveyLoader) : true;
  }

  public submit(): void {
    this.store
      .dispatch(
        new EditSchedule(
          this.editScheduleObj.scheduleId,
          this.editScheduleForm.value
        )
      )
      .pipe(catchError((err) => {
        this.alertMsgService.error({body : err.error.message})
        return of()
      }))
      .subscribe(() => {
        this.alertMsgService.success({ body: 'Schedule Updated Successfully' });
        const navigatePath = this.scheduleType === 'staging' ? '/staging/schedule' : '/uploads/schedule';
        setTimeout(() => this.router.navigate([navigatePath]), 1000);
      });
  }

  public validateAndProceedToMetaData(): void {
    if (this.editScheduleForm.valid) {
      this.store.dispatch(
        new SetScheduleFormData(this.editScheduleForm.getRawValue())
      );
      this.proceedToMetaData.emit();
    } else {
      this.editScheduleForm.markAllAsTouched();
    }
  }

  private clearValueAndValidators(field: AbstractControl): void {
    field.setValue('');
    field.markAsPristine();
    field.markAsUntouched();
    field.clearValidators();
  }

  private createFolderPath(selectedItems: MPRFolderModel[]): void {
    let folderPath = '';
    selectedItems[0].path_collection.entries.forEach(
      (path: MprFolderPathCollection) => {
        folderPath = `${folderPath}/${path.name}`;
      }
    );
    folderPath = `${folderPath}/${selectedItems[0].name}`;
    this.editScheduleForm.controls['folderPath'].setValue(folderPath);
    this.editScheduleForm.controls['folderId'].setValue(selectedItems[0].id);
  }

  private createS3FolderPath(folderPath: string): void {
    this.editScheduleForm.patchValue({ 'folderId': folderPath, folderPath });
  }

  private setupSurveyIdValidation(): void {
    this.editScheduleForm.get('connectionId')?.valueChanges.subscribe((connectionId) => {
      const isForstaConnection = (connectionId === constants.FORSTA_SFTP_CONNECTION.connectionId);
      if (this.scheduleType === 'staging' && isForstaConnection) {
        this.editScheduleForm.get('surveyId')?.setValidators(Validators.required);
      } else {
        this.editScheduleForm.get('surveyId')?.clearValidators();
      }
      this.editScheduleForm.get('surveyId')?.updateValueAndValidity();
      this.editScheduleForm.get('surveyId')?.valueChanges.subscribe((surveyId) => {
        const formControl = this.editScheduleForm.controls;
        formControl['folderPath'].setValue(surveyId);
        formControl['folderId'].setValue(surveyId);
      });
    });
  }

  private showPickerDialog(openerComponent: ComponentType<unknown>): void {
    const dialogRef = this.dialog.open(openerComponent, {
      data: {
        pickerType: 'folder',
        options: { maxSelectable: 1 },
      },
    });
    // NOTE: You do not need to unsubscribe from a dialogRef.afterClosed
    dialogRef.afterClosed().subscribe((selectedItems) => {
      if (selectedItems) {
        switch (openerComponent) {
          case BoxContentPickerComponent:
            this.createFolderPath(selectedItems);
            break;
          case S3ContentPickerComponent:
            this.createS3FolderPath(selectedItems);
            break;
        }
      } else {
        this.editScheduleForm.controls['folderPath'].markAsUntouched();
      }
    });
  }
  
  private sortProjectsSurveysBySurveyId(): void {
    this.projectSurveys.sort((a,b) => {
      const surveyIdA = a.surveyId.toLowerCase();
      const surveyIdB = b.surveyId.toLowerCase();
      if (surveyIdA < surveyIdB) {
        return -1;
      }
      if (surveyIdA > surveyIdB) {
        return 1;
      }
      return 0;
    });
  }
}
