import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, FeatureFlagGuard } from '@core/guards';
import { UserInfoGuard } from '@core/guards/user-info.guard';
import { RoleLabelsResolver } from '@core/resolvers/role-labels.resolver';
import { UsersComponent } from '@shared/components/users/users.component';
import { AdminLayoutComponent } from '@theme/admin-layout/admin-layout.component';
import { AddToProjectComponent } from 'app/admin/admin/add-to-project/add-to-project.component';
import { AddUsersComponent } from 'app/admin/admin/add-users/add-users.component';
import { EditUserComponent } from 'app/admin/admin/edit-user/edit-user.component';
import { AllProjectsComponent } from './all-projects/all-projects.component';
import { AllUsersComponent } from './all-users/all-users.component';
import { CreateProjectComponent } from './create-project/create-project.component';
import { EditProjectComponent } from './edit-project/edit-project.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { PlatformAdminComponent } from './platform-admin/platform-admin.component';
import { ProjectViewComponent } from './project-view/project-view.component';
import { ProjectSurveyComponent } from './project-survey/project-survey.component';

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    resolve: { hasDispatchFinished: RoleLabelsResolver },
    canActivate: [AuthGuard, FeatureFlagGuard],
    canActivateChild: [AuthGuard, UserInfoGuard],
    data: {
      allowWhenFeatureFlagTrue: ['any'],
    },
    children: [
      {
        path: '',
        component: PlatformAdminComponent,
        data: {
          back: '/home',
          backTitle: 'Dashboard',
          title: 'Admin Console',
        },
      },
      {
        path: 'users',
        component: AllUsersComponent,
        data: {
          back: '/platformAdmin',
          backTitle: 'Dashboard',
          title: 'Users',
        },
      },
      {
        path: 'users/list/:projectId',
        component: UsersComponent,
        data: {
          back: '/platformAdmin/projects/view/::projectId',
          backTitle: 'Project View',
          title: 'Users',
        },
      },
      {
        path: 'users/add',
        component: AddUsersComponent,
        data: {
          back: '/platformAdmin/users',
          backTitle: 'Users',
          title: 'Add User(s)',
        },
      },
      {
        path: 'user/add/:projectId',
        component: AddUsersComponent,
        data: {
          back: '/platformAdmin/users/list/:projectId',
          backTitle: 'Users',
          title: 'Add User(s)',
        },
      },
      {
        path: 'users/addtoproject/:emailId',
        component: AddToProjectComponent,
        data: {
          back: '/platformAdmin/users',
          backTitle: 'Users',
          title: 'Add to Project',
        },
      },
      {
        path: 'users/edit/:userId/:projectId',
        component: EditUserComponent,
        data: {
          back: 'platformAdmin/users',
          backTitle: 'Users',
          title: 'Edit User',
        },
      },
      {
        path: 'user/edit/:userId/:projectId',
        component: EditUserComponent,
        data: {
          back: 'platformAdmin/users/list/:projectId',
          backTitle: 'Users',
          title: 'Edit User',
        },
      },
      {
        path: 'users/manage/:emailId',
        component: ManageUsersComponent,
        resolve: { hasDispatchFinished: RoleLabelsResolver },
        data: {
          back: '/platformAdmin/users',
          backTitle: 'Project Users',
          title: 'Manage Users',
        },
      },
      {
        path: 'projects',
        component: AllProjectsComponent,
        data: {
          back: '/platformAdmin',
          backTitle: 'Dashboard',
          title: 'Projects',
        },
      },
      {
        path: 'projects/create',
        component: CreateProjectComponent,
        data: {
          back: '/platformAdmin/projects',
          backTitle: 'Projects',
          title: 'Create new project',
        },
      },
      {
        path: 'projects/view/:projectId',
        component: ProjectViewComponent,
        data: {
          back: '/platformAdmin/projects',
          backTitle: 'Projects',
          title: 'Project Details',
        },
      },
      {
        path: 'projects/edit/:projectId',
        component: EditProjectComponent,
        data: {
          back: 'projects/view/:projectId',
          backTitle: 'Projects',
          title: 'Edit Project',
        },
      },
      {
        path: 'projects/project-survey/:projectId',
        component: ProjectSurveyComponent,
        canActivate: [FeatureFlagGuard],
        data: {
          allowWhenFeatureFlagTrue: ['isForstaFeatureFlagEnabled'],
          back: 'projects/view/:projectId',
          backTitle: 'Project Details',
          title: 'Manage Forsta Survey Mappings',
        },
      },
      { path: '', redirectTo: 'platformAdmin', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PlatformAdminRoutingModule {}
