import { StagingUploadsFilemapModel } from './staging-uploads-filemap-model';

interface S3ParamFieldsModel {
  fields: {
    AWSAccessKeyId: string;
    key: string;
    policy: string;
    signature: string;
    'x-amz-security-token': string;
  };
  url: string;
}

export interface PresignedUrlPayloadModel {
  jobId: string;
  oldNewFileNameList?: StagingUploadsFilemapModel[];
  s3PresignedUrl: S3ParamFieldsModel;
  s3PresignedUrlWithTransferAcceleration: S3ParamFieldsModel;
}

export interface CreateJobResponseModel {
  message: PresignedUrlPayloadModel;
  status_code: number;
}
