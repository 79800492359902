export interface AWSDetailsStateModel {
    aws_access_key_id: string;
    aws_secret_access_key: string;
    aws_session_token: string;
    consoleUrl: string;
    sm_securitygroup: string;
    sm_subnet_1: string;
    sm_subnet_2: string;
    status_code?: number;
    validForHours: number;
}