import { UserAWSStatus } from '@theme/interfaces/user-aws-status';
export interface ProjectUserList {
  amazonWorkspace?: UserAWSStatus;
  ec2Status: string;
  emailId: string;
  firstName: string;
  isPowerUser: boolean;
  lastName: string;
  machineSize: string;
  projectId: string;
  projectRestrictedDataUseGroups: string[];
  roleName: string;
  userId: string;
  userStatus: string;
}
