import { MprFolderPathCollection } from './index';
export interface MPRFolderModel {
    created_at: string,
    etag: string,
    has_collaborations: boolean,
    id: string
    is_externally_owned: boolean,
    modified_at: string,
    modified_by: object
    name: string,
    parent: null,
    path_collection: { entries: MprFolderPathCollection[], total_count: number },
    permissions: object
    size: number
    type: string
}