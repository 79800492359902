export interface ScheduleModel {
  connectionId?: string;
  connectionName? : string;
  datasetName?: string;
  destinationFolder?: string
  folderId?: string;
  frequency: string;
  lastRunAt: string;
  scheduleId: string;
  scheduleName: string;
  status: string;
  userId?: string;
}
