import { Clipboard } from '@angular/cdk/clipboard';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as constants from '@core/constants';
import { LaunchEc2Enum, UserProjectRoleEnum } from '@core/enums';
import { AlertMessageService } from '@core/services';
import { environment } from '@env/environment';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { MprConfirmComponent, WorkspacesInputsModel } from '@shared';
import {
  CommonResponseModel,
  WorkspacesInstanceStateModel,
} from '@shared/interfaces';
import { SkipMainContentService } from '@shared/services';
import { TileConfig, User, UserProject } from '@theme/interfaces';
import {
  ApiInProgressState,
  AWSDetailsState,
  FeatureFlagsState,
  LaunchEc2State,
  LaunchWorkspaceEc2,
  LoadSelectedProjectMetadata,
  LoadUserdata,
  LoggedinUserState,
  ProvisionWorkspaceEc2,
  StopWorkspaceEc2,
  UserProjectState,
  UserState,
} from 'app/state';
import { AWSDetailsStateModel } from 'app/state/aws-details';
import {
  GetAWSDetails,
  ResetAWSDetails,
} from 'app/state/aws-details/aws-details.action';
import { FeatureFlagsStateModel } from 'app/state/feature-flags';
import ProjectModel from 'app/state/project/project-state-model';
import { ProjectState } from 'app/state/project/project.state';
import {
  GetRDPDetails,
  ResetRDPDetails,
  SetEC2NewVersionAvailability,
} from 'app/state/workspaces-instance/workspaces-instance.action';
import { WorkspacesInstanceState } from 'app/state/workspaces-instance/workspaces-instance.state';
import {
  catchError,
  Observable,
  Subscription,
  throwError,
  timer,
  withLatestFrom,
} from 'rxjs';
import { SelectRVersionComponent } from '../select-r-version/select-r-version.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-ec2-workspace',
  templateUrl: './ec2-workspace.component.html',
  styleUrls: ['./ec2-workspace.component.scss'],
})
export class Ec2WorkspaceComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public selectedTabIndex!: number;
  @Input() public inputs!: WorkspacesInputsModel;
  // 'GetUser' should same as the value in ApiInprogressStateModel state defaults
  @Select(ApiInProgressState.getApiInProgressStateAction('GetUser'))
  public fetchUserApiInProgress$?: Observable<boolean>;
  @Select(LoggedinUserState.getLoggedinUserName)
  public userFullName$?: Observable<string>;
  @Select(ProjectState.getSnowflakeProvisioningStatus)
  public snowflakeProvisioningStatus$!: Observable<string>;
  @Select(LaunchEc2State.getLaunchEc2)
  private ec2$?: Observable<CommonResponseModel>;
  @Select(UserProjectState.getSelectedProject)
  private project$?: Observable<UserProject>;
  @Select(UserState.getUsers)
  private user$?: Observable<User>;
  @Select(AWSDetailsState.returnAWSDetails)
  private awsDetails$!: Observable<AWSDetailsStateModel>;
  @Select(FeatureFlagsState.returnFeatureFlags)
  private featureFlags$!: Observable<FeatureFlagsStateModel>;
  @Select(WorkspacesInstanceState.getWorkspaceDetails)
  private getWorkspaceDetails$!: Observable<WorkspacesInstanceStateModel>;
  @Select(ProjectState.getProjectAdminEmailList)
  private projectAdminEmailList$!: Observable<string[]>;
  @Select(ProjectState.getSelectedProjectData)
  private selectedProject$!: Observable<ProjectModel>;
  @ViewChild('WorkSpaceDialog')
  private WorkSpaceDialog!: TemplateRef<any>;
  @ViewChild('ReprovisionConfirmInnerContent')
  private ReprovisionConfirmInnerContent?: TemplateRef<any>;
  @ViewChild('StataAndVscodeDialog')
  private StataAndVscodeDialog!: TemplateRef<any>;
  @ViewChild('TempUrlAndCredDialog')
  private TempUrlAndCredDialog!: TemplateRef<any>;
  public awsConsoleFailureMsg = `Failed to get AWS URL & Crendentials. Please try again or contact your administrator.`;
  public awsDetailsRefreshed = false;
  public contactUs = '';
  public dataProcessingUserGuideUrl = `${environment.helpPageNowUrl}processing-user-guide/overview`;
  public disableAWSCredentialsButton = false
  public disableStopButton = false;
  public ec2Data!: CommonResponseModel;
  public ec2Status = LaunchEc2Enum.STOPPED_STATUS;
  public ec2idleTime = environment.ec2idleTime;
  public isLoggedInUserInternal = false;
  public isRefresh = false;
  public jupyterLabTile: TileConfig | undefined;
  public launchEc2Enum = LaunchEc2Enum;
  public loggedinUserEmailDomain = '';
  public panelOpenState = false;
  public projectID!: string;
  public rstudioTile: TileConfig | undefined;
  public selectedUser!: User;
  public showAWSCredentialsLink = false;
  public showMessage = false;
  public showStagingRelatedElements = true;
  public showConnectingToSFLink = false;
  public showConnectingToRDSLink = false;
  public stataHelpURL = `${environment.helpPageNowUrl}processing-user-guide/working-with-stata`;
  public showSpinner = true;
  public userID!: string;
  public vscodeHelpURL = `${environment.helpPageNowUrl}processing-user-guide/working-with-vscode`;
  public stataAndVscodeDetails: WorkspacesInstanceStateModel = {
    public_dns_name: '',
    is_stata_ami: false,
    is_vscode_ami: false,
  };
  public awsCredentials: AWSDetailsStateModel = {
    aws_access_key_id: '',
    aws_secret_access_key: '',
    aws_session_token: '',
    consoleUrl: '',
    validForHours: 0,
    sm_securitygroup: '',
    sm_subnet_1: '',
    sm_subnet_2: '',
    status_code: 0,
  };
  public ec2Reprovisioned = false;
  public workSpaceTypes = constants.workspaceTypes;
  public workspaceServiceNowUrl = environment.workspaceChangeServiceNowUrl;
  public tileConfigs: TileConfig[] = [
    {
      img: 'assets/images/R_Studio.svg',
      title: 'R STUDIO',
      tag: '',
      roleSetting: [
        UserProjectRoleEnum.DEVELOPER,
        UserProjectRoleEnum.ADMIN,
        UserProjectRoleEnum.EXTERNAL_DEVELOPER,
      ],
      isVisible: true,
      isDisabled:
        this.selectedUser?.serverUrls?.rstudio &&
        this.ec2Status === LaunchEc2Enum.RUNNING_STATUS
          ? false
          : true,
      path: this.selectedUser?.serverUrls?.rstudio,
      version: '',
    },
    {
      img: 'assets/images/Stata.svg',
      title: 'STATA',
      tag: '',
      desc: '',
      roleSetting: [UserProjectRoleEnum.DEVELOPER, UserProjectRoleEnum.ADMIN],
      isVisible:
        this.selectedUser?.isStataFeatureFlagAvailable?.toLowerCase() ===
          'true' && this.stataAndVscodeDetails.is_stata_ami,
      isDisabled: this.ec2Status !== LaunchEc2Enum.RUNNING_STATUS,
      path: '',
    },
    {
      img: 'assets/images/Vscode.svg',
      title: 'VISUAL STUDIO CODE',
      tag: '',
      desc: '',
      roleSetting: [
        UserProjectRoleEnum.DEVELOPER,
        UserProjectRoleEnum.ADMIN,
        UserProjectRoleEnum.EXTERNAL_DEVELOPER,
      ],
      isVisible:
        this.selectedUser?.isVscodeFeatureFlagAvailable?.toLowerCase() ===
          'true' && this.stataAndVscodeDetails.is_vscode_ami,
      isDisabled: this.ec2Status !== LaunchEc2Enum.RUNNING_STATUS,
      path: '',
    },
    {
      img: 'assets/images/Jupyter.svg',
      title: 'JUPYTER LAB',
      tag: '',
      roleSetting: [
        UserProjectRoleEnum.DEVELOPER,
        UserProjectRoleEnum.ADMIN,
        UserProjectRoleEnum.EXTERNAL_DEVELOPER,
      ],
      isVisible: true,
      isDisabled:
        this.selectedUser?.serverUrls?.jupyter &&
        this.ec2Status === LaunchEc2Enum.RUNNING_STATUS
          ? false
          : true,
      path: this.selectedUser?.serverUrls?.jupyter,
    },
  ];
  private selectedProject!: UserProject;
  private ec2Subscription?: Subscription;
  private userSubscription?: Subscription;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @ViewChild('skipper') public skipper!: MatButton;

  constructor(
    private clipboard: Clipboard,
    public store: Store,
    private skipMainContentService: SkipMainContentService,
    private dialog: MatDialog,
    private router: Router,
    private alertService: AlertMessageService,
  ) {}

  public closemessage(): void {
    this.showMessage = !this.showMessage;
  }

  public convertToGiB(memory: string): string {
    return memory === 'NA'
      ? memory
      : `${Math.round(Number(memory.split('M')[0]) / 1024)} GiB`;
  }

  public copyToClipBoard(s3URL: string = '', event?: Event): void {
    this.clipboard.copy(s3URL);
    if (event) {
      event.stopPropagation();
    }
  }

  public displayQuickLinks(ec2Status: LaunchEc2Enum): boolean {
    return this.showAWSCredentialsLink || this.showConnectingToSFLink || this.showConnectingToRDSLink
      ? true
      : this.isEc2Provisioned(ec2Status);
  }

  public disableUpdateEC2Instance(ec2Status: LaunchEc2Enum): boolean {
    return (
      ec2Status === LaunchEc2Enum.STOPPING_STATUS ||
      ec2Status === LaunchEc2Enum.INITIALIZING_STATUS
    );
  }

  public doRefresh(): void {
    this.isRefresh = !this.isRefresh;
    this.store.dispatch(new LoadUserdata());
  }

  public isEc2Provisioned(ec2Status: LaunchEc2Enum): boolean {
    return (
      ec2Status !== this.launchEc2Enum.PROVISIONING &&
      ec2Status !== this.launchEc2Enum.NOT_PROVISIONED &&
      ec2Status !== this.launchEc2Enum.TERMINATED_STATUS &&
      ec2Status !== this.launchEc2Enum.FAILED &&
      ec2Status !== this.launchEc2Enum.REPROVISION_TERMINATED &&
      ec2Status !== this.launchEc2Enum.SHUTTING_DOWN
    );
  }

  public setTileConfig(): void {
    this.tileConfigs[0].isDisabled =
      this.selectedUser?.serverUrls?.rstudio &&
      this.ec2Status === LaunchEc2Enum.RUNNING_STATUS
        ? false
        : true;
    this.tileConfigs[1].isVisible =
      this.selectedUser?.isStataFeatureFlagAvailable?.toLowerCase() ===
        'true' &&
      this.stataAndVscodeDetails.is_stata_ami &&
      this.tileConfigs[1].roleSetting.includes(this.selectedProject.roleName);
    this.tileConfigs[1].isDisabled =
      this.ec2Status !== LaunchEc2Enum.RUNNING_STATUS;
    this.tileConfigs[2].isVisible =
      this.selectedUser?.isVscodeFeatureFlagAvailable?.toLowerCase() ===
        'true' &&
      this.stataAndVscodeDetails.is_vscode_ami &&
      this.tileConfigs[2].roleSetting.includes(this.selectedProject.roleName);
    this.tileConfigs[2].isDisabled =
      this.ec2Status !== LaunchEc2Enum.RUNNING_STATUS;
    this.tileConfigs[3].isDisabled =
      this.selectedUser?.serverUrls?.jupyter &&
      this.ec2Status === LaunchEc2Enum.RUNNING_STATUS
        ? false
        : true;
    this.tileConfigs[3].path = this.selectedUser?.serverUrls?.jupyter;
    this.tileConfigs[0].version = this.selectedUser?.rVersion;
    this.tileConfigs[0].path = this.selectedUser?.serverUrls?.rstudio;
  }

  public showReprovisionConfimation(): void {
    const dialogRef = this.dialog.open(MprConfirmComponent, {
      data: {
        confirmTitle: 'Update EC2 Instance',
        cancelButtonText: 'Dismiss',
        confirmButtonText: 'Update',
        innerContent: this.ReprovisionConfirmInnerContent,
        confirmData: true,
      },
    });
    // NOTE: You do not need to unsubscribe from a dialogRef.afterClosed
    dialogRef.afterClosed().subscribe((confirmData: boolean): void => {
      if (!confirmData) return;
      this.provisionWorkspace(true);
      this.ec2Reprovisioned = true;
      this.ec2Status = LaunchEc2Enum.REPROVISIONING;
      this.isRefresh = true;
    });
  }

  public launchWorkspace(): void {
    this.isRefresh = true;
    this.ec2Status = LaunchEc2Enum.INITIALIZING_STATUS;
    this.store.dispatch(
      new LaunchWorkspaceEc2(
        this.selectedUser.userId,
        this.selectedUser.projectId,
      ),
    );
  }
  public showDivider(count: number, index: number): boolean {
    return index === count - 1 ? false : true;
  }

  ngOnInit(): void {
    // Only when we have values
    this.store.dispatch(new ResetRDPDetails());
    this.featureFlags$.subscribe((featureFlags: FeatureFlagsStateModel) => {
      if (featureFlags.isStagingAccessAvailable === '') return;
      const selectedProject: UserProject = this.store.selectSnapshot(
        UserProjectState.getSelectedProject,
      );
      if (
        !selectedProject.userRestrictedDataUseGroups.includes('restricted') ||
        featureFlags.isStagingAccessAvailable.toLowerCase() !== 'true'
      ) {
        this.showStagingRelatedElements = false;
      }
      this.loggedinUserEmailDomain = this.store
        .selectSnapshot(LoggedinUserState.getLoggedInUserEmail)
        .split('@')[1];
      this.isLoggedInUserInternal =
        featureFlags.internalDomainList.findIndex(
          (x) => x === this.loggedinUserEmailDomain,
        ) > -1;
      if (!this.isLoggedInUserInternal) {
        this.store
          .dispatch(
            new LoadSelectedProjectMetadata(
              {},
              '?getProjectAdminEmailList=true',
            ),
          )
          .pipe(
            withLatestFrom(this.projectAdminEmailList$),
            catchError((err) => throwError(() => new Error(''))),
          )
          .subscribe(([_, res]) => {
            this.contactUs = `mailto:${res.join('; ')}`;
          });
      } else {
        this.contactUs = featureFlags.internalContactUsUrl;
      }
      this.selectedProject$.subscribe((project: ProjectModel) => {
        this.showConnectingToRDSLink = project.rdsEnabled && project.serviceAccountEnabled 
      });
    });

    this.store.dispatch(new LoadUserdata());
    this.store.dispatch(new GetRDPDetails());
    this.project$?.subscribe((project: UserProject) => {
      this.selectedProject = project;
      if (this.selectedProject.roleName !== '') this.setRoleVisibility();
    });

    this.userSubscription = this.user$?.subscribe((user: User) => {
      this.selectedUser = user;
      if (this.selectedUser.projectId) {
        this.showSpinner = false;
      }
      this.ec2Status =
        Object.values(LaunchEc2Enum).find(
          (item) => item === user.ec2Status.toUpperCase(),
        ) || LaunchEc2Enum.NOT_PROVISIONED;
      if (
        (this.ec2Status === LaunchEc2Enum.PROVISIONING ||
          this.ec2Status === LaunchEc2Enum.INITIALIZING_STATUS ||
          this.ec2Status === LaunchEc2Enum.RUNNING_STATUS) &&
        !this.stataAndVscodeDetails.is_stata_ami &&
        !this.stataAndVscodeDetails.is_vscode_ami
      ) {
        this.store.dispatch(new GetRDPDetails());
      }
      this.showAWSCredentialsLink =
        this.selectedUser?.isHeavyLiftingFlagAvailable?.toLowerCase() ===
          'true' && this.selectedUser?.isPowerUser;

      this.disableStopButton = this.ec2Status !== LaunchEc2Enum.RUNNING_STATUS;
      this.isRefresh =
        this.ec2Status === LaunchEc2Enum.PROVISIONING ||
        this.ec2Status === LaunchEc2Enum.INITIALIZING_STATUS ||
        this.ec2Status === LaunchEc2Enum.STOPPING_STATUS ||
        this.ec2Status === LaunchEc2Enum.REPROVISION_TERMINATED ||
        this.ec2Status === LaunchEc2Enum.SHUTTING_DOWN ||
        this.ec2Status === LaunchEc2Enum.REPROVISIONING;
      this.setTileConfig();
      this.snowflakeProvisioningStatus$.subscribe(
        (snowflakeProvisioningResponse) => {
          //If we do not still have completed this wait.
          if (snowflakeProvisioningResponse === '') return;

          this.showConnectingToSFLink =
            this.selectedUser?.dwFeatureFlag?.toLowerCase() === 'true' &&
            snowflakeProvisioningResponse === 'active';
        },
      );
    });

    if (
      this.selectedProject.roleName === UserProjectRoleEnum.RESEARCHER ||
      this.selectedProject.roleName === UserProjectRoleEnum.EXTERNAL_RESEARCHER
    ) {
      this.router.navigate(['/workspace/aws']);
      return;
    }
    // Track if popup shown
    let reprovisionPopupShown = false;
    this.getWorkspaceDetails$.subscribe((rdpDetails) => {
      this.stataAndVscodeDetails = rdpDetails;
      this.setTileConfig();
      timer(1000).subscribe(() => {
        if (!reprovisionPopupShown && this.showUpdateEC2Popup()) {
          this.dialog.closeAll();
          if (this.selectedTabIndex === 0) this.showReprovisionConfimation();
          reprovisionPopupShown = true;
        }
      });
    });

    this.ec2Subscription = this.ec2$?.subscribe((ec2: CommonResponseModel) => {
      this.showMessage = false;
      this.ec2Data = ec2;
      if (ec2?.status_code === 200) {
        if (ec2?.message.toLowerCase() === 'shutting down ec2') {
          this.ec2Status = LaunchEc2Enum.STOPPING_STATUS;
          this.isRefresh = true;
          this.tileConfigs.forEach((tile) => {
            tile.isDisabled = true;
          });
        } else if (
          !ec2?.message.includes('Initiated Server Provisioning') &&
          !ec2?.message.toLowerCase().includes('reprovision')
        ) {
          this.ec2Status = LaunchEc2Enum.INITIALIZING_STATUS;
          this.isRefresh = true;
          this.tileConfigs.forEach((tile) => {
            tile.isDisabled = true;
          });
        }
      } else {
        this.showMessage =
          this.isRefresh && ec2?.message !== '' && ec2.status_code !== 200;
      }
    });
    this.skipMainContentService.skip$.subscribe((res) => {
      this.skipper.focus();
    });

    this.rstudioTile = this.tileConfigs.find(
      (tile) => tile.title === 'R STUDIO',
    );
    this.jupyterLabTile = this.tileConfigs.find(
      (tile) => tile.title === 'JUPYTER LAB',
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedTabIndex'].currentValue !== 0) return;

    if (this.showUpdateEC2Popup()) this.showReprovisionConfimation();
  }

  ngOnDestroy(): void {
    this.ec2Reprovisioned = false;
    this.ec2Subscription?.unsubscribe();
    this.userSubscription?.unsubscribe();
  }

  public stopWorkspace(): void {
    this.disableStopButton = true;
    this.ec2Status = LaunchEc2Enum.STOPPING_STATUS;
    this.tileConfigs.forEach((tile) => {
      tile.isDisabled = true;
    });
    this.store.dispatch(
      new StopWorkspaceEc2(
        this.selectedUser.userId,
        this.selectedUser.projectId,
        'stop',
      ),
    );
  }

  public showUpdateEC2Popup(): boolean {
    return this.stataAndVscodeDetails.new_ami_version_available &&
      this.ec2Status !== LaunchEc2Enum.INITIALIZING_STATUS &&
      this.ec2Status !== LaunchEc2Enum.STOPPING_STATUS &&
      this.ec2Status !== LaunchEc2Enum.REPROVISIONING &&
      this.ec2Status !== LaunchEc2Enum.PROVISIONING &&
      this.ec2Status !== LaunchEc2Enum.TERMINATED_STATUS &&
      this.ec2Status !== LaunchEc2Enum.REPROVISION_TERMINATED &&
      this.ec2Status !== LaunchEc2Enum.FAILED &&
      this.ec2Status !== LaunchEc2Enum.SHUTTING_DOWN
      ? true
      : false;
  }

  public showUpdateEc2Section(): boolean {
    return this.stataAndVscodeDetails.new_ami_version_available &&
      this.ec2Status !== LaunchEc2Enum.REPROVISIONING &&
      this.ec2Status !== LaunchEc2Enum.PROVISIONING &&
      this.ec2Status !== LaunchEc2Enum.TERMINATED_STATUS &&
      this.ec2Status !== LaunchEc2Enum.FAILED &&
      this.ec2Status !== LaunchEc2Enum.REPROVISION_TERMINATED &&
      this.ec2Status !== LaunchEc2Enum.SHUTTING_DOWN
      ? true
      : false;
  }

  public provisionWorkspace(reprovision = false): void {
    this.disableStopButton = true;
    this.tileConfigs.forEach((tile) => {
      tile.isDisabled = true;
    });
    this.ec2Status = reprovision
      ? LaunchEc2Enum.REPROVISIONING
      : LaunchEc2Enum.PROVISIONING;
    this.isRefresh = true;
    // We do not want the update section to show up when user has reporvision in progress.
    this.store.dispatch(new SetEC2NewVersionAvailability(false));
    this.store
      .dispatch(
        new ProvisionWorkspaceEc2(
          this.selectedUser.userId,
          this.selectedUser.projectId,
          reprovision ? 'reprovision' : 'provision',
        ),
      )
      .pipe(
        catchError((err) => {
          this.alertService.error({
            body: err.error.message,
          });
          return throwError(() => new Error(''));
        }),
      );
  }

  public openTempUrlAndCredModal(): void {
    this.disableAWSCredentialsButton = true;
    this.store
      .dispatch(new GetAWSDetails())
      .pipe(
        withLatestFrom(this.awsDetails$),
        catchError(() => {
          this.handleAWSConsoleError();
          this.disableAWSCredentialsButton = false;
          return throwError(() => new Error(''));
        }),
      )
      .subscribe(([_, awsDetails]) => {
        if (awsDetails.status_code !== 0) {
          this.handleAWSConsoleError();
        } else {
          this.awsCredentials = awsDetails;
          this.awsDetailsRefreshed = false;
          const userStateSnapshot = this.store.selectSnapshot(
            UserState.getUsers,
          );
          this.projectID = userStateSnapshot.projectId;
          this.userID = userStateSnapshot.userId;
          this.dialog.open(this.TempUrlAndCredDialog, { disableClose: true });
        }
        this.disableAWSCredentialsButton = false;
      });
  }

  public handleAWSConsoleError(): void {
    this.dialog.closeAll();
    this.store.dispatch(new ResetAWSDetails());
    this.alertService.error({
      body: this.awsConsoleFailureMsg,
    });
  }

  public copyAwsValues(): void {
    const concatenatedString = `aws_access_key_id = ${this.awsCredentials.aws_access_key_id}\naws_secret_access_key = ${this.awsCredentials.aws_secret_access_key}\naws_session_token = ${this.awsCredentials.aws_session_token}`;
    this.clipboard.copy(concatenatedString);
  }

  public closeTempUrlAndCredDialog(): void {
    this.dialog.closeAll();
  }

  public openWorkSpaceModal(): void {
    this.dialog.open(this.WorkSpaceDialog);
  }

  public closeWorkspaceDialog(): void {
    this.dialog.closeAll();
  }

  public navigateConnectingToSnowflake(): void {
    this.router.navigate(['workspace/connecting-to-snowflake']);
  }

  public navigateConnectingToRDS(): void {
    this.router.navigate(['/rds/view-rds-from-workspace']);
  }

  public tileClick(
    tile: TileConfig | undefined,
    isModifyRversion = false,
  ): void {
    if (tile) {
      const title = tile.title;
      const path = tile.path;

      if (title === 'STATA' || title === 'VISUAL STUDIO CODE') {
        this.store
          .dispatch(new GetRDPDetails())
          .pipe(withLatestFrom(this.getWorkspaceDetails$))
          .subscribe(([_, rdpDetails]) => {
            this.stataAndVscodeDetails = rdpDetails;
            this.dialog.open(this.StataAndVscodeDialog, {
              disableClose: true,
              data: {
                name: title === 'STATA' ? 'STATA' : 'Visual Studio Code',
                username: title === 'STATA' ? 'xstata' : 'vscode',
                link:
                  title === 'STATA' ? this.stataHelpURL : this.vscodeHelpURL,
              },
            });
          });
      } else if (title === 'R STUDIO') {
        if (
          this.tileConfigs[0].version === '' ||
          this.tileConfigs[0].version === null ||
          isModifyRversion
        ) {
          const confirmData = {
            instanceId: this.selectedUser.instanceId,
            currentRversion: this.tileConfigs[0].version,
          };
          const rversionDialogRef = this.dialog.open(SelectRVersionComponent, {
            disableClose: true,
            data: { confirmData },
          });
          rversionDialogRef.afterClosed().subscribe((closeData: any): void => {
            if (closeData) {
              if (!closeData['error']) {
                this.tileConfigs[0].version = closeData['selectedVersion'];
                this.alertService.success({
                  body: `R Studio is being launched with version ${closeData['selectedVersion']}`,
                });
                window.open(path, '_blank');
              } else {
                this.alertService.error({
                  body: `R Studio version update failed, please try again.`,
                });
              }
            }
          });
        } else {
          window.open(path, '_blank');
        }
      } else {
        window.open(path, '_blank');
      }
    }
  }

  public closeStataAndVscodeDialog(): void {
    this.dialog.closeAll();
  }

  private setRoleVisibility(): void {
    this.tileConfigs = this.tileConfigs.map((tile: TileConfig) => ({
      ...tile,
      isVisible: tile.roleSetting.includes(this.selectedProject.roleName),
    }));
  }
}
