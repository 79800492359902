import { Clipboard } from '@angular/cdk/clipboard';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ADD_RDS_DEFAULT_VALUES, RDS_CLUSTER_DISPLAY_STATUS, UserProjectRoleEnum } from '@core';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { AlertMessageService } from '@core/services';
import { environment } from '@env/environment';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { MprConfirmComponent, MprConfirmInputModal, SkipMainContentService } from '@shared';
import { UserProject } from '@theme/interfaces';
import { AddEditRDSComponent } from 'app/rds/add-edit-rds/add-edit-rds.component';
import {
  ApiInProgressState,
  LoadRDSClusters,
  DeleteRDSCluster,
  LoadSelectedProjectMetadata,
  ManageRDSClustersModel,
  ManageRDSClusterState,
  ProjectState,
  RDSClusters,
  ResetManageRDSClusterCommonResponse,
  ResetManageRDSClusters,
  SetSelectedCluster,
  SetupCluster,
  StartOrStopCluster,
  UserProjectState,
} from 'app/state';
import {
  FeatureFlagsState,
  FeatureFlagsStateModel,
} from 'app/state/feature-flags';
import ProjectModel from 'app/state/project/project-state-model';
import {
  catchError,
  filter,
  Observable,
  Subject,
  Subscription,
  takeUntil,
  throwError,
  withLatestFrom,
} from 'rxjs';
import { getRDSClusterDisplayStatus } from '../common/rds-utils';
import { MatButton } from '@angular/material/button';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-manage-rds',
  templateUrl: './manage-rds.component.html',
  styleUrls: ['./manage-rds.component.scss'],
})
export class ManageRDSComponent implements OnInit, OnDestroy {
  @Select(ApiInProgressState.getApiInProgressStateAction('GetProjectsMetadata'))
  public fetchProjectsMetadataInProgress$?: Observable<boolean>;
  @Select(ApiInProgressState.getApiInProgressStateAction('GetRds'))
  public fetchRDSInProgress$?: Observable<boolean>;
  @Select(UserProjectState.getSelectedProject)
  public project$?: Observable<UserProject>;
  @Select(ProjectState.getSelectedProjectData)
  public projectData$!: Observable<ProjectModel>;
  @Select(ManageRDSClusterState.GetRDSClusterState)
  public rdsClusterState$!: Observable<ManageRDSClustersModel>;
  @ViewChild('showRDSClusterDetailsDialog')
  public showRDSClusterDetailsDialog!: TemplateRef<any>;
  @ViewChild('showWarningDialog')
  public showWarningDialog!: TemplateRef<any>;
  @ViewChild('skipper') public skipper!: MatButton;
  public disableAllButtons = false;
  public displayedColumns: string[] = [
    'clusterName',
    'dbEngine',
    'dbInstanceCount',
    'dateClusterSetup',
    'status',
  ];
  public isPasswordVisible = false;
  public isPlatformAdmin = true;
  public isUsernameVisible = false;
  public projectId: string;
  public rdsClusterDataSource = new MatTableDataSource<RDSClusters>();
  public rdsHelpURL = `${environment.helpPageNowUrl}`;
  public requestHeaders: MprHttpHeaderModal = {};
  public showInprogressSetup = false;
  public showRDS = true;
  public showSetupFailureSection = false;
  public userRole = UserProjectRoleEnum.PLATFORM_ADMIN;
  private allSubscription = new Subscription();
  private destroyed$ = new Subject<boolean>();
  constructor(
    private clipboard: Clipboard,
    private route: ActivatedRoute,
    private router: Router,
    public store: Store,
    private skipMainContentService: SkipMainContentService,
    private alertService: AlertMessageService,
    private dialog: MatDialog
  ) {
    this.projectId = this.route.snapshot.params['projectId'];
    this.requestHeaders[HeaderParams.PROJECTID] = this.projectId;
    this.requestHeaders[HeaderParams.ROLENAME] = this.userRole;
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroyed$)
      )
      .subscribe((e) => {
        const currentRoute: string = this.router.url;
        this.isPlatformAdmin = currentRoute.includes('platform-admin');
      });
  }

  public closeDialog(): void {
    this.dialog.closeAll();
  }

  public clusterNotSetup(rdsClusters: RDSClusters[]): boolean {
    return (
      !this.showInprogressSetup &&
      (rdsClusters.length === 0 ||
        (rdsClusters.length > 0 && rdsClusters[0].status === 'unavailable'))
    );
  }

  public clusterSetupAvailableAndSuccess(rdsClusters: RDSClusters[]): boolean {
    return (
      !this.showInprogressSetup &&
      rdsClusters.length > 0 &&
      rdsClusters[0].status === 'available'
    );
  }

  public clusterSetupInprogress(rdsClusters: RDSClusters[]): boolean {
    return (
      this.showInprogressSetup ||
      (rdsClusters.length > 0 && rdsClusters[0].status === 'creating')
    );
  }

  public copyToClipBoard(copyText: string): void {
    this.clipboard.copy(copyText);
  }

  public showDeleteClusterDialog(rdscluster: RDSClusters): void {
    const clusterName = rdscluster.clusterName;
    const confirmMessage = `Are you sure you want to delete the RDS cluster <b>${clusterName}</b>?`;
    // Open the confirmation dialog
    const dialogRef = this.dialog.open(MprConfirmComponent, {
      width: '650px',
      data: {
        confirmTitle: `Delete RDS Cluster`,
        confirmMessage: confirmMessage,
        useEmitterOnConfirmClick: true,
      },
      disableClose: true,
    });
    // Subscribe to the confirmAction emitter in the dialog component
    dialogRef.componentInstance.confirmAction.subscribe(() => {
      // Dispatch the delete action when the confirmation is clicked
      this.store
        .dispatch(
          new DeleteRDSCluster(
            this.projectId,
            rdscluster.rdsResourceId,
            this.requestHeaders
          )
        )
        .pipe(
          catchError((err) => {
            // Handle error
            this.alertService.error({
              body: err.error.message || 'Failed to delete the cluster.',
            });
            return throwError(() => new Error(''));
          })
        )
        .subscribe((res: any) => {
          // Check the status code
          if (res.status_code > 202) {
            // If status code is greater than 202, treat it as a failure
            this.alertService.error({
              body: res.message
                ? res.message
                : `Deleting cluster&nbsp;<b>${clusterName}</b>&nbsp;was unsuccessful. Please try again.`,
            });
          } else {
            // Success notification
            this.alertService.success({
              body: `RDS Cluster&nbsp;<b>${clusterName}</b>&nbsp;deleted.`,
            });
          }
          // Close the dialog regardless of success or failure
          dialogRef.close();
          // Reload the cluster list after deletion
          this.store.dispatch(new LoadRDSClusters(this.requestHeaders));
        });
    });
   }
   
  public displayDeleteCluster(): boolean {
    return this.userRole === UserProjectRoleEnum.PLATFORM_ADMIN;
  }
  public displayEditCluster(): boolean {
    return this.userRole === UserProjectRoleEnum.PLATFORM_ADMIN;
  }

  public displayManageUsers(): boolean {
    return (
      this.userRole === UserProjectRoleEnum.ADMIN ||
      this.userRole === UserProjectRoleEnum.PLATFORM_ADMIN
    );
  }

  public displayStartCluster(rdsCluster: RDSClusters): boolean {
    return (
      (this.userRole === UserProjectRoleEnum.ADMIN ||
        this.userRole === UserProjectRoleEnum.PLATFORM_ADMIN) &&
      rdsCluster.displayStatus === RDS_CLUSTER_DISPLAY_STATUS.STOPPED
    );
  }

  public displayStopCluster(rdsCluster: RDSClusters): boolean {
    return (
      (this.userRole === UserProjectRoleEnum.ADMIN ||
        this.userRole === UserProjectRoleEnum.PLATFORM_ADMIN) &&
      rdsCluster.displayStatus === RDS_CLUSTER_DISPLAY_STATUS.RUNNING
    );
  }

  public displayViewDetails(): boolean {
    return this.userRole === UserProjectRoleEnum.ADMIN;
  }

  public doRefresh(): void {
    this.disableAllButtons = true;
    this.store
      .dispatch(new LoadRDSClusters(this.requestHeaders))
      .subscribe(() => {
        this.showInprogressSetup = false;
        this.showSetupFailureSection = false;
        this.disableAllButtons = false;
      });
  }

  ngOnDestroy(): void {
    this.allSubscription.unsubscribe();
    this.store.dispatch(new ResetManageRDSClusters());
  }

  ngOnInit(): void {
    if (!this.projectId) {
      this.project$?.subscribe((project: UserProject) => {
        this.projectId = project.projectId;
        this.userRole = project.roleName;
        this.isPlatformAdmin = false;
        this.requestHeaders[HeaderParams.PROJECTID] = this.projectId;
        this.requestHeaders[HeaderParams.ROLENAME] = this.userRole;
      });
    }
    if( this.userRole === UserProjectRoleEnum.ADMIN || this.userRole === UserProjectRoleEnum.PLATFORM_ADMIN){
        this.displayedColumns.push('actionMenu')
    }
    else {
      this.displayedColumns.push('details')
    }
    this.store
      .dispatch(new LoadSelectedProjectMetadata(this.requestHeaders))
      .pipe(
        withLatestFrom(this.projectData$),
        catchError((err) => throwError(() => new Error(''))),
        takeUntil(this.destroyed$)
      )
      .subscribe(([_, res]) => {
        this.showRDS = this.showRDSCheck(res);
      });
    this.store.dispatch(new LoadRDSClusters(this.requestHeaders));
    this.allSubscription = this.rdsClusterState$.subscribe((rdsClusterState) => {
      const sortedClusters = rdsClusterState.rdsClusters
        .map((cluster) => ({
          ...cluster,
          dateClusterSetup: cluster.dateClusterSetup,
          displayStatus: getRDSClusterDisplayStatus(cluster.status),
        }))
        .sort((a, b) => {
          const dateA = a.dateClusterSetup ? new Date(a.dateClusterSetup).getTime() : 0;
          const dateB = b.dateClusterSetup ? new Date(b.dateClusterSetup).getTime() : 0;
          return dateB - dateA;
        });
      this.rdsClusterDataSource = new MatTableDataSource(sortedClusters);
    });
    this.skipMainContentService.skip$.subscribe((res) => {
      this.skipper.focus();
    });
  }

  public openRDSClusterDetailsDialog(selectedCluster: RDSClusters): void {
    if (selectedCluster.dbUserName || this.userRole === UserProjectRoleEnum.ADMIN) {
      this.dialog.open(this.showRDSClusterDetailsDialog, {
        data: { rdsCluster: selectedCluster },
        disableClose: true,
      });
    } else {
      this.dialog.open(this.showWarningDialog, {
        data: { message: 'You do not have access to this RDS cluster. Please contact your project lead.' },
        disableClose: true,
      });
    }
  }

  public redirectToClusterUserList(rdsCluster: RDSClusters): void {
    this.store.dispatch(new SetSelectedCluster(rdsCluster));
    const routePath = this.isPlatformAdmin
      ? `/rds/platform-admin/manage-cluster-users/${this.projectId}/${rdsCluster.rdsResourceId}`
      : `/rds/manage-cluster-users/${rdsCluster.rdsResourceId}`;
    this.router.navigate([routePath]);
  }

  public setupRDS(): void {
    const existingClusterNames = this.store.selectSnapshot(
      ManageRDSClusterState.getExistingClusterNames
    );
    const baseClusterName = `${this.projectId}_${environment.env_shortName}`;
    let clusterName = baseClusterName;
    // Check if a cluster with the base name already exists
    if (existingClusterNames.includes(clusterName.toLowerCase())) {
      let suffix = 1;
      // Increment the suffix until we find an unused cluster name
      while (
        existingClusterNames.includes(`${clusterName}_${suffix}`.toLowerCase())
      ) {
        suffix++;
      }
      clusterName = `${clusterName}_${suffix}`;
    }
    const defaultCapacity = this.store.selectSnapshot(
      ManageRDSClusterState.GetRDSClusterState
    ).defaultCapacity;
    const dialogRef = this.dialog.open(AddEditRDSComponent, {
      width: '500px',
      data: {
        projectName: this.projectId,
        clusterName,
        dbEngine: ADD_RDS_DEFAULT_VALUES.DB_ENGINE,
        capacity: defaultCapacity,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const enteredClusterName = result.clusterName;
        this.disableAllButtons = true;
        this.showInprogressSetup = true;
        this.store
          .dispatch(
            new SetupCluster(
              this.projectId,
              enteredClusterName,
              this.requestHeaders
            )
          )
          .pipe(
            withLatestFrom(this.rdsClusterState$),
            catchError((err) => {
                this.alertService.error({
                    body: err.error.message,
                });
              this.showInprogressSetup = false;
              this.showSetupFailureSection = true;
              return throwError(() => new Error(''));
            })
          )
          .subscribe(([_, res]) => {
            this.disableAllButtons = false;
            if (res.commonResponse.status_code === 202) {
              this.alertService.success({
                body: `RDS cluster ${enteredClusterName} is being set up. It can take up to 10 minutes to complete. Click REFRESH to check the status.`,
                autoDismiss: false,
              });
              this.store.dispatch(new LoadRDSClusters(this.requestHeaders));
            } else {
              const displayMessage = res.commonResponse.message !== '' ? res.commonResponse.message : 'RDS cluster setup is unsuccessful. Please try again.';
              this.alertService.error({
                body: displayMessage,
                autoDismiss: false,
              });
              this.showInprogressSetup = false;
              this.showSetupFailureSection = true;
            }
          });
      }
    });
  }

  public showRDSCheck(projectData: ProjectModel): boolean {
    const featureFlags: FeatureFlagsStateModel = this.store.selectSnapshot(
      FeatureFlagsState.returnFeatureFlags
    );
    return (
      featureFlags.rdsFeatureFlag.toLowerCase() === 'true' &&
      featureFlags.serviceAccountFeatureFlag.toLowerCase() === 'true' &&
      projectData.rdsEnabled &&
      projectData.serviceAccountEnabled
    );
  }

  public showRefresh(rdsClusters : RDSClusters[]): boolean {
    return rdsClusters.findIndex(cluster=> cluster.status=== 'starting'|| cluster.status=== 'stopping' || cluster.displayStatus=== 'creating' || cluster.displayStatus=== 'deleting') > -1;
  }

  public togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  public toggleUsernameVisibility(): void {
    this.isUsernameVisible = !this.isUsernameVisible;
  }

  public triggerActionStartOrStop(rdsCluster : RDSClusters, requestedAction: string) : void {
    const confirmMessage = requestedAction === 'stop' ?
       `Are you sure you want to temporarily stop the RDS cluster <b>${rdsCluster.clusterName}</b>?` :
       `Are you sure you want to start the RDS cluster <b>${rdsCluster.clusterName}</b>?`
    const confirmTitle = requestedAction === 'stop' ?
      `Stop RDS Cluster Temporarily`: 
      `Start RDS Cluster`;
      const dialogRef = this.dialog.open(MprConfirmComponent, {
        width: '650px',
        data: {
          confirmTitle,
          confirmMessage,
          useEmitterOnConfirmClick: true,
          showCheckboxWithConfirmationMessage : requestedAction === 'stop' ? 
                `I understand that the RDS cluster can only be stopped for an allowed maximum time of 7 days, and will auto-restart after 7 days.` 
                : ''
        },
        disableClose: true,
      });
      dialogRef.componentInstance.confirmAction.subscribe((data) => {
        const action = requestedAction === 'stop' ? 'stop_cluster' : 'start_cluster';
        this.store
          .dispatch(
            new StartOrStopCluster(
              this.projectId,
              rdsCluster.rdsResourceId,
              action,
              this.requestHeaders
            )
          )
          .pipe(
            withLatestFrom(this.rdsClusterState$),
            catchError((err) => {
              this.alertService.error({
                body: err.error.message,
              });
              this.store.dispatch(new ResetManageRDSClusterCommonResponse());
              return throwError(() => new Error(''));
            })
          )
          .subscribe(([_, res]) => {
            if (res.commonResponse.status_code > 202) {
              this.alertService.error({
                body: res.commonResponse.message
                  ? res.commonResponse.message
                  : `The requested action for the RDS cluster ${rdsCluster.clusterName} failed.`,
              });
              dialogRef.close();
            } else {
              const successMessage = `RDS cluster ${rdsCluster.clusterName} is ${requestedAction === 'stop'? 'stopping': 'starting'}. It can take up to 10 minutes. Click&nbsp;<b>REFRESH</b>&nbsp;to check the status.`;
              this.alertService.success({
                body: successMessage,
                autoDismiss: false
              })
              dialogRef.close();
            }
            this.store.dispatch(new LoadRDSClusters(this.requestHeaders))
          });
      });
  }

  public viewOnlyForProjectAdmin(): boolean {
    return this.userRole === UserProjectRoleEnum.ADMIN;
  }
}
