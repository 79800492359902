import { Component, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  AlertMessageModel,
  snackBarAlignmentTypes,
  snackBarMessageType,
} from '@core/interfaces';
import { AlertMessageComponent } from '@theme/alert-message/alert-message.component';

@Injectable({
  providedIn: 'root',
})
export class AlertMessageService {
  private autoDismissTimeinSeconds = 5;

  private defaultMatSnackBarConfig: MatSnackBarConfig = {
    horizontalPosition: 'center',
    verticalPosition: 'top',
    panelClass: ['mpr-snackbar'],
  };

  constructor(private route: Router, private snackBar: MatSnackBar) {}

  public dismmissAll(): void {
    this.snackBar.dismiss();
  }

  public error(message: AlertMessageModel): void {
    this.openSnackBarCustomComponent({
      ...message,
      type: snackBarMessageType.ERROR,
    });
  }

  public success(message: AlertMessageModel): void {
    this.openSnackBarCustomComponent({
      ...message,
      type: snackBarMessageType.SUCCESS,
    });
  }

  public warning(message: AlertMessageModel): void {
    this.openSnackBarCustomComponent({
      ...message,
      type: snackBarMessageType.WARNING,
    });
  }

  private getMatSnackBarConfigOptions(
    message: AlertMessageModel
  ): MatSnackBarConfig {
    // Take care of the optionals to ensure some defaults if user misses out.
    message.autoDismiss =
      message.autoDismiss === undefined ? true : message.autoDismiss;
    message.alignment =
      message.alignment === undefined
        ? snackBarAlignmentTypes.DEFAULT
        : snackBarAlignmentTypes.CENTER;

    // merge with the defaults.
    const config: MatSnackBarConfig = {
      ...this.defaultMatSnackBarConfig,
      ...{
        panelClass: ['mpr-snackbar', `mpr-snackbar-${message.type}`],
        autoDismiss: message.autoDismiss,
        data: message,
      },
    };

    // If auto dismmiss is on we need content center aligned and duration to be added.
    if (message.autoDismiss) {
      config.duration = this.autoDismissTimeinSeconds * 1000;
      config.data.alignment = snackBarAlignmentTypes.CENTER;
    }
    return config;
  }

  private openSnackBarCustomComponent(message: AlertMessageModel): void {
    // Trigger a custom component for this purpose.
    this.snackBar.openFromComponent(
      AlertMessageComponent,
      this.getMatSnackBarConfigOptions(message)
    );
  }
}
