import ExternalProjectAccountModel from './external-project-account.model';

export default interface ProjectModel {
  businessUnit: string;
  clientName: string;
  department: string;
  dwAccess?: boolean;
  externalProjectAccountList: Array<ExternalProjectAccountModel>
  externalProjectAccountsEnabled: boolean;
  hasPowerUsers: boolean;
  [key: string]: any;
  projectAdminEmailList?: string[];
  projectAttributes?: [];
  projectId: string;
  projectName: string;
  projectNumber: string;
  projectRestrictedDataUseGroups: string[];
  rdsEnabled: boolean;
  serviceAccountEnabled : boolean;
  snowflakeProvisioningStatus: string;
}
