import { UploadStatusesEnum } from '@core';
import { MprReadyFile } from 'app/uploads/interfaces';

export interface RecurringUploadHistory {
  connectionName: string;
  dataStore: string;
  datasetId?: string;
  datasetName?: string;
  description: string;
  destinationFolder?: string;
  filesReadyForSearch?: Array<string>;
  frequency: string;
  jobStatus: UploadStatusesEnum;
  noOfFilesReadyForSearch?: string;
  noOfFilesReadyForStaging?: string;
  noOfQuarantinedFiles?: string;
  noOfSkippedFiles?: string;
  quarantinedFiles?: Array<string>;
  quarantinedSupportingFiles?: Array<string>;
  runDate: string;
  scan_skipped?: boolean;
  scheduleId: string;
  scheduleJobId: string;
  scheduleName: string;
  skippedFiles?: Array<MprReadyFile>;
  stagedFiles?: Array<string>;
  supportingFilesReadyForSearch?: Array<string>;
  totalNoOfFiles: string;
  uploadType?: string;
}
