/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit, Inject } from '@angular/core';
import { MprHttpHeaderModal } from '@core/interfaces';
import { Store } from '@ngxs/store';
import { catchError, throwError } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AlertMessageService } from '@core/services';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import {
  MprErrorStateMatcher,
} from '@shared/validators';
import { ForgotAWSPassword, ProvisionAWSWorkspace } from 'app/state';
import { AwsPasswprdModal } from '@shared/interfaces/aws-password-model';
@Component({
  selector: 'app-set-aws-workspace-password',
  templateUrl: './set-aws-workspace-password.component.html',
  styleUrls: ['./set-aws-workspace-password.component.scss'],
})
export class SetAwsWorkspacePasswordComponent implements OnInit {
  public requestHeaders: MprHttpHeaderModal = {};
  public awsWorkspaceSetPasswordForm!: FormGroup;
  public awsWorkspacePasswordPattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*_+=\-`\'|\\(){}\[\]:;"<>,.?/])[A-Za-z\d~!@#$%^&*_+=\-`\'|\\(){}\[\]:;"<>,.?/]{8,32}$/;
  public disableAction = false;
  public matcher = new MprErrorStateMatcher();
  public hidePassword = true;
  public hideConfirmPassword = true;
  public title = '';

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<SetAwsWorkspacePasswordComponent>,
    private alertMsgService: AlertMessageService,
    @Inject(MAT_DIALOG_DATA) public data: AwsPasswprdModal
  ) {
    this.awsWorkspaceSetPasswordForm = this.fb.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(this.awsWorkspacePasswordPattern),
          ],
        ],
        confirmPassword: [
          '',
          [
            Validators.required,
            Validators.pattern(this.awsWorkspacePasswordPattern),
          ],
        ],
      },
      {
        validators: this.matchValidator('password', 'confirmPassword'),
      }
    );
  }

  public matchValidator(controlName: string, matchingControlName: string): any {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors['confirmedValidator']
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  ngOnInit(): void {
    this.title = this.data.isForgotPassword
      ? 'Reset password for your Amazon Workspace'
      : 'Create password for your Amazon Workspace';
  }

  public submit(): void {
    this.disableAction = true;
    const password =
      this.awsWorkspaceSetPasswordForm.controls['password'].value;
    const encodedPassword = btoa(password);
    const finalApiCall = this.data.isForgotPassword
      ? this.store.dispatch(
          new ForgotAWSPassword(encodedPassword, this.data.userId)
        )
      : this.store.dispatch(
          new ProvisionAWSWorkspace('provision', encodedPassword)
        );

    finalApiCall
      .pipe(
        catchError((err) => {
          this.alertMsgService.error({
            body: err.error.message,
          });
          this.disableAction = false;
          return throwError(() => new Error(''));
        })
      )
      .subscribe((response) => {
        this.disableAction = false;
        if (this.data.isForgotPassword) {
          this.alertMsgService.success({
            body: 'Password for your Amazon Workspace has been reset sucessfully.',
          });
        } else {
          this.alertMsgService.success({
            body: 'Your Workspace is being setup using the password you entered.',
          });
        }
        // All is well
        this.dialogRef.close({ result: 'success' });
      });
  }
}
