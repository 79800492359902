import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MprConfirmComponent } from '@shared';
import { MatDialog } from '@angular/material/dialog';
import {
  EditAProject,
  GetProjectDeatils,
  GetGobalAttributes,
  GlobalAttributesState,
  GlobalAttributesModel,
  ManageProjectState,
  ResetProjectData,
  SetLoaderState,
  FeatureFlagsState,
  GetAllowedDomains,
  DomainListState,
} from 'app/state';
import ManageProjectModel, {
  MessageDetails,
  projectDetails,
} from 'app/state/manage-project/manage-project-state-model';
import { FeatureFlagsStateModel } from 'app/state/feature-flags';
import { UserProjectRoleEnum } from '@core';
import {
  Observable,
  catchError,
  throwError,
  timer,
  withLatestFrom,
} from 'rxjs';
import { AlertMessageService } from '@core/services';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss'],
})
export class EditProjectComponent implements OnInit, OnDestroy {
  @Select(DomainListState.returnAllowedDomains)
  public allowedDomains$?: Observable<string[]>;
  @Select(GlobalAttributesState.getGloablAttributesLoader)
  public globalAttributeLoader$? : Observable<boolean>;
  @Select(GlobalAttributesState.returnGlobalAttributes)
  public globalAttributesObservable$? : Observable<GlobalAttributesModel[]>;
  @Select(ManageProjectState.getProjectCreationStatus)
  public projectCreation$!: Observable<MessageDetails | string>;
  @Select(ManageProjectState.getProjectDetails)
  public projectDetails$?: Observable<projectDetails>;
  @Select(ManageProjectState.getLoaderStatus)
  public showSpinner$?: Observable<boolean>;
  public allowedExternalDomains: Array<string> = [];
  public blockExternalDomain: string | undefined;
  public globalAttributes: GlobalAttributesModel[] = []
  public isAccountingFeatureEnabled = false;
  public returnPath = '';
  public selectedProjectDetails: projectDetails = {
    credit: 0,
    dwAccess: false,
    emailDistributionList: [],
    hasPowerUsers: false,
    externalProjectAccountsEnabled: false,
    projectName: '',
    projectNickname: '',
    projectNumber: '',
    projectRestrictedDataUseGroups: [],
    projectSize: '',
    rdsEnabled: false,
  };
  public selectedProjectId = '';
  private requestHeaders?: MprHttpHeaderModal = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public store: Store,
    public dialog: MatDialog,
    private alertMsgService: AlertMessageService
  ) {
    this.selectedProjectId = route.snapshot.params['projectId'];
    this.returnPath = route.snapshot.data['back'];
  }

  public formattedMessageToShow(projectForm: projectDetails): string {
    const enabledFeatures: string[] = [];
    if (projectForm.dwAccess) {
        enabledFeatures.push('<strong>Data Warehouse</strong>');
    }
    if (projectForm.hasPowerUsers) {
        enabledFeatures.push('<strong>Heavy Lifting</strong>');
    }
    if (projectForm.externalProjectAccountsEnabled) {
        enabledFeatures.push('<strong>Powered by Mquiry</strong>');
    }
    if (projectForm.rdsEnabled) {
      enabledFeatures.push('<strong>Relational Database Service</strong>');
  }
    if (enabledFeatures.length > 0) {
        let featuresMessage = `You are enabling `;
        if (enabledFeatures.length === 1) {
            featuresMessage += `${enabledFeatures[0]} access`;
        } else if (enabledFeatures.length === 2) {
            featuresMessage += `${enabledFeatures[0]} and ${enabledFeatures[1]} access`;
        } else {
            // Join all but the last feature with a comma and a space
            featuresMessage += `${enabledFeatures.slice(0, -1).join(', ')}`;
            // Add 'and' before the last feature without a comma
            featuresMessage += ` and ${enabledFeatures[enabledFeatures.length - 1]} access`;
        }
        featuresMessage += ` to this project.<br/><br/>Are you sure you want to continue?`;
        return featuresMessage;
    } else {
        return `Are you sure you want to continue?`;
    }
 }

  ngOnDestroy(): void {
    this.dialog.closeAll();
    this.store.dispatch(new ResetProjectData());
  }

  ngOnInit(): void {
    this.isAccountingFeatureEnabled = this.store
        .selectSnapshot(FeatureFlagsState.returnFeatureFlags)
        .isAccountingFeatureFlagEnabled.toLowerCase() === 'true'
    this.requestHeaders = {};
    this.requestHeaders[HeaderParams.ROLENAME] =
      UserProjectRoleEnum.PLATFORM_ADMIN;
    this.store.dispatch(new GetGobalAttributes(this.requestHeaders));
    this.globalAttributesObservable$?.subscribe((attributes)=> {
      this.globalAttributes = attributes;
    })
    
    this.requestHeaders = {};
    this.requestHeaders[HeaderParams.PROJECTID] = this.selectedProjectId;
    this.requestHeaders[HeaderParams.ROLENAME] =
      UserProjectRoleEnum.PLATFORM_ADMIN;
    this.store.dispatch(new GetProjectDeatils(this.requestHeaders));

    this.projectDetails$
      ?.pipe(
        catchError((err) => {
          this.alertMsgService.error({
            body: err.error.message,
          });
          return throwError(() => new Error(''));
        })
      )
      .subscribe((res: projectDetails) => {
        if (res && res.projectName) {
          this.selectedProjectDetails = { ...res };
        }
      });

    const featureFlags: FeatureFlagsStateModel = this.store.selectSnapshot(
      FeatureFlagsState.returnFeatureFlags
    );
    this.blockExternalDomain =
      featureFlags.blockExternalDomainForEmailDistributionList;
    const isBlockExternalDomain =
      this.blockExternalDomain?.toLowerCase() === 'true';

    if (!isBlockExternalDomain) {
      this.store.dispatch(
        new GetAllowedDomains(
          this.selectedProjectId,
          UserProjectRoleEnum.PLATFORM_ADMIN
        )
      );
    }

    this.allowedDomains$?.subscribe((externalDomains: Array<string>) => {
      this.allowedExternalDomains = externalDomains;
    });
  }

  public showCancelPopup(): void {
    this.dialog
      .open(MprConfirmComponent, {
        disableClose: true,
        data: {
          confirmTitle: 'Cancel Confirmation',
          confirmMessage: `You will lose any changes you made. Are you sure you want to cancel?`,
          cancelButtonText: 'Go Back',
          confirmButtonText: 'Proceed',
          confirmData: true,
        },
      })
      .afterClosed()
      .subscribe((proceed: boolean) => {
        if (proceed) {
          this.router.navigate([
            `/platformAdmin/projects/view/${this.selectedProjectId}`,
          ]);
        }
      });
  }

  public showConfirmPopup(projectdata: any): void {
    if (projectdata.projectFormValue.hasPowerUsers === false) {
      projectdata.changedFormValue.hasPowerUsers = false;
    }
    if (projectdata.projectFormValue.externalProjectAccountsEnabled === false) {
      projectdata.changedFormValue.externalProjectAccountsEnabled = false;
    }
    if (projectdata.projectFormValue.rdsEnabled === false) {
      projectdata.changedFormValue.rdsEnabled = false;
    }
    const popupContentMessage = this.formattedMessageToShow(
      projectdata.changedFormValue
    );
    this.dialog
      .open(MprConfirmComponent, {
        disableClose: true,
        data: {
          confirmTitle: 'Edit Project',
          confirmMessage: `${popupContentMessage}`,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Proceed',
          confirmData: true,
        },
      })
      .afterClosed()
      .subscribe((proceed: boolean) => {
        if (proceed) {
          this.store
            .dispatch(
              new EditAProject(
                projectdata.changedFormValue,
                UserProjectRoleEnum.PLATFORM_ADMIN,
                this.selectedProjectId
              )
            )
            .pipe(
              withLatestFrom(this.projectCreation$),
              catchError((err) => {
                this.store.dispatch(new SetLoaderState(false));
                this.alertMsgService.error({
                  body: err.error.message,
                });
                return throwError(() => new Error(''));
              })
            )
            .subscribe(([_, res]) => {
              if (res) {
                if (res === 'Success') {
                  timer(1000).subscribe(() => {
                    this.alertMsgService.success({
                      body: 'Project details edited successfully.',
                    });
                  });
                }
                if (
                  res ===
                  'No new value for project attribute has been provided, thus project record will not be updated.'
                ) {
                  timer(1000).subscribe(() => {
                    this.alertMsgService.success({
                      body: res,
                    });
                  });
                }
                const backUrl = `platformAdmin/projects/view/${this.selectedProjectId}`;
                this.router.navigate([backUrl]);
              }
            });
        }
      });
  }

  public showloader(loader: any): boolean {
    return this.isAccountingFeatureEnabled ? 
     loader.globalAttribute || loader.projectData : loader.projectData;
  }
}
