import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertMessageService } from '@core/services';
import { Select, Store } from '@ngxs/store';
import { CommonResponseModel } from '@shared/interfaces';
import { WarningPopupInputModel } from '@shared/interfaces/warning-popup-input-model';
import { RenameS3Folder, S3PickerState } from 'app/state/s3-picker';
import { Observable, catchError, throwError, withLatestFrom } from 'rxjs';

@Component({
  selector: 'mpr-warning-popup',
  templateUrl: './warning-popup.component.html',
  styleUrls: ['./warning-popup.component.scss'],
})
export class WarningPopupComponent {
  @Select(S3PickerState.GetAddedEditedDeletedFolder)
  private createEditS3FolderResponse$!: Observable<CommonResponseModel>;
  constructor(
    private alertMsgService: AlertMessageService,
    private store: Store,
    private dialogRef: MatDialogRef<WarningPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WarningPopupInputModel
  ) {}

  public replace(isCopy: boolean, typeOfOperation?: string): void {
    const fileType = this.data.isFile ? 'True' : 'False';
    let message = '';
    if (this.data.isFile) {
      message =
        typeOfOperation === 'replace'
          ? `Replacing "${this.data.displayName}" in the destination folder. It might take a few minutes.`
          : `Moving a copy of "${this.data.displayName}" to the destination folder. It might take a few minutes, please wait until you see the file/folder is deleted.`;
    } else {
      message = `Moving a copy of "${this.data.displayName}" to the destination folder.For folders with large files, it might take a few minutes, please wait until you see the file/folder is deleted, please wait until you see the file/folder is deleted.`;
    }
    this.store
      .dispatch(
        new RenameS3Folder(
          this.data.prefix,
          'move',
          this.data.destinationPrefix,
          fileType,
          isCopy
        )
      )
      .pipe(
        withLatestFrom(this.createEditS3FolderResponse$),
        catchError((err) => {
          this.alertMsgService.error({
            body: err.error.message,
            autoDismiss: true,
          });
          return throwError(() => new Error(''));
        })
      )
      .subscribe(([_, response]) => {
        this.alertMsgService.success({
          body: message,
          autoDismiss: false,
        });
        this.dialogRef.close({ result: 'success' });
      });
  }
}
