import { UserProject } from '@theme/interfaces';

export default interface UserProjectStateModel {
  message: string;
  projects: UserProject[];
  projectsLoaded: boolean;
  selectedProject: UserProject;
  selectedProjectId: string;
  status: string;
  userProjectsLoaded: boolean;
}
