import { UploadStatusesEnum } from '@core';
import { MprReadyFile, URLListModel } from 'app/uploads/interfaces';

export interface OneTimeUploadHistory {
  dataStore?: string;
  datasetId?: string;
  datasetName?: string;
  description?: string;
  destinationFolder?: string;
  displaySource?: Array<string>;
  filesReadyForSearch?: Array<string>;
  jobId?: string;
  jobStatus: UploadStatusesEnum;
  noOfFilesReadyForSearch?: string;
  noOfFilesReadyForStaging?: string;
  noOfQuarantinedFiles?: string;
  noOfSkippedFiles?: string;
  noOfSnowflakeUrls?: string;
  noOfUrls?: string;
  quarantinedFiles?: Array<string>;
  quarantinedSupportingFiles?: Array<string>;
  resourceNames?: Array<string>;
  scan_skipped?: boolean;
  skippedFiles?: Array<MprReadyFile>;
  snowflakeUrls?: Array<string>;
  stagedFiles?: Array<string>;
  stagingJobId?: string;
  supportingFilesReadyForSearch?: Array<string>;
  totalNoOfFiles?: string;
  uploadDate: string;
  uploadType?: string;
  urls?: URLListModel[];
  userName?: string;
}
