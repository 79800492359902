import { ConnectionAddResponse, ConnectionModel } from '@shared/interfaces';

export interface ConnectionStateModel {
  connections: ConnectionModel[];
  lastAddedConnection: ConnectionAddResponse;
  loadingConnection: boolean;
  loadingConnections: boolean;
  selectedConnection: ConnectionModel;
  selectedConnectionId: string;
}
