import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
  } from '@angular/core';
  import { Select, Store } from '@ngxs/store';
  import { AlertMessageService } from '@core/services';
  import { ProjectUserList } from 'app/state/project-user/project-user-list-model';
  import {
    CommonResponseModel,
    MprConfirmComponent,
  } from '@shared';
  import { UserProjectRoleEnum, WorkspaceStatus } from '@core';
  import { Observable, catchError, throwError, withLatestFrom } from 'rxjs';
  import { LaunchAWSWorkspaceState, ResetAWSWorkspaceStatus, TerminateUserAWSWorkspace } from 'app/state';
  import { MatDialog } from '@angular/material/dialog';
  import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
  import { UntilDestroy } from '@ngneat/until-destroy';
  
  @UntilDestroy({ checkProperties: true })
  @Component({
    selector: 'mpr-reconfigure-workspace',
    templateUrl: './reconfigure-workspace.component.html',
    styleUrls: ['./reconfigure-workspace.component.scss'],
  })
  export class ReconfigureWorkspaceComponent implements OnInit, OnDestroy {
    @Input() public action = 'edit';
    @Input() public data: ProjectUserList = {
        amazonWorkspace: { },
        ec2Status: '',
        emailId: '',
        firstName: '',
        isPowerUser: false,
        lastName: '',
        machineSize: '',
        projectId: '',
        projectRestrictedDataUseGroups: [],
        roleName: '',
        userId: '',
        userStatus: '',
      };
    @Select(LaunchAWSWorkspaceState.getLaunchAws)
    private awsStatus$!: Observable<CommonResponseModel>;
    public workspaceStatus = 'Not Provisioned';

    constructor(
        private dialog: MatDialog,
        private store: Store,
        private alertService: AlertMessageService){}


    public displayStatusColor(status: string): string {
        if (
            status === 'PENDING' ||
            status === 'STARTING' ||
            status === 'REPROVISIONING' ||
            status === 'TERMINATING'
        ) {
            return 'yellow';
        } else if (
            status === 'STOPPED' ||
            status === 'STOPPING' ||
            status === 'FAILED' ||
            status === 'REPROVISIONING_FAILED' ||
            status === 'TERMINATION FAILED'
        ) {
            return 'red';
        } else if (status === 'AVAILABLE') {
            return 'green';
        } else if (status === 'NOT_PROVISIONED' || status === 'TERMINATED') {
            return 'grey';
        } else {
            return '';
        }
    }

    public displayTerminateWorkspaceButton() : boolean {
        return ['AVAILABLE', 'STOPPED', 'STOPPING', 'STARTING', 'REPROVISIONING', 'TERMINATING', 'REPROVISION_FAILED', 'REPROVISIONING', 'TERMINATION FAILED'].indexOf(this.workspaceStatus) > -1
    }

    public displayWorkspaceStatus(status : string) : string {
        if(status === 'NOT_PROVISIONED' || status === 'REPROVISION_FAILED'){
            const display = status.replace('_', ' ');
             return display;
        }
        return status;
    }

    public isWorkspaceInProgressStatus() : boolean {
        return ['STOPPING', 'STARTING', 'REPROVISIONING', 'TERMINATING'].indexOf(this.workspaceStatus) > -1
    }

    ngOnDestroy(): void {
        this.store.dispatch(new ResetAWSWorkspaceStatus());
      }

    ngOnInit(): void {
        this.workspaceStatus = this.data.amazonWorkspace?.workspaceStatus || 'Not Provisioned'
    }

    public showTerminateAWSConfirmationPopup(): void {
        const dialogRef = this.dialog.open(MprConfirmComponent, {
            data: {
              confirmTitle: 'Terminate Amazon Workspace',
              confirmMessage: `Are you sure you want to terminate the amazon workspace for <b>${this.data['firstName']} ${this.data['lastName']}</b>? <br><br> The user will lose all data stored locally on the workspace.`,
              confirmData: {
                userId : this.data.userId,
                projectId : this.data.projectId,
              },
            },
          });
          dialogRef.afterClosed().subscribe((confirmData: any): void => {
            if (!confirmData) return;
            const requestHeaders: MprHttpHeaderModal = {};
            requestHeaders[HeaderParams.ROLENAME] =
              UserProjectRoleEnum.PLATFORM_ADMIN;
            this.workspaceStatus = WorkspaceStatus.TERMINATING;
            this.store.dispatch(new TerminateUserAWSWorkspace(confirmData, requestHeaders))
            .pipe(
                withLatestFrom(this.awsStatus$),
                catchError((err) => {
                  this.workspaceStatus = 'TERMINATION FAILED';
                  this.alertService.error({
                    body: `Unable to terminate the instance please try again.`,
                  });
                  return throwError(() => new Error(''));
                })
              )
              .subscribe(([_, response]) => {
                if(response['status_code'] !== 200){
                    this.workspaceStatus = 'TERMINATION FAILED';
                    this.alertService.error({
                        body: response.message
                    });
                } else {
                    this.alertService.success({
                        body: `Amazon Workspace of ${this.data['firstName']} ${this.data['lastName']} is being terminated, you will receive an email when it is completed.`,
                        autoDismiss: false,
                    });
                    
                    this.workspaceStatus = 'NOT_PROVISIONED';
                }
              });
          });
    }

  }
  