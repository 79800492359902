import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { CommonResponseModel } from '@shared/interfaces';
import { RDSClusterUsersResponse } from 'app/state';
import { RDSResponse } from 'app/state/manage-rds/manage-rds.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ManageRDSService {
  private rdsClusterMgmtEndpoint = '/rds';
  private rdsClusterUsersEndpoint = '/rds/users';

  constructor(private http: HttpClient) {}

  public addUserToRDSCluster(
    projectId: string,
    rdsResourceId: string,
    userId: string,
    requestHeaders: MprHttpHeaderModal
  ): Observable<CommonResponseModel> {
    const headers = this.getRequestHeaders(requestHeaders);
    return this.http.post<CommonResponseModel>(
      this.rdsClusterUsersEndpoint,
      {
        projectId,
        rdsResourceId,
        userId,
      },
      { headers }
    );
  }

  public deleteRDSCluster(
    projectId: string,
    rdsResourceId: string,
    requestHeaders: MprHttpHeaderModal
    ): Observable<CommonResponseModel> {
    const headers = this.getRequestHeaders(requestHeaders);
    const body = {
      projectId,
      rdsResourceId,
    };
    return this.http.delete<CommonResponseModel>(this.rdsClusterMgmtEndpoint, {
      body,
      headers
    }
    );
   }
  
  public deleteUserFromRDSCluster(
    projectId: string,
    rdsResourceId: string,
    userId: string,
    requestHeaders: MprHttpHeaderModal
  ): Observable<CommonResponseModel> {
    const headers = this.getRequestHeaders(requestHeaders);
    return this.http.delete<CommonResponseModel>(this.rdsClusterUsersEndpoint, {
      body: {
        projectId,
        rdsResourceId,
        userId,
      },
      headers,
    });
  }

  public getRDSClusterUsers(
    clusterId: string,
    requestHeaders?: MprHttpHeaderModal
  ): Observable<RDSClusterUsersResponse> {
    const rdsUserAPI = `/rds/users?rdsResourceId=${clusterId}`;
    if (requestHeaders) {
      const headers = this.getRequestHeaders(requestHeaders);
      return this.http.get<RDSClusterUsersResponse>(rdsUserAPI, { headers });
    }

    return this.http.get<RDSClusterUsersResponse>(rdsUserAPI);
  }

  public getRDSClusters(
    requestHeaders: MprHttpHeaderModal
  ): Observable<RDSResponse> {
    const headers = this.getRequestHeaders(requestHeaders);
    return this.http.get<RDSResponse>(this.rdsClusterMgmtEndpoint, { headers });
  }

  public setupRDSCluster(
    projectId: string,
    clusterName: string,
    requestHeaders: MprHttpHeaderModal
  ): Observable<CommonResponseModel> {
    const headers = this.getRequestHeaders(requestHeaders);
    return this.http.post<CommonResponseModel>(
      this.rdsClusterMgmtEndpoint,
      { projectId, clusterName },
      { headers }
    );
  }

  public startOrStopCluster(
    projectId: string,
    rdsResourceId: string,
    action: string,
    requestHeaders: MprHttpHeaderModal
  ): Observable<CommonResponseModel> {
    const headers = this.getRequestHeaders(requestHeaders);
    return this.http.put<CommonResponseModel>(
      this.rdsClusterMgmtEndpoint,
      { projectId, rdsResourceId, action },
      { headers }
    );
  }

  private getRequestHeaders(headers: MprHttpHeaderModal): HttpHeaders {
    const requestHeaders = new HttpHeaders()
      .set(HeaderParams.PROJECTID, headers[HeaderParams.PROJECTID])
      .append(HeaderParams.ROLENAME, headers[HeaderParams.ROLENAME]);
    return requestHeaders;
  }
}
