/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */
import { MprHttpHeaderModal } from '@core/interfaces';

export class LoadRDSClusters {
  public static type = '[RDSCusters] Get Clusters ';
  constructor(public requestHeaders: MprHttpHeaderModal) {}
}

export class ResetManageRDSClusters {
  public static type = '[RDSCusters] Reset State ';
}

export class ResetManageRDSClusterCommonResponse {
  public static type = '[RDSCusters] Reset Common response State ';
}

export class SetupCluster {
  public static type = '[RDSClusters] Setup Cluster';
  constructor(
    public projectId: string,
    public clusterName: string,
    public requestHeaders: MprHttpHeaderModal
  ) {}
 }

 export class DeleteRDSCluster {
  public static type = '[RDSClusters] Delete Cluster';
  constructor(
    public projectId: string,
    public rdsResourceId: string,
    public requestHeaders: MprHttpHeaderModal
  ) {}
 }

 export class StartOrStopCluster {
  public static type = '[RDSClusters] StartOrStopCluster Cluster';
  constructor(
    public projectId: string,
    public rdsResourceId: string,
    public action: string,
    public requestHeaders: MprHttpHeaderModal
  ) {}
 }
