import {
  ViewChild,
  TemplateRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  SkipMainContentService,
  ConnectionModel,
  OneTimeUploadHistory,
  RecurringUploadHistory,
} from '@shared';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import {
  ConnectionDataStore,
  StepValueEnum,
  UploadStatusesEnum,
  UploadTypeEnum,
  StackbarColorsEnum,
  UserProjectRoleEnum,
} from '@core';
import { Clipboard } from '@angular/cdk/clipboard';
import { RecurringJobDetailsState } from 'app/state/uploads/recurring-job-details/recurring-job-details.state';
import { DatasetDetailsModel, MprReadyFile } from '../interfaces';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OnetimeJobDetailsState } from 'app/state/uploads/onetime-job-details/onetime-job-details.state';
import {
  ResetOneTimeJobDetails,
  SetOneTimeJobDetails,
} from 'app/state/uploads/onetime-job-details/onetime-job-details.actions';
import {
  ResetRecurringJobDetails,
  SetRecurringJobDetails,
} from 'app/state/uploads/recurring-job-details/recurring-job-details.action';
import * as constants from '@core/constants';
import { environment } from '@env/environment';
import { Observable, withLatestFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {
  CheckDatasetStatus,
  DatasetStatusState,
  DatasetStatusModel,
  Message,
  UserProjectState,
  FeatureFlagsState,
  LoggedinUserState,
} from 'app/state/';
import { HeaderParams, JobDetailsFileCountModel, MprHttpHeaderModal } from '@core/interfaces';
import { UserProject } from '@theme/interfaces';
import {
  ConnectionState,
  GetAllConnections,
  ResetConnectionState,
} from 'app/state/connection';
import { FeatureFlagsStateModel } from 'app/state/feature-flags';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'mpr-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss'],
})
@UntilDestroy({ checkProperties: true })
export class JobDetailsComponent implements OnInit, OnDestroy {
  @Select(UserProjectState.getAllUserProjects)
  public allProject$?: Observable<UserProject[]>;
  @Select(ConnectionState.getAllActiveIngestionConnections)
  public connections$?: Observable<ConnectionModel[]>;
  @Select(DatasetStatusState.getDatasetStatus)
  public datasetStatus$!: Observable<DatasetStatusModel>;
  @Select(FeatureFlagsState.returnFeatureFlags)
  public featureFlags$!: Observable<FeatureFlagsStateModel>;
  @Select(OnetimeJobDetailsState.getOneTimeJobDetails)
  public getOnetimeJobDetails$!: Observable<any>;
  @Select(RecurringJobDetailsState.getRecurringJobDetails)
  public getRecurringJobDetails$!: Observable<any>;
  @ViewChild('skipper') public skipper!: MatButton;
  @ViewChild('datasetDeletedDialog')
  private datasetDeletedDialog!: TemplateRef<any>;
  public chartColors: Array<string> = [];
  public chartData : Array<JobDetailsFileCountModel> = [];
  public commonConstants = constants;
  public connectionDataStoreEnum = ConnectionDataStore;
  public connectionList: ConnectionModel[] = [];
  public contactUs = '';
  public detailsToDisplay = {
    recurring: [
      'scheduleName',
      'dataStore',
      'uploadType',
      'totalNoOfFiles',
      'runDate',
      'description',
      'jobStatus',
    ],
    onetime: [
      'userName',
      'dataStore',
      'uploadType',
      'totalNoOfFiles',
      'uploadDate',
      'description',
      'jobStatus',
    ],
  };
  public filesReadyTableDataSource: Array<MprReadyFile> = [];
  public isMapRequestHeader = false;
  public isScanSkipped = false;
  public isTotalNoOfFilesZero = false;
  public jobOperationType = '';
  public jobStatus: UploadStatusesEnum = UploadStatusesEnum.BLANK;
  public loggedinUserEmailDomain = '';
  public oneTimeJobDetails: OneTimeUploadHistory;
  public projectDeatils: DatasetDetailsModel = {
    datasetId: '',
    projectID: '',
    datasetName: '',
  };
  public quarantineFilesColumns = ['fileName'];
  public quarantinedFiles: Array<MprReadyFile> = [];
  public readyFilesColumns = ['fileName'];
  public recurringJobDetails: RecurringUploadHistory;
  public requestHeaders: MprHttpHeaderModal = {};
  public resourceNames: Array<string> = [];
  public selectedDatasetResponse: Message;
  public selectedDetailType: any = [];
  public selectedJobId: string;
  public selectedUploadType: string;
  public showLoader = true;
  public skippedFiles: Array<MprReadyFile> = [];
  public skippedFilesColumns = ['fileName'];
  public snowflakeUrls: Array<string> = [];
  public snowflakeUrlsColumns = ['filename'];
  public stepValue: any = {
    upload_in_progress: [
      StepValueEnum.INPROGRESS,
      StepValueEnum.PENDING
    ],
    upload_completed: [
      StepValueEnum.COMPLETED,
      StepValueEnum.PENDING
    ],
    cataloging_in_progress: [
      StepValueEnum.COMPLETED,
      StepValueEnum.INPROGRESS
    ],
  };
  public stepValueEnum = StepValueEnum;
  public unsupportedFileTypes = ['EXE', 'COM', 'MSI', 'BAT', 'CGI']; // TODO: Will soon move to a externally loaded API / JSON
  public uploadStatusesEnum = UploadStatusesEnum;
  public uploadTypeEnum = UploadTypeEnum;
  public workspaceServiceNowUrl = environment.workspaceChangeServiceNowUrl;

  constructor(
    private clipboard: Clipboard,
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private skipMainContentService: SkipMainContentService,
  ) {
    this.selectedUploadType = route.snapshot.params['uploadType'];
    this.selectedJobId = route.snapshot.params['jobId'];
    this.oneTimeJobDetails = {
      uploadDate: '',
      userName: '',
      dataStore: '',
      description: '',
      noOfSkippedFiles: '',
      totalNoOfFiles: '',
      uploadType: '',
      jobStatus: UploadStatusesEnum.BLANK,
      jobId: '',
      filesReadyForSearch: [],
      quarantinedFiles: [],
      quarantinedSupportingFiles: [],
      resourceNames: [],
      skippedFiles: [],
      snowflakeUrls: [],
      supportingFilesReadyForSearch: [],
      noOfQuarantinedFiles: '',
      noOfFilesReadyForSearch: '',
      noOfUrls: '',
    };
    this.recurringJobDetails = {
      connectionName: '',
      uploadType: '',
      totalNoOfFiles: '',
      runDate: '',
      description: '',
      jobStatus: UploadStatusesEnum.BLANK,
      scheduleName: '',
      filesReadyForSearch: [],
      quarantinedFiles: [],
      quarantinedSupportingFiles: [],
      skippedFiles: [],
      supportingFilesReadyForSearch: [],
      noOfSkippedFiles: '',
      noOfQuarantinedFiles: '',
      noOfFilesReadyForSearch: '',
      dataStore: '',
      frequency: '',
      scheduleId: '',
      scheduleJobId: '',
    };
    this.selectedDatasetResponse = {
      actionDate: '',
      actionType: '',
      userName: '',
    };
  }

  public checkAndAddFiles(): void {
    const datasetId =
      (this.selectedUploadType === UploadTypeEnum.RECURRING
        ? this.recurringJobDetails.datasetId
        : this.oneTimeJobDetails.datasetId) || '';
    this.store
      .dispatch(new CheckDatasetStatus(datasetId))
      .pipe(withLatestFrom(this.datasetStatus$))
      .subscribe(([_, response]) => {
        if (response.status_code) {
          if (
            response.message.actionType === 'deleted' ||
            response.message.actionType === 'purged'
          ) {
            this.selectedDatasetResponse = response.message;
            this.dialog.open(this.datasetDeletedDialog);
          } else this.routeToAddFiles();
        }
      });
  }

  public closeDeletedDatasetDialog(): void {
    this.dialog.closeAll();
  }

  public copyToClipBoard(s3URL: string): void {
    this.clipboard.copy(s3URL);
  }

  public fetchRouting(): void {
    const projectDetails = this.route.snapshot.paramMap.get('projectDetails');
    if (projectDetails) {
      this.projectDeatils = JSON.parse(atob(projectDetails));
      this.isMapRequestHeader = true;
    }
  }

  public generateCkanURL(): string {
    const datasetName =
      this.selectedUploadType === UploadTypeEnum.RECURRING
        ? this.recurringJobDetails.datasetName
        : this.oneTimeJobDetails.datasetName;
    return `${environment.ckanUrl}/dataset/${datasetName}`;
  }
  public getOneTimeJobDetails(requestHeaders?: MprHttpHeaderModal): void {
    this.store
      .dispatch(
        new SetOneTimeJobDetails(this.selectedJobId, '', this.requestHeaders)
      )
      .pipe(withLatestFrom(this.getOnetimeJobDetails$))
      .subscribe(([_, response]) => {
        this.showLoader = false;
        this.oneTimeJobDetails = response;
        this.jobStatus = this.oneTimeJobDetails.jobStatus;
        this.isScanSkipped = this.oneTimeJobDetails.scan_skipped || true;
        this.skippedFiles =
          this.oneTimeJobDetails.skippedFiles?.slice(0, 30) || [];
        this.isTotalNoOfFilesZero = this.isFilesCountNoneOrZero(
          this.oneTimeJobDetails.totalNoOfFiles
        );
        this.quarantinedFiles = this.getFileList(
          this.oneTimeJobDetails.quarantinedFiles || []
        ).slice(0, 30);
        this.resourceNames =
          this.oneTimeJobDetails.resourceNames?.slice(0, 30) || [];
        this.filesReadyTableDataSource = this.getFileList(
          this.oneTimeJobDetails.filesReadyForSearch || []
        ).slice(0, 30);
        if (
          !this.isFilesCountNoneOrZero(
            this.oneTimeJobDetails.noOfFilesReadyForSearch
          )
        ) {
          this.chartData.push({
            name: constants.FILES_READY_FOR_SEARCH,
            data: [Number(this.oneTimeJobDetails.noOfFilesReadyForSearch)],
          });
          this.chartColors.push(StackbarColorsEnum.READY);
        }
        if (
          !this.isFilesCountNoneOrZero(
            this.oneTimeJobDetails.noOfQuarantinedFiles
          )
        ) {
          this.chartData.push({
            name: constants.QUARANTINED_FILES,
            data: [Number(this.oneTimeJobDetails.noOfQuarantinedFiles)],
          });
          this.chartColors.push(StackbarColorsEnum.QUARANTINED);
        }
        if (
          !this.isFilesCountNoneOrZero(this.oneTimeJobDetails.noOfSkippedFiles)
        ) {
          this.chartData.push({
            name: constants.SKIPPED_FILES,
            data: [Number(this.oneTimeJobDetails.noOfSkippedFiles)],
          });
          this.chartColors.push(StackbarColorsEnum.SKIPPED);
        }
        if (!this.isFilesCountNoneOrZero(this.oneTimeJobDetails.noOfUrls)) {
          this.chartData.push({
            name: constants.URLS,
            data: [Number(this.oneTimeJobDetails.noOfUrls)],
          });
          this.chartColors.push(StackbarColorsEnum.SNOWFLAKE);
        }
      });
  }

  public getStepStatus(
    stepNumber: number,
    jobStatus: UploadStatusesEnum
  ): string {
    const returnStepValue = this.stepValue[jobStatus];
    if (
      this.isScanSkipped &&
      jobStatus === UploadStatusesEnum.CATALOGING_IN_PROGRESS
    ) {
      returnStepValue[1] = StepValueEnum.SKIPPED;
    }
    return returnStepValue[stepNumber - 1];
  }

  public isCompletedJob(jobStatus: UploadStatusesEnum): boolean {
    return [
      UploadStatusesEnum.COMPLETED,
      UploadStatusesEnum.COMPLETED_WITH_ERRORS,
    ].includes(jobStatus);
  }

  public isFailedJob(jobStatus: UploadStatusesEnum): boolean {
    return [
      UploadStatusesEnum.UPLOAD_FAILED,
      UploadStatusesEnum.SCAN_FAILED,
      UploadStatusesEnum.CATALOGING_FAILED,
    ].includes(jobStatus);
  }

  public isInProgressJob(jobStatus: UploadStatusesEnum): boolean {
    return [
      UploadStatusesEnum.UPLOAD_IN_PROGRESS,
      UploadStatusesEnum.UPLOAD_COMPLETED,
      UploadStatusesEnum.SCAN_IN_PROGRESS,
      UploadStatusesEnum.SCAN_COMPLETED,
      UploadStatusesEnum.SCAN_SKIPPED,
      UploadStatusesEnum.CATALOGING_IN_PROGRESS,
    ].includes(jobStatus);
  }

  public isQuarantineSupportingDoc(fileLocation: string): boolean {
    let result = false;
    if (
      this.selectedUploadType === this.uploadTypeEnum.RECURRING &&
      this.recurringJobDetails.quarantinedSupportingFiles
    ) {
      result =
        this.recurringJobDetails.quarantinedSupportingFiles.includes(
          fileLocation
        );
    } else if (
      this.selectedUploadType === this.uploadTypeEnum.ONETIME &&
      this.oneTimeJobDetails.quarantinedSupportingFiles
    ) {
      result =
        this.oneTimeJobDetails.quarantinedSupportingFiles.includes(
          fileLocation
        );
    }
    return result;
  }

  public isReadyFileSupportingDoc(fileLocation: string): boolean {
    let result = false;
    if (
      this.selectedUploadType === this.uploadTypeEnum.RECURRING &&
      this.recurringJobDetails.supportingFilesReadyForSearch
    ) {
      result =
        this.recurringJobDetails.supportingFilesReadyForSearch.includes(
          fileLocation
        );
    } else if (
      this.selectedUploadType === this.uploadTypeEnum.ONETIME &&
      this.oneTimeJobDetails.supportingFilesReadyForSearch
    ) {
      result =
        this.oneTimeJobDetails.supportingFilesReadyForSearch.includes(
          fileLocation
        );
    }
    return result;
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetOneTimeJobDetails());
    this.store.dispatch(new ResetRecurringJobDetails());
  }

  ngOnInit(): void {
    this.skipMainContentService.skip$.subscribe((res) =>{
      this.skipper.focus();
    })
    this.fetchRouting();
    this.skippedFiles = [];
    this.snowflakeUrls = [];
    this.resourceNames = [];
    this.quarantinedFiles = [];
    this.filesReadyTableDataSource = [];

    this.featureFlags$.subscribe((featureFlags: FeatureFlagsStateModel) => {
      this.loggedinUserEmailDomain = this.store.selectSnapshot(
        LoggedinUserState.getLoggedInUserEmail
      ).split('@')[1];
      const isLoggedInUserInternal = featureFlags.internalDomainList.findIndex((x) => x === this.loggedinUserEmailDomain) > -1;
      this.contactUs = isLoggedInUserInternal ? featureFlags.internalContactUsUrl : featureFlags.externalContactUsUrl;
    });
    if (this.selectedUploadType === this.uploadTypeEnum.RECURRING) {
      this.store
        .dispatch(new SetRecurringJobDetails(this.selectedJobId, this.jobOperationType))
        .pipe(withLatestFrom(this.getRecurringJobDetails$))
        .subscribe(([_, response]) => {
          this.showLoader = false;
          this.recurringJobDetails = response;
          this.jobStatus = this.recurringJobDetails.jobStatus;
          this.isScanSkipped = this.recurringJobDetails.scan_skipped || true;
          this.skippedFiles =
            this.recurringJobDetails.skippedFiles?.slice(0, 30) || [];
          this.isTotalNoOfFilesZero = this.isFilesCountNoneOrZero(
            this.recurringJobDetails.totalNoOfFiles
          );
          this.quarantinedFiles = this.getFileList(
            this.recurringJobDetails.quarantinedFiles || []
          ).slice(0, 30);
          this.filesReadyTableDataSource = this.getFileList(
            this.recurringJobDetails.filesReadyForSearch || []
          ).slice(0, 30);
          if (
            !this.isFilesCountNoneOrZero(
              this.recurringJobDetails.noOfFilesReadyForSearch
            )
          ) {
            this.chartData.push({
              name: constants.FILES_READY_FOR_SEARCH,
              data: [Number(this.recurringJobDetails.noOfFilesReadyForSearch)],
            });
            this.chartColors.push(StackbarColorsEnum.READY);
          }
          if (
            !this.isFilesCountNoneOrZero(
              this.recurringJobDetails.noOfQuarantinedFiles
            )
          ) {
            this.chartData.push({
              name: constants.QUARANTINED_FILES,
              data: [Number(this.recurringJobDetails.noOfQuarantinedFiles)],
            });
            this.chartColors.push(StackbarColorsEnum.QUARANTINED);
          }
          if (
            !this.isFilesCountNoneOrZero(
              this.recurringJobDetails.noOfSkippedFiles
            )
          ) {
            this.chartData.push({
              name: constants.SKIPPED_FILES,
              data: [Number(this.recurringJobDetails.noOfSkippedFiles)],
            });
            this.chartColors.push(StackbarColorsEnum.SKIPPED);
          }
        });
    } else {
      if (this.projectDeatils.datasetId) {
        this.allProject$?.subscribe((allProjects: UserProject[]) => {
          this.store.dispatch(new ResetConnectionState());
          const selectedProject = allProjects.find(
            (project: UserProject) =>
              project.projectId === this.projectDeatils.projectID
          );
          this.requestHeaders = {};
          this.connectionList = [];
          if (selectedProject) {
            this.requestHeaders[HeaderParams.ROLENAME] =
              selectedProject?.roleName || UserProjectRoleEnum.BLANK;
            this.requestHeaders[HeaderParams.PROJECTID] =
              this.projectDeatils.projectID || '';
            if (selectedProject?.roleName !== UserProjectRoleEnum.RESEARCHER && selectedProject?.roleName !== UserProjectRoleEnum.EXTERNAL_RESEARCHER)
              this.store.dispatch(new GetAllConnections(this.requestHeaders));
            else this.connectionList.push(constants.LOCAL_DRIVE_SOURCE);
          }
          this.connections$?.subscribe((connections: ConnectionModel[]) => {
            this.connectionList = [...connections];
          });
          this.getOneTimeJobDetails(this.requestHeaders);
        });
      } else {
        this.getOneTimeJobDetails();
      }
    }
  }

  public redirectToCkan(): void {
    const ckanUrl = this.generateCkanURL();
    window.location.href = ckanUrl;
  }

  public routeToAddFiles(): void {
    const jobId =
      this.selectedUploadType === this.uploadTypeEnum.RECURRING
        ? this.recurringJobDetails.scheduleJobId
        : this.oneTimeJobDetails.jobId;
    this.router.navigate([`/uploads/onetime/job/${jobId}`]);
  }

  public showFilesGridOrError(
    jobStatus: UploadStatusesEnum,
    selectedUploadType: string
  ): boolean {
    // returns true for error and false for grid
    return selectedUploadType === this.uploadTypeEnum.ONETIME
      ? this.isFailedJob(jobStatus)
      : this.isFilesCountNoneOrZero(
          this.recurringJobDetails.noOfSkippedFiles
        ) &&
          this.isFilesCountNoneOrZero(
            this.recurringJobDetails.noOfQuarantinedFiles
          ) &&
          this.isFilesCountNoneOrZero(
            this.recurringJobDetails.noOfFilesReadyForSearch
          );
  }

  public showNote(tableType: string): boolean {
    if (tableType === 'skippedFiles')
      return this.selectedUploadType === UploadTypeEnum.RECURRING
        ? Number(this.recurringJobDetails.noOfSkippedFiles) > 30
        : Number(this.oneTimeJobDetails.noOfSkippedFiles) > 30;
    else if (tableType === 'quarantinedFiles')
      return this.selectedUploadType === UploadTypeEnum.RECURRING
        ? Number(this.recurringJobDetails.noOfQuarantinedFiles) > 30
        : Number(this.oneTimeJobDetails.noOfQuarantinedFiles) > 30;
    else if (tableType === 'snowflakeUrls')
      return Number(this.oneTimeJobDetails.noOfUrls) > 30;
    else
      return this.selectedUploadType === UploadTypeEnum.RECURRING
        ? Number(this.recurringJobDetails.noOfFilesReadyForSearch) > 30
        : Number(this.oneTimeJobDetails.noOfFilesReadyForSearch) > 30;
  }

  private getFileList(filesReadyForSearch: Array<string>): Array<MprReadyFile> {
    const fileList: Array<MprReadyFile> = [];

    if (filesReadyForSearch.length > 0) {
      for (let index = 0; index < filesReadyForSearch.length; index++) {
        const s3Location = filesReadyForSearch[index] ?? '';
        let fileName = s3Location.split('/').pop() || '';
        const fileNameSplitArray = fileName.split('_');
        const timeStampAndExtension = fileNameSplitArray.pop();
        fileName = `${fileNameSplitArray.join('_')}.${timeStampAndExtension
          ?.split('.')
          .pop()}`;
        fileList[index] = {
          fileName,
          s3Location,
        };
      }
      return fileList;
    }
    return fileList;
  }

  private isFilesCountNoneOrZero(count: string = ''): boolean {
    return count === '0' || count === constants.NONE || count === '';
  }
}
