<div fxLayout="column" class="mpr-mat-dialog-small">
  <h1 mat-dialog-title>SET UP RDS CLUSTER</h1>
  <div mat-dialog-content class="content-of-popup">
  <form [formGroup]="setupRDSForm" (ngSubmit)="onSave()">
  <div class="form-group">
  <div class="mpr-form-block-field m-t-3">
  <label for="Project Name" class="mpr-form-block-field-label text-uppercase">Project Name</label>
  <input
    id="Project Name"
    aria-label="Project Name"
    class="mpr-metadata-form-block-field-input"
    [disabled]="true"
    formControlName="projectName"
  />
  </div>
  </div>
  <div class="form-group">
    <div class="mpr-form-block-field m-t-3">
    <label for="Cluster Name" class="mpr-form-block-field-label text-uppercase">Cluster Name</label>
    <input
      id="Cluster Name"
      aria-label="Cluster Name"
      class="mpr-cluster mpr-metadata-form-block-field-input"
      formControlName="clusterName"
    />
    <mat-error *ngIf="setupRDSForm.get('clusterName')?.touched && setupRDSForm.get('clusterName')?.hasError('required')">
      Please enter Cluster Name.
    </mat-error>
    <mat-error *ngIf="setupRDSForm.get('clusterName')?.touched && !setupRDSForm.get('clusterName')?.hasError('required') && setupRDSForm.get('clusterName')?.hasError('whitespace')">
      Cluster Name is invalid.
    </mat-error>
    <mat-error *ngIf="setupRDSForm.get('clusterName')?.touched && setupRDSForm.get('clusterName')?.hasError('maxlength')">
      Cluster Name cannot exceed 100 characters.
    </mat-error>
    <mat-error *ngIf="setupRDSForm.get('clusterName')?.touched && setupRDSForm.get('clusterName')?.hasError('uniqueClusterName')">
      Cluster Name already exists.
    </mat-error>
    </div>
    </div>
  <div class="form-group">
  <div class="mpr-form-block-field m-t-3">
  <label for="DB Engine" class="mpr-form-block-field-label text-uppercase">DB Engine</label>
  <input
    id="DB Engine"
    aria-label="DB Engine"
    class="mpr-metadata-form-block-field-input"
    [disabled]="true"
    formControlName="dbEngine"
  />
  </div>
  </div>
  <div class="form-group">
  <div class="mpr-form-block-field m-t-3">
  <label for="Capacity" class="mpr-form-block-field-label text-uppercase">Capacity</label>
  <input
    id="Capacity"
    aria-label="Capacity"
    class="mpr-metadata-form-block-field-input"
    [disabled]="true"
    formControlName="capacity"
  />
  </div>
  </div>
  <div class="mpr-progressbar-actions" mat-dialog-actions>
  <div>
  <button
    mat-stroked-button
    mat-dialog-close
    type="button"
    class="mpr-button mpr-blue-button mpr-cancel-button"
  >
    Cancel
  </button>
  <button
    type="submit"
    mat-button
    class="mpr-save-button mpr-button mpr-yellow-button mpr-button-authorize"
    [disabled]="setupRDSForm.invalid"
  >
    Save
  </button>
  </div>
  </div>
  </form>
  </div>
  </div>