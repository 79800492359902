<div class="workspace-container">
  <div
    class="mpr-dashboard-loader"
    *ngIf="fetchUserApiInProgress$ | async; else showWorkspaceStatus"
  >
    <div class="mpr-workspace-loader-container">
      <div class="mpr-noresutl-error">
        <span>
          <mat-spinner diameter="45" color="accent"></mat-spinner>
        </span>
      </div>
    </div>
  </div>
  <ng-template #showWorkspaceStatus>
    <div class="d-flex">
      <div class="workspace-top-box">
        <ng-container [ngSwitch]="workspaceStatus">
          <div
            class="workspace-top-box-content"
            *ngSwitchCase="workspaceStatusEnum.NOT_PROVISIONED"
            id="notProvisionedMessage"
          >
            Click <b>SETUP WORKSPACE</b> and create a new password for login.
            <br />
            The setup may take upto 45 mins, you will receive an email with
            instructions when it’s ready.
          </div>
          <div *ngSwitchCase="workspaceStatusEnum.PENDING" id="pendingMessage">
            Your workspace is being setup. It may take up to 45 mins, you will
            receive an email with instructions when it’s ready.
          </div>
          <div
            *ngSwitchCase="workspaceStatusEnum.REPROVISIONING"
            id="reprovisioningMessage"
          >
            Your workspace is being updated. It may take upto 45 mins, you will
            receive an email when it’s ready.
          </div>
          <div
            *ngSwitchCase="workspaceStatusEnum.AVAILABLE"
            id="availableMessage"
          >
            Your Workspace is <b>ON</b>. Click <b>GO TO WORKSPACE</b>
            to get started.
          </div>
          <div
            *ngSwitchCase="workspaceStatusEnum.STARTING"
            id="startingMessage"
          >
            Your Workspace is <b>INITIALIZING</b>. Click <b>REFRESH</b>
            to check the status.
          </div>
          <div
            *ngSwitchCase="workspaceStatusEnum.STOPPING"
            id="stoppingMessage"
          >
            Your Workspace is <b>STOPPING</b>. Click <b>REFRESH</b> to check the
            status.
          </div>
          <div
            *ngSwitchCase="workspaceStatusEnum.TERMINATING"
            id="stoppingMessage"
          >
            Your Workspace is <b>TERMINATING</b>. Click <b>REFRESH</b> to check
            the status.
          </div>
          <div *ngSwitchCase="workspaceStatusEnum.STOPPED" id="stoppedMessage">
            Your Workspace is <b>OFF</b>. Click <b>LAUNCH WORKSPACE</b>
            to turn it on.
          </div>
          <div *ngSwitchCase="workspaceStatusEnum.FAILED" id="failedMessage">
            <div class="mpr-bold-text">
              Failure in provisioning Amazon Workspace
            </div>
            <div class="mpr-aws-failure-text">
              <img
                class="mpr-warning-icon"
                src="assets/images/warning-icon-red-triangle.svg"
                alt=" aws workspace provision failed warning"
              />
              Your Amazon Workspace setup has failed due to an error. Please try
              again in a few minutes. If the problem continues, contact
              <a
                target="_blank"
                class="mpr-service-now-link f-s-16"
                href="{{ inputs.commonSupportLink }}"
                >{{ 'appName' | appDetails }} Support</a
              >.
            </div>
          </div>
          <div
            *ngSwitchCase="workspaceStatusEnum.REPROVISIONING_FAILED"
            id="reprovisionFailedMessage"
          >
            <div class="mpr-bold-text">
              Failure in updating Amazon Workspace
            </div>
            <div class="mpr-aws-failure-text">
              <img
                class="mpr-warning-icon"
                src="assets/images/warning-icon-red-triangle.svg"
                alt=" aws workspace provision failed warning"
              />
              Your Amazon Workspace update has failed due to an error. Please
              try again in a few minutes. If the problem continues, contact
              <a
                target="_blank"
                class="mpr-service-now-link f-s-16"
                href="{{ inputs.commonSupportLink }}"
                >{{ 'appName' | appDetails }} Support</a
              >.
            </div>
          </div>
        </ng-container>
        <button
          id="btnProvisionWorkspace"
          mat-button
          #skipper
          (click)="setAWSWorkspacePassword(false)"
          class="mat-focus-indicator mpr-button mpr-yellow-button mat-button mat-button-base ng-star-inserted"
          *ngIf="
            workspaceStatus === workspaceStatusEnum.NOT_PROVISIONED ||
            workspaceStatus === workspaceStatusEnum.FAILED
          "
        >
          <mat-icon>power_settings_new</mat-icon>
          SETUP WORKSPACE
        </button>

        <button
          id="btnGotoWorkspace"
          mat-button
          #skipper
          class="mpr-button-disabled mat-focus-indicator mpr-button mpr-yellow-button mat-button mat-button-base ng-star-inserted"
          *ngIf="
            workspaceStatus === workspaceStatusEnum.PENDING ||
            workspaceStatus === workspaceStatusEnum.REPROVISIONING
          "
        >
          <img
            class="power-setting-icon"
            src="./assets/images/open_in_new.svg"
            alt=""
          />
          GO TO WORKSPACE
        </button>

        <button
          id="btnLaunchWorkspace"
          mat-button
          #skipper
          (click)="launchAWSWorkspace()"
          class="mat-focus-indicator mpr-button mpr-yellow-button mat-button mat-button-base ng-star-inserted"
          *ngIf="!isRefresh && workspaceStatus === workspaceStatusEnum.STOPPED"
        >
          <mat-icon>power_settings_new</mat-icon>
          LAUNCH WORKSPACE
        </button>

        <button
          id="btnLaunchWorkspaceStatus"
          mat-button
          #skipper
          class="mpr-button-disabled mpr-aws-workspace-second-btn mat-focus-indicator mpr-button mpr-yellow-button mat-button mat-button-base ng-star-inserted"
          *ngIf="
            isRefresh ||
            workspaceStatus === workspaceStatusEnum.STOPPING ||
            workspaceStatus === workspaceStatusEnum.STARTING
          "
        >
          <img
            class="power-setting-icon"
            src="./assets/images/power_settings.svg"
            alt=""
          />
          LAUNCH WORKSPACE
        </button>

        <button
          id="btnRefresh"
          mat-button
          class="mpr-button mpr-aws-workspace-second-btn"
          *ngIf="isRefresh"
          (click)="doRefresh()"
        >
          <span class="mpr-refresh-img">
            <img src="assets/images/New_Refresh.svg" alt="" role="img" />
          </span>
          <span class="mpr-refresh"> Refresh </span>
        </button>

        <button
          id="btnGotoWorkspaceStatus"
          mat-button
          #skipper
          (click)="navigateToWorkspace()"
          class="mat-focus-indicator mpr-button mpr-yellow-button mat-button mat-button-base ng-star-inserted"
          *ngIf="workspaceStatus === workspaceStatusEnum.AVAILABLE"
        >
          <img
            class="power-setting-icon"
            src="./assets/images/open_in_new.svg"
            alt=""
          />
          GO TO WORKSPACE
        </button>

        <button
          id="btnStopWorkspace"
          mat-button
          (click)="stopAWSWorkspace()"
          class="mpr-button mpr-stop-aws-workspace-btn"
          *ngIf="workspaceStatus === workspaceStatusEnum.AVAILABLE"
        >
          <img class="stop-icon" src="./assets/images/stop.svg" alt="" />
          STOP WORKSPACE
        </button>

        <button
          id="btnUpdateWorkspace"
          mat-button
          (click)="showUpdatePopup()"
          class="mpr-button mpr-yellow-button mat-button mat-button-base"
          *ngIf="workspaceStatus === workspaceStatusEnum.REPROVISIONING_FAILED"
        >
          <img class="stop-icon" src="assets/images/reprovision.svg" alt="" />
          UPDATE WORKSPACE
        </button>
      </div>
      <div
        class="workspace-top-box mpr-update-workspace d-flex mpr-d-flex-dir-col"
        *ngIf="showUpdateWorkspaceSectionOrPopup(true)"
      >
        <div class="mpr-font-style-normal">
          A new version of the workspace is available
        </div>
        <div class="d-flex mpr-justify-content-center">
          <button
            (click)="showUpdatePopup()"
            class="mpr-reprovision-link mpr-cursor-pointer mpr-button mat-button mat-button-base"
            [class.mpr-button-disabled]="checkIfWorkspaceActionInProgress()"
          >
            <img src="assets/images/reprovision.svg" alt="Reprovision" />
            <div class="mpr-reprovison-icon-name">Update Workspace</div>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
  <div
    id="aws-workspace-quick_links"
    *ngIf="displayQuickLinks(workspaceStatus)"
  >
    <div class="mpr-aws-quick-links">QUICK LINKS</div>
    <div class="mpr-aws-workspace-quick-links" id="aws-quicklinks-container">
      <ng-container *ngIf="
        workspaceStatus !== workspaceStatusEnum.NOT_PROVISIONED &&
        workspaceStatus !== workspaceStatusEnum.PENDING &&
        workspaceStatus !== workspaceStatusEnum.FAILED
      ">
        <a
          class="mpr-aws-workspace-userguide"
          href="{{ awsWorkspaceUserGuideHelpURL }}"
          target="_blank"
          >AMAZON WORKSPACE USERGUIDE
          <img
            class="mpr-link-new-window-icon"
            src="assets/images/new-window.svg "
            alt="Data Processing User Guide"
          />
        </a>
        <div class="mpr-vertical-line"></div>
        <a
          class="mpr-aws-workspace-userguide mpr-cursor-pointer"
          (click)="setAWSWorkspacePassword(true)"
        >
          FORGOT PASSWORD?
        </a>
        <div class="mpr-vertical-line" *ngIf="showConnectingToRDSLink"></div>
      </ng-container>
      <ng-container *ngIf="showConnectingToRDSLink">
        <a
          class="mpr-aws-workspace-userguide mpr-cursor-pointer"
          (click)="navigateConnectingToRDS()"
        >
        connecting to rds
        </a>
      </ng-container>
    </div>
  </div>
  <div
    class="mpr-aws-workpace-info"
    id="aws_workspace_info_container"
    *ngIf="
      workspaceStatus !== workspaceStatusEnum.NOT_PROVISIONED &&
      workspaceStatus !== workspaceStatusEnum.PENDING &&
      workspaceStatus !== workspaceStatusEnum.FAILED
    "
  >
    <div class="mpr-aws-workspace-info-title">WORKSPACE INFO</div>
    <div class="mpr-aws-workpace-wrapper">
      <div class="d-flex">
        <div class="mpr-workspace-value">
          <div class="mpr-workspace-details">
            USERNAME
            <span class="mpr-private-space-copy">
              <button
                class="mpr-icon-button mpr-copy-to-clipboard-icon"
                (click)="copyToClipBoard(workspaceInfo?.userName || 'NA')"
                matTooltip="Copy Username"
                id=""
                aria-label="User Name"
              ></button>
            </span>
          </div>
          <div class="mpr-workspace-details-value">
            {{ workspaceInfo?.userName || 'NA' }}
          </div>
        </div>
        <div class="mpr-workspace-value">
          <div class="mpr-workspace-details">
            REGISTRATION CODE
            <span class="mpr-private-space-copy">
              <button
                class="mpr-icon-button mpr-copy-to-clipboard-icon"
                (click)="
                  copyToClipBoard(workspaceInfo?.registrationCode || 'NA')
                "
                matTooltip="Copy Registration Code"
                aria-label="Registration Code"
              ></button>
            </span>
          </div>
          <div class="mpr-workspace-details-value">
            {{ workspaceInfo?.registrationCode || 'NA' }}
          </div>
        </div>
        <div class="mpr-workspace-value">
          <div class="mpr-workspace-details">OPERATING SYSTEM</div>
          <div class="mpr-workspace-details-value">
            {{ workspaceInfo?.operatingSystem || 'NA' }}
          </div>
        </div>
        <div class="mpr-workspace-value">
          <div class="mpr-workspace-details">vCPU</div>
          <div class="mpr-workspace-details-value">
            {{ workspaceInfo?.vCPU || 'NA' }}
          </div>
        </div>
        <div class="mpr-workspace-value">
          <div class="mpr-workspace-details">RAM</div>
          <div class="mpr-workspace-details-value">
            {{ workspaceInfo?.ram || 'NA' }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="use-workspace-box"
    id="workspaceDetails_container"
    *ngIf="
      workspaceStatus === workspaceStatusEnum.NOT_PROVISIONED ||
      workspaceStatus === workspaceStatusEnum.PENDING ||
      workspaceStatus === workspaceStatusEnum.FAILED
    "
  >
    <div class="use-workspace-image">
      <img src="./assets/images/workspace-man-with-computer.png" alt="" />
    </div>
    <div class="use-workspace-content">
      <div
        class="use-workspace-heading"
        *ngIf="
          workspaceStatus === workspaceStatusEnum.NOT_PROVISIONED ||
          workspaceStatus === workspaceStatusEnum.FAILED
        "
      >
        USE WORKSPACE FOR:
      </div>
      <div
        class="use-workspace-heading"
        *ngIf="workspaceStatus === workspaceStatusEnum.PENDING"
      >
        HOW TO CONNECT TO WORKSPACE?
      </div>
      <div
        class="use-workspace-list"
        *ngIf="
          workspaceStatus === workspaceStatusEnum.NOT_PROVISIONED ||
          workspaceStatus === workspaceStatusEnum.FAILED
        "
      >
        <ul>
          <li>Explore data – view samples and understand data quality.</li>
          <li>
            Collaborate with developers – comment on specs and revisions, review
            analytic output.
          </li>
          <li>Access tools – Excel, WinMerge, Notepad++, STATA, VSCode.</li>
        </ul>
      </div>
      <div
        class="use-workspace-list"
        *ngIf="workspaceStatus === workspaceStatusEnum.PENDING"
      >
        <ul>
          <li>
            Once your Workspace is setup, you will receive an email from
            <b>“{{ 'displayAppSupportEmail' | appDetails }}”</b> with subject
            <b>“Your Amazon WorkSpace is ready for use.”</b> Follow the
            instructions to get started.
          </li>
          <li>
            As part of your workspace setup, you may receive an email from
            Amazon with the subject
            <b>‘Your Amazon WorkDocs account has been activated’</b>. Please
            disregard this email.
          </li>
          <li>
            For more details, visit
            <a
              class="mpr-workspace-userguide"
              href="{{ awsWorkspaceUserGuideHelpURL }}"
              target="_blank"
              >AMAZON WORKSPACE USERGUIDE.
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
