import { ServerUrls, UserAWSStatus } from '@theme/interfaces';
import { UserProjectRoleEnum } from '@core/enums';
import { UserList } from '../project-user/user-list-model';

export default interface UserStateModel {
  allUserData?: Array<UserList> | null;
  createdBy: string;
  dwFeatureFlag: string;
  ec2Provisioned: boolean;
  ec2Status: string;
  emailId: string;
  instanceId: string;
  isHeavyLiftingFlagAvailable?: string;
  isPowerUser: boolean;
  isStataFeatureFlagAvailable?: string;
  isVscodeFeatureFlagAvailable?: string;
  machineSize: string;
  memory: string;
  modifiedBy: string;
  projectId: string;
  projectName: string;
  projectNickname: string;
  projectNumber: string;
  projectRestrictedDataUseGroups: string[];
  projectRestrictedS3Space: string[];
  projectStagingS3Space: string;
  projectStandardS3Space: string;
  publicLimitedS3Space: string;
  publicUseS3Space: string;
  rVersion: string;
  roleName: UserProjectRoleEnum;
  serverUrls: ServerUrls;
  userCount?: number;
  userId: string;
  userInfo?: Array<UserList>;
  userProjectId: string;
  userStatus: string;
  vCPU: string;
  workspaceStatus: string;
}
