import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MprConfirmInputModal } from '@shared';

@Component({
  selector: 'mpr-confirm',
  templateUrl: './mpr-confirm.component.html',
  styleUrls: ['./mpr-confirm.component.scss'],
})
export class MprConfirmComponent {
  @Output() public confirmAction = new EventEmitter<any>();
  public confirmationMessage = false;
  public disableConfirmShowLoader = false;

  constructor(
    private dialogRef: MatDialogRef<MprConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MprConfirmInputModal
  ) {}

  public confirm(): void {
    if (this.data.useEmitterOnConfirmClick === undefined) {
      this.dialogRef.close(this.data.confirmData);
    } else {
      this.disableConfirmShowLoader = true;
      this.confirmAction.emit(this.data);
    }
  }
}
