export interface ApiInProgressStateModel {
  GetConnection: boolean;
  GetDw: boolean;
  GetJob: boolean;
  GetProjectSurvey: boolean;
  GetProjectsMetadata: boolean;
  GetRds:boolean;
  GetSchedule: boolean;
  GetScheduleJob: boolean;
  GetUser: boolean;
  GetUserExternalAccounts: boolean;
  [key: string]: boolean;
}
