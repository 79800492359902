import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { AlertMessageModel } from '@core/interfaces';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AlertMessageComponent implements OnInit {
  // Inject Data to the component like a dialog
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: AlertMessageModel,
    private snackBarRef: MatSnackBarRef<AlertMessageComponent>
  ) {}

  public dismissAlert(): void {
    this.snackBarRef.dismiss();
  }

  ngOnInit(): void {}
}
