import { CommonResponseModel } from '@shared';
import { RDSClusters } from '../manage-rds/manage-rds.model';

export interface RDSClusterUser {
  dbUserName: string;
  userId: string;
}

export interface RDSClusterUserKeyValue {
  [key: string]: RDSClusterUser;
}

export interface RDSClusterUsersResponse {
  addedUsers: RDSClusterUser[] | [];
  rdsResourceId: string;
  status_code: number;
}

export interface RDSClusterUsersStateModel {
  cluster: RDSClusters | null;
  commonResponse: CommonResponseModel;
  users: RDSClusterUserKeyValue;
}
