import { ConnectionDataStore, ConnectionType } from '@core/enums';

export interface ConnectionRequestPayload {
  accountId?: string;
  bucketName?: string;
  connectionName: string;
  connectionType: ConnectionType;
  dataStore: ConnectionDataStore;
  emailId?: string;
  prefixName?: string;
  projectId?: string;
}
