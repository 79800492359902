import { NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CreateScheduleComponent } from './create-schedule/create-schedule.component';
import { EnterMetadataComponent } from './enter-metadata/enter-metadata.component';
import { MatPaginatorIntlCro } from './customPaginator';
import { OneTimeUploadsComponent } from './one-time-uploads/one-time-uploads.component';
import { SelectFilesComponent } from './select-files/select-files.component';
import { UploadHistoryComponent } from './upload-history/upload-history.component';
import { SharedModule } from '@shared';
import { UploadOptionComponent } from './upload-option/upload-option.component';
import { UploadsRoutingModule } from './uploads-routing.module';
import { JobDetailsComponent } from './job-details/job-details.component';
import { CloudTransferComponent } from './cloud-transfer/cloud-transfer.component';
import { OnPremiseUploadComponent } from './on-premise-upload/on-premise-upload.component';

@NgModule({
  declarations: [
    CloudTransferComponent,
    CreateScheduleComponent,
    JobDetailsComponent,
    OnPremiseUploadComponent,
    OneTimeUploadsComponent,
    SelectFilesComponent,
    EnterMetadataComponent,
    UploadOptionComponent,
    UploadHistoryComponent,
  ],
  imports: [SharedModule, UploadsRoutingModule],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlCro,
    },
  ],
})
export class UploadsModule {}
