import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { RDS_CLUSTER_DISPLAY_STATUS, UserProjectRoleEnum } from '@core';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { AlertMessageService } from '@core/services';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import {
  CommonResponseModel,
  MprConfirmComponent,
  SkipMainContentService,
} from '@shared';
import {
  AddUserToRDSCluster,
  ApiInProgressState,
  DeleteUserFromRDSCluster,
  GetProjectUserList,
  LoadRDSClusters,
  LoadRDSClusterUsers,
  LoadSelectedProjectMetadata,
  ManageRDSClustersModel,
  ManageRDSClusterState,
  ProjectState,
  ProjectUserList,
  ProjectUserState,
  RDSClusterUser,
  RDSClusterUserKeyValue,
  RDSClusterUsersState,
  ResetManageRDSClusters,
  ResetRDSClusterCommonResponse,
  ResetRDSClusterUsersState,
  SetSelectedCluster,
  UserProjectState,
} from 'app/state';
import {
  catchError,
  Observable,
  Subscription,
  throwError,
  timer,
  withLatestFrom,
} from 'rxjs';
import { getRDSClusterDisplayStatus } from '../common/rds-utils';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-manage-cluster-users',
  templateUrl: './manage-cluster-users.component.html',
  styleUrls: ['./manage-cluster-users.component.scss'],
})
export class ManageClusterUsersComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Select(ApiInProgressState.getApiInProgressStateAction('GetRds'))
  public clusterUserListingInProgress$?: Observable<boolean>;
  @Select(RDSClusterUsersState.getListAddDeleteUserResponse)
  public listAddDeleteUserResponse$!: Observable<CommonResponseModel>;
  @Select(ProjectUserState.getProjectUserList)
  public projectUsersList$?: Observable<ProjectUserList[]>;
  @Select(ManageRDSClusterState.GetRDSClusterState)
  public rdsClusterState$!: Observable<ManageRDSClustersModel>;
  @ViewChild('skipper') public skipper!: ElementRef;
  @Select(ApiInProgressState.getApiInProgressStateAction('GetRdsUsers'))
  public userListInProgress$?: Observable<boolean>;
  @Select(RDSClusterUsersState.getRDSClusterUsers)
  public usersAddedToCluster$?: Observable<RDSClusterUserKeyValue>;

  public clusterId = '';
  public clusterName = '';
  public clusterStatus = '';
  public dataSource!: MatTableDataSource<ProjectUserList>;
  public displayedColumns = ['name', 'role', 'dbUserName', 'action'];
  public isPlatformAdmin = false;
  public listType = 'Users';
  public pageLoadError = false;
  public projectId = '';
  public projectUserSubscription: Subscription | undefined;
  public requestHeaders: MprHttpHeaderModal = {};

  constructor(
    private route: ActivatedRoute,
    public store: Store,
    private alertMsgService: AlertMessageService,
    private skipMainContentService: SkipMainContentService,
    private router: Router,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.projectId = this.route.snapshot.params['projectId']
      ? this.route.snapshot.params['projectId']
      : '';
    this.clusterId = this.route.snapshot.params['clusterId']
      ? this.route.snapshot.params['clusterId']
      : '';
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as { message: any };
    if (state?.message) {
      timer(1000).subscribe(() => {
        this.alertMsgService.success({
          body: state.message.message,
        });
      });
    }
  }

  public destroyStates(): void {
    this.store.dispatch(new ResetManageRDSClusters());
    this.store.dispatch(new ResetRDSClusterUsersState());
    this.store.dispatch(new ResetRDSClusterCommonResponse());
  }

  public getUserIfAlreadyAddedToCluster(
    userId: string,
    addedUsers: RDSClusterUserKeyValue | null
  ): RDSClusterUser | false {
    if (
      addedUsers &&
      Object.keys(addedUsers).length > 0 &&
      Object.keys(addedUsers).includes(userId)
    ) {
      return addedUsers[userId];
    }
    return false;
  }

  public handleDisabledRDSFlag(flagValue: boolean): void {
    let routeData: any = {};
    this.route.data.subscribe((data) => {
      routeData = data;
    });

    if (!flagValue) this.router.navigate([routeData.back]);
  }

  public isClusterRunning(): boolean {
    return this.clusterStatus === RDS_CLUSTER_DISPLAY_STATUS.RUNNING;
  }

  ngAfterViewInit(): void {
    this.skipMainContentService.skip$.subscribe((res) => {
      const focusElement = this.document.querySelector('.mpr-skipper');
      if (focusElement) {
        (focusElement as HTMLElement).focus();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyStates();
    this.projectUserSubscription?.unsubscribe();
    this.pageLoadError = false;
  }

  ngOnInit(): void {
    if (this.projectId) {
      this.requestHeaders[HeaderParams.PROJECTID] = this.projectId;
      this.requestHeaders[HeaderParams.ROLENAME] =
        UserProjectRoleEnum.PLATFORM_ADMIN;
      this.isPlatformAdmin = true;
    } else {
      this.projectId = this.store.selectSnapshot(
        UserProjectState.getSelectedProject
      ).projectId;
      this.requestHeaders[HeaderParams.PROJECTID] = this.projectId;
      this.requestHeaders[HeaderParams.ROLENAME] = UserProjectRoleEnum.ADMIN;
    }

    // Check if this project has rds enabled.
    if (
      this.store.selectSnapshot(ProjectState.getSelectedProjectData).projectId
    ) {
      const rdsEnabled =
        this.store.selectSnapshot(ProjectState.getSelectedProjectData)
          .rdsEnabled || false;
      this.handleDisabledRDSFlag(rdsEnabled);
    } else {
      this.store
        .dispatch(new LoadSelectedProjectMetadata(this.requestHeaders))
        .subscribe(() => {
          const rdsEnabled =
            this.store.selectSnapshot(ProjectState.getSelectedProjectData)
              .rdsEnabled || false;
          this.handleDisabledRDSFlag(rdsEnabled);
        });
    }

    this.store
      .dispatch(new LoadRDSClusterUsers(this.clusterId, this.requestHeaders))
      .pipe(withLatestFrom(this.listAddDeleteUserResponse$))
      .subscribe(([_, res]) => {
        if (res.status_code > 202) {
          this.alertMsgService.error({
            body: res.message
              ? res.message
              : `An error occured while accessing this page.`,
          });
          this.pageLoadError = true;
          this.destroyStates();
        }
      });

    this.store.dispatch(new GetProjectUserList('False', this.requestHeaders));

    const currentCluster = this.store.selectSnapshot(
      RDSClusterUsersState.getRDSCluster
    );

    if (currentCluster) {
      this.clusterName = currentCluster.clusterName || '';
      this.clusterStatus = getRDSClusterDisplayStatus(currentCluster.status);
    }

    // If there's no cluster name in state that means user refreshed now we need the cluster name from the API that lists the clusters
    if (this.clusterName === '') {
      this.store.dispatch(new LoadRDSClusters(this.requestHeaders));
      this.rdsClusterState$.subscribe((rdsClusterState) => {
        const matchingClusters = rdsClusterState.rdsClusters.filter(
          (cluster) => this.clusterId === cluster.rdsResourceId
        );
        if (matchingClusters.length > 0) {
          this.clusterName = matchingClusters[0].clusterName;
          this.clusterStatus = getRDSClusterDisplayStatus(
            matchingClusters[0].status
          );
          this.store.dispatch(new SetSelectedCluster(matchingClusters[0]));
        }
      });
    }

    this.projectUserSubscription = this.projectUsersList$
      ?.pipe(
        catchError((err) => {
          this.alertMsgService.error({
            body: err.error.message,
          });
          return throwError(() => new Error(''));
        })
      )
      .subscribe((users) => {
        // Show only active users
        const activeUsers =
          users.length > 0
            ? users.filter((user) => user.userStatus === 'active')
            : [];
        this.dataSource = new MatTableDataSource(activeUsers);
      });
  }

  public showAddRemoveClusterUserPopup(
    user: ProjectUserList,
    action: any
  ): void {
    //if (!this.isClusterRunning()) return;
    const actionTitle = action.charAt(0).toUpperCase() + action.slice(1);
    const confirmTitle = `${actionTitle} User`;
    const stateAction =
      action === 'add' ? AddUserToRDSCluster : DeleteUserFromRDSCluster;
    const userMessageConfirm = `<b>${user.firstName} ${user.lastName}</b> ${
      action === 'add' ? 'to' : 'from'
    } the RDS cluster <b>${this.clusterName}</b>`;
    const userMessageSuccess = `User&nbsp;<b>${user.firstName} ${
      user.lastName
    }</b>&nbsp;${action === 'add' ? 'added to' : 'removed from'} the RDS cluster&nbsp;<b>${this.clusterName}&nbsp;</b>`;
    const confirmMessage = `Are you sure you want to ${action} the user ${userMessageConfirm}?`;

    const dialogRef = this.dialog.open(MprConfirmComponent, {
      width: '650px',
      data: {
        confirmTitle,
        confirmMessage,
        useEmitterOnConfirmClick: true,
      },
      disableClose: true,
    });
    dialogRef.componentInstance.confirmAction.subscribe((data) => {
      this.store
        .dispatch(
          new stateAction(
            this.projectId,
            this.clusterId,
            user.userId,
            this.requestHeaders
          )
        )
        .pipe(
          withLatestFrom(this.listAddDeleteUserResponse$),
          catchError((err) => {
            this.alertMsgService.error({
              body: err.error.message,
            });
            this.store.dispatch(new ResetRDSClusterCommonResponse());
            return throwError(() => new Error(''));
          })
        )
        .subscribe(([_, res]) => {
          if (res.status_code > 202) {
            this.alertMsgService.error({
              body: res.message
                ? res.message
                : `Adding user ${this.clusterName} was unsuccessful. Please try again.`,
            });
            dialogRef.close();
          } else {
            this.alertMsgService.success({
              body: userMessageSuccess,
            });
            dialogRef.close();
          }

          this.store.dispatch(new ResetRDSClusterCommonResponse());

          this.store.dispatch(
            new LoadRDSClusterUsers(this.clusterId, this.requestHeaders)
          );
        });
    });
  }
}
