import { CommonResponseModel } from '@shared';

export interface RDSClusters {
    clusterName : string;
    dateClusterSetup: string;
    dbEngine: string;
    dbInstanceCount: string;
    dbUserName: string;
    displayStatus: string;
    endpoint: string;
    password: string;
    port: string;
    projectId: string;
    rdsResourceId: string;
    status : string;
    username: string;
}

export interface RDSResponse {
    data : RDSClusters[] | [];
    dbEngine: string;
    defaultCapacity: string;
    message : string;
    status_code : number;
}

export interface ManageRDSClustersModel {
    commonResponse : CommonResponseModel;
    dbEngine: string;
    defaultCapacity: string;
    loader : boolean;
    rdsClusters : RDSClusters[] | [];
}