<div
  fxLayout="row"
  fxLayoutAlign="{{ data.alignment }} center"
  class="m-l-12 m-r-8"
>
  <div>
    <div
      fxLayout="row"
      class="m-b-12 mpr-snackbar-title f-w-600"
      *ngIf="data.title"
      [innerHTML]="data.title"
    ></div>
    <div
      fxLayout="row"
      class="mpr-snackbar-content"
      [class.mpr-snackbar-no-color]="data.title"
      [innerHTML]="data.body"
    ></div>
  </div>
  <div
    class="m-l-12"
    fxLayout="column"
    fxLayoutAlign="flex-start center"
    *ngIf="!data.autoDismiss"
  >
    <mat-icon (click)="dismissAlert()" class="cursor-pointer">cancel</mat-icon>
  </div>
</div>
