import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { WorkspacesInputsModel } from '@shared/interfaces';
import {
  FeatureFlagsState,
  IsLoggedInUserInternal,
  LoggedinUserState,
} from 'app/state';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss'],
})
export class WorkspaceComponent implements OnInit {
  public inputs!: WorkspacesInputsModel;
  public isDeveloperWorkspaceEnabled = false;
  public selectedTabIndex = 0;

  constructor(public store: Store) {}

  ngOnInit(): void {
    const selectedTab = 0;
    this.store.dispatch(new IsLoggedInUserInternal());
    const featureFlags = this.store.selectSnapshot(
      FeatureFlagsState.returnFeatureFlags
    );
    this.isDeveloperWorkspaceEnabled =
      featureFlags.isDevelopersAwsWorkspacesAvailable;

    const isUserInternal = this.store.selectSnapshot(
      LoggedinUserState.getIsUserInternal
    );
    const commonSupportLink = isUserInternal
      ? featureFlags.internalContactUsUrl
      : featureFlags.externalContactUsUrl;

    this.inputs = {
      commonSupportLink,
      isUserInternal,
      selectedTab,
    };
  }

  public selectedTabChange(tabChange: MatTabChangeEvent): void {
    this.selectedTabIndex = tabChange.index;
  }
}
