import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ManageRDSClustersModel, RDSClusters, RDSResponse } from './manage-rds.model';
import { ManageRDSService } from '@shared/services/manage-rds.service';
import { Observable, tap } from 'rxjs';
import { LoadRDSClusters, ResetManageRDSClusterCommonResponse, ResetManageRDSClusters, SetupCluster, StartOrStopCluster, DeleteRDSCluster } from './manage-rds.action';
import { CommonResponseModel } from '@shared';

const stateDefaults: ManageRDSClustersModel = {
    rdsClusters: [],
    loader: false,
    commonResponse : {message : '', status_code: 0 },
    dbEngine: '',
    defaultCapacity: ''
  };

@State<ManageRDSClustersModel>({
    defaults: stateDefaults,
    name: 'ManageRDSClusterState',
  })
  @Injectable()
  export class ManageRDSClusterState {
    constructor(private manageRDSService: ManageRDSService) {}

    @Selector()
    public static GetManageRDSLoader(
        state: ManageRDSClustersModel,
    ): boolean {
        return state.loader;
    }

    @Selector()
    public static GetRDSClusterState(
        state: ManageRDSClustersModel,
    ): ManageRDSClustersModel {
        return state;
    }
    
    @Selector()
    public static GetRDSClusters(
        state: ManageRDSClustersModel,
    ): RDSClusters[] | [] {
        return state.rdsClusters;
    }

     // Selector to get existing cluster names
   @Selector()
   public static getExistingClusterNames(state: ManageRDSClustersModel): string[] {
       return state.rdsClusters.map(cluster => cluster.clusterName.toLowerCase());
   }

   @Action(DeleteRDSCluster)
 public deleteRDSCluster(
   { patchState, getState }: StateContext<ManageRDSClustersModel>,
   { projectId, rdsResourceId, requestHeaders }: DeleteRDSCluster
 ): Observable<CommonResponseModel> {
   return this.manageRDSService.deleteRDSCluster(projectId, rdsResourceId, requestHeaders).pipe(
     tap((results) => {
       patchState({
         commonResponse: results
       });
     })
   );
 }

    @Action(LoadRDSClusters)
    public loadRDSClusters(
    { patchState }: StateContext<ManageRDSClustersModel>,
    { requestHeaders }: LoadRDSClusters,
    ): Observable<RDSResponse> {
    let loader = true;
    patchState({ loader });
    return this.manageRDSService.getRDSClusters(requestHeaders).pipe(
        tap((results) => {
            loader = false;
            let rdsClusters: RDSClusters[] | [] = [];
            let commonResponse: CommonResponseModel = stateDefaults.commonResponse;
            if(results.data){
                rdsClusters = results.data;
            } else {
                commonResponse = results
            }
            const dbEngine = results.dbEngine || stateDefaults.dbEngine;
            const defaultCapacity = results.defaultCapacity || stateDefaults.defaultCapacity;
            patchState({ rdsClusters, loader, commonResponse, defaultCapacity, dbEngine});
        }),
    );
    }

    @Action(ResetManageRDSClusterCommonResponse)
    public resetManageRDSClusterUserCommonResponse({
      patchState,
    }: StateContext<ManageRDSClustersModel>): void {
      const commonResponse = stateDefaults.commonResponse;
      patchState({ commonResponse });
    }

    @Action(ResetManageRDSClusters)
    public resetManageRDSClusters({
        patchState,
    }: StateContext<ManageRDSClustersModel>): void {
        patchState({ ...stateDefaults });
    }
    
    @Action(SetupCluster)
    public setupCluster(
      { patchState }: StateContext<ManageRDSClustersModel>,
      { projectId, clusterName, requestHeaders }: SetupCluster,
    ): Observable<CommonResponseModel> {
      let loader = true;
      patchState({ loader });
      return this.manageRDSService.setupRDSCluster(projectId, clusterName, requestHeaders).pipe(
        tap((results) => {
          loader = false;
          const commonResponse: CommonResponseModel = results;
          patchState({ loader, commonResponse });
        }),
      );
    }

    @Action(StartOrStopCluster)
    public startOrStopCluster(
      { patchState }: StateContext<ManageRDSClustersModel>,
      { projectId, rdsResourceId, action, requestHeaders }: StartOrStopCluster,
    ): Observable<CommonResponseModel> {
      let loader = true;
      patchState({ loader });
      return this.manageRDSService.startOrStopCluster(projectId, rdsResourceId, action, requestHeaders).pipe(
        tap((results) => {
          loader = false;
          const commonResponse: CommonResponseModel = results;
          patchState({ loader, commonResponse });
        }),
      );
    }
  }