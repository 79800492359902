import {
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { User } from '@theme/interfaces';
import {
  UserState,
  LoadUserdata,
  SnowflakeDetailsState,
  SfJWTTokenState,
  ApiInProgressState
} from 'app/state';
import {
  Observable,
  withLatestFrom,
  catchError,
  throwError,
} from 'rxjs';
import { LaunchEc2Enum } from '@core/enums';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Clipboard } from '@angular/cdk/clipboard';
import { environment } from '@env/environment';
import { MatDialog } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { SkipMainContentService } from '@shared/services';

import {
  GetSnowflakeDetails,
  ResetSnowflakeDetails,
} from 'app/state/snowflake-details/snowflake-details.actions';
import {
  RefreshSfJWTToken,
  ResetSfJWTToken,
} from 'app/state/sf-jwt-token/sf-jwt-token.actions';

import { AlertMessageService } from '@core/services';
import { SnowflakeDetailsStateModel } from 'app/state/snowflake-details';
import { SfJWTTokenStateModel } from 'app/state/sf-jwt-token';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-connecting-to-snowflake',
  templateUrl: './connecting-to-snowflake.component.html',
  styleUrls: ['./connecting-to-snowflake.component.scss'],
})
export class ConnectingToSnowflakeComponent implements OnInit {
  @Select(ApiInProgressState.getApiInProgressStateAction('GetDw'))
  public fetchDWInProgress$?: Observable<boolean>;
  @ViewChild('skipper') public skipper!: MatButton;
  @Select(SfJWTTokenState.returnSfJWTToken)
  private jwtToken$!: Observable<SfJWTTokenStateModel>;
  @Select(SnowflakeDetailsState.returnSnowflakeDetails)
  private snowflakeDetails$!: Observable<SnowflakeDetailsStateModel>;
  @Select(UserState.getUsers)
  private user$!: Observable<User>;
  public JWTTokenFailureMsg = `Operation failed, please try again.`;
  public ec2Status = LaunchEc2Enum.STOPPED_STATUS;
  public isJwtRefreshing = false;
  public isWorkspaceRunning = true;
  public jwtTokenDetails: SfJWTTokenStateModel = {
    instanceId: '',
    status_code: 0,
  };
  public selectedUser!: User;
  public sfDetails: SnowflakeDetailsStateModel = {
    snowflakeAccount: '',
    snowflakeDatabase: '',
    snowflakeWarehouse: '',
    snowflakeRoleName: '',
    emailId: '',
    status_code: 0,
    dsn_value: 'snowflake',
  };
  public showConnectingToSFLink = false;
  public snowflakeDetailsFailureMsg = `Failed to get Snowflake Details. Please try again or contact your administrator.`;
  public snowflakeHelpURL = `${environment.helpPageNowUrl}data-warehouse/connecting-to-snowflake-from-workspace`;
  constructor(
    private clipboard: Clipboard,
    public store: Store,
    public dialog: MatDialog,
    private skipMainContentService: SkipMainContentService,
    private alertMsgService: AlertMessageService
  ){}

  public copyCodeToClipboard(codeSnippet: HTMLPreElement): void {
    const codeContent = codeSnippet.innerText.trim();
    this.clipboard.copy(codeContent);
  }
  
  public copySFOtherValues(): void {
    const concatenatedValues = `DSN = ${this.sfDetails.dsn_value}\nAccount = ${this.sfDetails.snowflakeAccount}\nWarehouse = ${this.sfDetails.snowflakeWarehouse}\nDatabase = ${this.sfDetails.snowflakeDatabase}\nRole = ${this.sfDetails.snowflakeRoleName}\nUID = ${this.sfDetails.emailId}`;
    this.clipboard.copy(concatenatedValues);
  }

  public handleSfJWTConsoleError(): void {
    this.store.dispatch(new ResetSfJWTToken());
    this.isJwtRefreshing = false;
    this.alertMsgService.error({
      body: this.JWTTokenFailureMsg,
    });
  }

  public handleSnowflakeConsoleError(): void {
    this.store.dispatch(new ResetSnowflakeDetails());
    this.alertMsgService.error({
      body: this.snowflakeDetailsFailureMsg,
    });
  }

  public jwtTokenRefresh(): void {
    if (this.isWorkspaceRunning) {
    this.alertMsgService.success({ body: 'Refreshing JWT token'});
    this.store
      .dispatch(new RefreshSfJWTToken(this.selectedUser.instanceId))
      .pipe(
        withLatestFrom(this.jwtToken$),
        catchError(() => {
          this.handleSfJWTConsoleError();
          return throwError(() => new Error(''));
        })
      )
      .subscribe(([_, jwtToken]) => {
        if (jwtToken.status_code !== 0 && jwtToken.status_code !== 200) {
          this.handleSfJWTConsoleError();
        } else {
          this.jwtTokenDetails = jwtToken;
          this.isJwtRefreshing = false;
          this.alertMsgService.success({ body: 'JWT token is refreshed'});
        }
      });
    } else {
      if(this.isJwtRefreshing)
      this.alertMsgService.error({ body: 'Your workspace needs to be in running state to refresh the JWT token.'})
      this.isJwtRefreshing = false;
    }
  }

  ngOnInit(): void {
    this.store
    .dispatch(new GetSnowflakeDetails())
    .pipe(
      withLatestFrom(this.snowflakeDetails$),
      catchError(() => {
        this.handleSnowflakeConsoleError();
        return throwError(() => new Error(''));
      })
    )
    .subscribe(([_, snowflakeDetails]) => {
      if (snowflakeDetails.status_code !== 0) {
        this.handleSnowflakeConsoleError();
      } else {
        this.sfDetails = snowflakeDetails;
      }
    });
    this.skipMainContentService.skip$.subscribe((res) => {
      this.skipper.focus();
    });
  }

  public triggerRefresh(): void {
    this.isJwtRefreshing = true;
    this.store
    .dispatch(new LoadUserdata())
    .pipe(
      withLatestFrom(this.user$),
      catchError(() => {
        this.handleSfJWTConsoleError();
        return throwError(() => new Error(''));
      })
    )
    .subscribe(([_, user]) => {
      this.selectedUser = user;
      this.ec2Status =
        Object.values(LaunchEc2Enum).find(
          (item) => item === user.ec2Status.toUpperCase()
        ) || LaunchEc2Enum.NOT_PROVISIONED;
        this.isWorkspaceRunning = this.ec2Status === LaunchEc2Enum.RUNNING_STATUS;
        this.jwtTokenRefresh();
    });
  }

}
