export interface GlobalAttributesModel {
    attributeId: string;
    attributeName: string;
    attributeOrder: string;
    category: string;
}

export interface GlobalAttributesStateModel {
    global_attributes: GlobalAttributesModel[];
    loader: boolean
}

export interface GlobalAttributesValueModel {
    attributeId : string;
    attributeValue : string
}