<ng-container *ngIf="listDetails | async as listData;">
  <div
    *ngIf="listData['userCount'] && listData['userCount'] > -1;"
  >
    <div class="mpr-list-container">
      <div class="mpr-list">List of Users ({{ listData['userCount'] }})</div>
      <span class="mpr-list-container">
        <span #skipper>
          <mpr-export-to-csv
            [spinner]="exportButtonSpinner"
            *ngIf="listData"
            (exportMethod)="exportToCSV()"
          ></mpr-export-to-csv>
        </span>
        <button
          class="mpr-action-button"
          (click)="navigateToRoute('platformAdmin/users/add')"
        >
          Add New User(s)
        </button>
      </span>
    </div>
    <div class="mpr-all-users-container m-t-16">
      <div class="p-b-16" *ngFor="let list of listData['userInfo']; index as i">
        <div class="d-flex justify-space-between">
          <div>
            <span class="mpr-username">{{ list.userName }}</span>
            <span class="mpr-email">({{ list.emailId }})</span>
          </div>
          <div class="d-flex align-items-center">
            <button
              class="d-flex align-items-center p-r-16 link-like-button"
              (click)="
                navigateToRoute('platformAdmin/users/manage', list.emailId)
              "
            >
              <img src="assets/images/Manage.svg" alt="" class="p-r-8" />
              <span class="icon-text">Manage</span>
            </button>
            <button
              class="d-flex align-items-center p-l-16 link-like-button"
              (click)="navigateAddToProject(list)"
            >
              <img
                src="assets/images/AddToProject.svg"
                alt=""
                class="p-r-8"
              />
              <span class="icon-text">Add To Project</span>
            </button>
          </div>
        </div>      
        <div
          *ngIf="listData['userCount'] && i < listData['userCount'] - 1"
          class="mpr-divider"
        ></div>
      </div>
    </div>
  
    <div *ngIf="listData['userInfo'] && listData['userCount'] > listData['userInfo'].length" class="mpr-load-more-wrapper">
      <mpr-load-more [show]="!showSpinner" (loadMoreMethod)="loadMoreUsers()"> </mpr-load-more>
    </div>
  </div>
  
  
</ng-container>
<div class="mpr-noresult-container" *ngIf="showSpinner">
  <div class="mpr-noresutl-error">
    <span>
      <mat-spinner diameter="45" color="accent"></mat-spinner>
    </span>
  </div>
</div>