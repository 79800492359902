import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserProjectRoleEnum } from '@core';
import { AlertMessageService } from '@core/services';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { CommonResponseModel, WorkspacesInputsModel } from '@shared/interfaces';
import {
  LaunchAWSWorkspaceState,
  ReprovisionAWSWorkspace,
  UserProjectState,
} from 'app/state';
import { Observable, catchError, throwError } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-workspace-update',
  templateUrl: './workspace-update.component.html',
  styleUrls: ['./workspace-update.component.scss'],
})
export class WorkspaceUpdateComponent implements OnInit {
  @Select(LaunchAWSWorkspaceState.getLaunchAws)
  private awsStatus$?: Observable<CommonResponseModel>;
  public availableWSTypes = [
    UserProjectRoleEnum.RESEARCHER,
    UserProjectRoleEnum.DEVELOPER,
  ];
  public disableAction = false;
  public updatedWorkspaceType: UserProjectRoleEnum =
    UserProjectRoleEnum.RESEARCHER;
  constructor(
    public store: Store,
    private dialogRef: MatDialogRef<WorkspaceUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Partial<WorkspacesInputsModel>,
    private alertMsgService: AlertMessageService
  ) {}

  ngOnInit(): void {
    const roleName = this.store.selectSnapshot(
      UserProjectState.getSelectedProjectRole
    );
    this.updatedWorkspaceType = roleName.toLowerCase().includes('admin')
      ? UserProjectRoleEnum.DEVELOPER
      : roleName;

    const isUserExternal = roleName.toLowerCase().includes('external');
    this.availableWSTypes = isUserExternal
      ? [
          UserProjectRoleEnum.EXTERNAL_RESEARCHER,
          UserProjectRoleEnum.EXTERNAL_DEVELOPER,
        ]
      : this.availableWSTypes;
  }
  public onUpdateSubmit(): void {
    // Handle User Preference here
    this.disableAction = true;
    this.store.dispatch(new ReprovisionAWSWorkspace(this.updatedWorkspaceType));
    this.awsStatus$
      ?.pipe(
        catchError((err) => {
          this.alertMsgService.error({
            body: err.error.message,
          });
          this.disableAction = false;
          return throwError(() => new Error(''));
        })
      )
      .subscribe((response) => {
        if (response.status_code === 200) {
          this.disableAction = false;
          // All is well
          this.dialogRef.close({ result: 'success' });
        } else if (response.status_code !== 0) {
          this.disableAction = false;
          this.dialogRef.close({ result: 'error' });
        }
      });
  }
}
