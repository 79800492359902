import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  AfterViewInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import {
  BoxPickerDialogModel,
  BoxPickerTypeEnum,
  BoxPickerOptionsModal,
  ConnectionModel,
} from '@shared';
import { ScriptLoaderService } from '@shared/services/script-loader.service';
import { ConnectionState } from 'app/state/connection';

const SCRIPT_PATH = '/assets/js/box-picker.js';
declare let Box: any; // Its Box Object that contains a lot and specifying a type may not be possible.

@Component({
  selector: 'mpr-box-content-picker',
  templateUrl: './box-content-picker.component.html',
  styleUrls: [
    './box-content-picker.component.scss',
    '../../../../assets/css/box-picker.css',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class BoxContentPickerComponent implements AfterViewInit, OnDestroy {
  private boxPicker: typeof Box;
  private connectionToken: string;
  private options: BoxPickerOptionsModal = {
    autoFocus: true,
    canUpload: false,
    canSetShareAccess: false,
    container: '.mpr-box-picker',
    logoUrl: '',
    size: 'large',
    sortBy: 'date',
    sortDirection: 'DESC',
  };
  private pickerType = BoxPickerTypeEnum.FILE;

  constructor(
    private store: Store,
    private scriptLoaderService: ScriptLoaderService,
    private dialogRef: MatDialogRef<BoxContentPickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BoxPickerDialogModel
  ) {
    const connection: ConnectionModel = this.store.selectSnapshot(
      ConnectionState.getSelectedConnection
    );
    this.connectionToken = connection.accessToken ?? '';
    this.options = { ...data.options, ...this.options };
    this.pickerType = data.pickerType;
  }

  ngAfterViewInit(): void {
    this.loadBoxPicker();
  }

  ngOnDestroy(): void {
    this.boxPicker.clearCache();
  }

  private handlePickedContent(): void {
    // Current assumption is this will be always via a dialog
    // but in case not in future add option to handle separately.
    this.boxPicker.addListener('choose', (items: any) => {
      this.dialogRef.close(items); //on close pass data to parent
    });
    this.boxPicker.addListener('cancel', () => {
      this.dialogRef.close();
    });
  }

  private loadBoxPicker(): void {
    // Load the script only if its not already loaded,
    // else you'll end up adding the script tag multiple times with unexpected results.
    try {
      // when undefined and we try accessing 'Box' it shows an exception
      if (Box) this.setupBoxPicker();
    } catch {
      const scriptElement = this.scriptLoaderService.loadJsScript(SCRIPT_PATH);
      scriptElement.onload = (): void => {
        this.setupBoxPicker();
      };
    }
  }

  private setupBoxPicker(): void {
    // Load the JavaScript client library.
    // (the init() method has been omitted for brevity)
    this.boxPicker =
      this.pickerType === 'file'
        ? new Box.FilePicker()
        : new Box.FolderPicker();
    this.showBoxPicker();
  }

  private showBoxPicker(): void {
    this.boxPicker.show('0', this.connectionToken, this.options);
    this.handlePickedContent();
  }
}
