import { RDS_CLUSTER_DISPLAY_STATUS } from '@core';

export const getRDSClusterDisplayStatus = (status: string): string => {
  const statusMapping: any = {
    unavailable: RDS_CLUSTER_DISPLAY_STATUS.UNAVAILABLE,
    available: RDS_CLUSTER_DISPLAY_STATUS.RUNNING,
    'backing-up': RDS_CLUSTER_DISPLAY_STATUS.TEMPORARILY_UNAVAILABLE,
    backtracking: RDS_CLUSTER_DISPLAY_STATUS.TEMPORARILY_UNAVAILABLE,
    maintenance: RDS_CLUSTER_DISPLAY_STATUS.TEMPORARILY_UNAVAILABLE,
    renaming: RDS_CLUSTER_DISPLAY_STATUS.TEMPORARILY_UNAVAILABLE,
    'resetting-master-credentials': RDS_CLUSTER_DISPLAY_STATUS.TEMPORARILY_UNAVAILABLE,
    starting: RDS_CLUSTER_DISPLAY_STATUS.STARTING,
    stopped: RDS_CLUSTER_DISPLAY_STATUS.STOPPED,
    stopping: RDS_CLUSTER_DISPLAY_STATUS.STOPPING,
    'storage-optimization': RDS_CLUSTER_DISPLAY_STATUS.TEMPORARILY_UNAVAILABLE,
    'update-iam-db-auth': RDS_CLUSTER_DISPLAY_STATUS.TEMPORARILY_UNAVAILABLE,
    upgrading: RDS_CLUSTER_DISPLAY_STATUS.TEMPORARILY_UNAVAILABLE,
    migrating: RDS_CLUSTER_DISPLAY_STATUS.TEMPORARILY_UNAVAILABLE,
    modifying: RDS_CLUSTER_DISPLAY_STATUS.TEMPORARILY_UNAVAILABLE,
    promoting: RDS_CLUSTER_DISPLAY_STATUS.TEMPORARILY_UNAVAILABLE,
    'preparing-data-migration': RDS_CLUSTER_DISPLAY_STATUS.TEMPORARILY_UNAVAILABLE,
    'migration-failed': RDS_CLUSTER_DISPLAY_STATUS.TEMPORARILY_UNAVAILABLE,
    'cloning-failed': RDS_CLUSTER_DISPLAY_STATUS.TEMPORARILY_UNAVAILABLE,
    creating: RDS_CLUSTER_DISPLAY_STATUS.INITIALIZING,
    deleting: RDS_CLUSTER_DISPLAY_STATUS.DELETING,
    'failing-over': RDS_CLUSTER_DISPLAY_STATUS.TEMPORARILY_UNAVAILABLE,
    'inaccessible-encryption-credentials':
      RDS_CLUSTER_DISPLAY_STATUS.TEMPORARILY_UNAVAILABLE,
    'inaccessible-encryption-credentials-recoverable':
      RDS_CLUSTER_DISPLAY_STATUS.TEMPORARILY_UNAVAILABLE,
  };
  return statusMapping[status] || status;
};
