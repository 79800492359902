import { Contents } from './contents-model';

// variables not in camel case convention as it's coming from API
export interface SubContentDetails {
  bucket_name?: string;
  contents: Contents[];
  folder_name?: string;
  message?: string;
  status_code?: number;
}
