import { Location } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '@env/environment';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { UserProjectState } from 'app/state/user-projects';
import { Observable } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-subheader-page-title-nav',
  templateUrl: './subheader-page-title-nav.component.html',
  styleUrls: ['./subheader-page-title-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SubheaderPageTitleNavComponent {
  @Select(UserProjectState.getSelectedProjectName)
  public projectName$?: Observable<string>;
  @Select(UserProjectState.getSelectedProjectRole)
  public projectRole$?: Observable<string>;

  public backTitle = '';
  public returnPath = '';
  public routeTitle = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
  ) {
    // FirstChild always has the data even in case of the / route
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.returnPath = route.snapshot.firstChild?.data['back'];
        this.backTitle = route.snapshot.firstChild?.data['backTitle'];
        this.routeTitle = route.snapshot.firstChild?.data['title'];
      }
    });
    this.returnPath = route.snapshot.firstChild?.data['back'];
    this.backTitle = route.snapshot.firstChild?.data['backTitle'];
    this.routeTitle = route.snapshot.firstChild?.data['title'];
    // If the referrer is ckan there's possibility that user might have clicked on terms and conditions
    // ANd now has come back to the page
    if (document.referrer.startsWith(environment.ckanUrl)) {
      sessionStorage.setItem('lastUrl', document.referrer);
    }
  }

  public navigateBack(): void {
    const stagingJobdetailsUrlPattern = '/staging/job-details/';
    if (this.router.url.includes(stagingJobdetailsUrlPattern)) {
      this.router.navigateByUrl('/staging');
    } else if (this.router.url ==='/staging') {
      this.router.navigateByUrl('/filemanager');
    } else {
      if (
        this.router.url.includes('/download') ||
        this.router.url.includes('/dataset') ||
      (this.router.url.includes('/staging') &&
        !this.router.url.includes('/staging/schedule')) ||
        this.router.url.includes('/platformAdmin/users/edit/') ||
        this.router.url.includes('/platformAdmin/projects/edit/') ||
        this.router.url.includes('/platformAdmin/projects/project-survey/') ||
        this.router.url.includes('/admin/users')
      ) {
        this.location.back();
      } else {
      if (this.returnPath.includes(':'))
          this.returnPath = this.populateDynamicParams(this.returnPath);
        this.router.navigate([this.returnPath]);
      }
    }
   }

  private populateDynamicParams(url: string): string {
    const arrayOfUrlParams = url.split('/');
    let finalUrl = '';
    arrayOfUrlParams.forEach((param, index) => {
      let path = '';
      if (param.includes(':')) {
        const searchChar = param.includes(':::')
          ? ':::'
          : param.includes('::')
            ? '::'
            : ':';
        const extractedParam = param.slice(
          param.indexOf(searchChar) + searchChar.length,
        );
        const replacedParam =
          this.route.snapshot.firstChild?.params[extractedParam];
        path +=
          searchChar.length === 3
            ? btoa(replacedParam)
            : searchChar.length === 2
              ? atob(replacedParam)
              : replacedParam;
      } else {
        path += param;
      }
      finalUrl += path;
      if (index < arrayOfUrlParams.length - 1) finalUrl += '/';
    });

    return finalUrl;
  }
}
