import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MprConfirmComponent } from '@shared';
import { MatDialog } from '@angular/material/dialog';
import {
  CreateAProject,
  FeatureFlagsState,
  GetGobalAttributes,
  GlobalAttributesModel,
  GlobalAttributesState,
  ManageProjectState,
  ResetProjectData,
  SetLoaderState,
} from 'app/state';
import ManageProjectModel, {
  MessageDetails,
  projectDetails,
} from 'app/state/manage-project/manage-project-state-model';
import { UserProjectRoleEnum } from '@core';
import {
  Observable,
  catchError,
  of,
  throwError,
  timer,
  withLatestFrom,
} from 'rxjs';
import { AlertMessageService } from '@core/services';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss'],
})
export class CreateProjectComponent implements OnInit, OnDestroy {
  @Select(GlobalAttributesState.getGloablAttributesLoader)
  public globalAttributeLoader$? : Observable<boolean>;
  @Select(GlobalAttributesState.returnGlobalAttributes)
  public globalAttributesObservable$? : Observable<GlobalAttributesModel[]>;
  @Select(ManageProjectState.getProjectCreationStatus)
  public projectCreation$!: Observable<MessageDetails>;
  @Select(ManageProjectState.getLoaderStatus)
  public showSpinner$?: Observable<boolean>;
  public globalAttributes: GlobalAttributesModel[] = [];
  public isAccountingFeatureEnabled = false;
  public returnPath = '';
  private requestHeaders?: MprHttpHeaderModal = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public store: Store,
    public dialog: MatDialog,
    private alertMsgService: AlertMessageService
  ) {
    this.returnPath = route.snapshot.data['back'];
  }

  public formattedMessageToShow(projectForm: projectDetails): string {
    const enabledFeatures: string[] = [];
    if (projectForm.dwAccess) {
        enabledFeatures.push('<strong>Data Warehouse</strong>');
    }
    if (projectForm.hasPowerUsers) {
        enabledFeatures.push('<strong>Heavy Lifting</strong>');
    }
    if (projectForm.externalProjectAccountsEnabled) {
        enabledFeatures.push('<strong>Powered by Mquiry</strong>');
    }
    if (projectForm.rdsEnabled) {
      enabledFeatures.push('<strong>Relational Database Service</strong>');
    }
    let featuresMessage = `You are about to add a new project “<b>${projectForm.projectNumber}_${projectForm.projectNickname}</b>”`;
    if (enabledFeatures.length > 0) {
        featuresMessage += ` with `;
        if (enabledFeatures.length === 1) {
            featuresMessage += `${enabledFeatures[0]} service enabled.`;
        } else if (enabledFeatures.length === 2) {
            featuresMessage += `${enabledFeatures[0]} and ${enabledFeatures[1]} services enabled.`;
        } else {
            // Join all but the last feature with a comma and a space
            featuresMessage += `${enabledFeatures.slice(0, -1).join(', ')}`;
            // Add 'and' before the last feature without a comma
            featuresMessage += ` and ${enabledFeatures[enabledFeatures.length - 1]} services enabled.`;
        }
    } else {
        featuresMessage += `.`;
    }
    featuresMessage += `<br/><br/>Are you sure you want to continue?`;
    return featuresMessage;
 }

  public navigateBack(message?: any, type = 'success'): void {
    const navigationExtras: NavigationExtras = {
      state: {
        type,
        message,
      },
    };

    this.returnPath = `/platformAdmin/projects/`;
    this.router.navigate([this.returnPath], navigationExtras);
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
    this.store.dispatch(new ResetProjectData());
  }

  ngOnInit(): void {
    this.isAccountingFeatureEnabled = this.store
        .selectSnapshot(FeatureFlagsState.returnFeatureFlags)
        .isAccountingFeatureFlagEnabled.toLowerCase() === 'true'
    this.requestHeaders = {};
    this.requestHeaders[HeaderParams.ROLENAME] =
      UserProjectRoleEnum.PLATFORM_ADMIN;
    this.store.dispatch(new GetGobalAttributes(this.requestHeaders));
    this.globalAttributesObservable$?.subscribe((attributes)=> {
      this.globalAttributes = attributes;
    })
  }

  public showCancelPopup(): void {
    this.dialog
      .open(MprConfirmComponent, {
        disableClose: true,
        data: {
          confirmTitle: 'Cancel Confirmation',
          confirmMessage: `You will lose any changes you made. Are you sure you want to cancel?`,
          cancelButtonText: 'Go Back',
          confirmButtonText: 'Proceed',
          confirmData: true,
        },
      })
      .afterClosed()
      .subscribe((proceed: boolean) => {
        if (proceed) {
          this.router.navigate([this.returnPath]);
        }
      });
  }

  public showConfirmPopup(projectForm: projectDetails): void {
    const popupContentMessage = this.formattedMessageToShow(projectForm);
    this.dialog
      .open(MprConfirmComponent, {
        disableClose: true,
        data: {
          confirmTitle: 'Create Project',
          confirmMessage: popupContentMessage,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Proceed',
          confirmData: true,
        },
      })
      .afterClosed()
      .subscribe((proceed: boolean) => {
        if (proceed) {
          this.store
            .dispatch(
              new CreateAProject(
                projectForm,
                UserProjectRoleEnum.PLATFORM_ADMIN
              )
            )
            .pipe(
              withLatestFrom(this.projectCreation$),
              catchError((err) => {
                this.store.dispatch(new SetLoaderState(false));
                this.navigateBack(err.error.message, 'error');
                return throwError(() => new Error(''));
              })
            )
            .subscribe(([_, res]) => {
              if (res && res.projectId) {
                timer(1000).subscribe(() => {
                  this.alertMsgService.success({
                    body: 'New project has been successfully created.',
                  });
                });
                this.router.navigate([this.returnPath]);
              }
            });
        }
      });
  }

  public showloader(loader: any): boolean {
    return this.isAccountingFeatureEnabled ? 
     loader.globalAttribute || loader.projectData : loader.projectData;
   }
}
