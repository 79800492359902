export interface EditScheduleDataModel {
  connectionId: string;
  createdBy: string;
  folderId: string;
  folderPath: string;
  frequency: string;
  frequencyDetail: string;
  hourOfTheDay: string;
  scheduleId: string;
  scheduleName: string;
  status: string;
}
