export interface UploadsMetadataModel {
  aggregationLevel: string[];
  dataAccessClassification: string;
  dataPurchased: boolean;
  dataSourceYearsEnd: string;
  dataSourceYearsStart: string;
  dataTags: string[];
  datasetName: string;
  dateCreated: string;
  focusArea: string[];
  geographicRegion: string;
  noDataSourceYearsApply: boolean;
  pii: boolean;
  restrictedDataUseGroups: [];
  shortDescription: string;
}
