<div ngModelGroup="group">
  <div class="mpr-form-fields">
    <div class="mpr-form-block-field mpr-width-50">
      <div class="mpr-form-block-field-label"
        >EMAIL ADDRESS*</div
      >
      <input
        id="emailId"
        type="text"
        class="mpr-emailFields"
        maxlength="50"
        [name]="group + 'emailId'"
        [(ngModel)]="data.emailId"
        placeholder="Enter valid email address"
        required
        pattern="{{ regexForEmail }}"
        #emailId="ngModel"
        appEmailValidator="{{ group }}"
        [allowedInternalDomains]="allowedInternalDomains"
        [allowedExternalDomains]="allowedExternalDomains"
        autocomplete="off"
        (change)="onEmailValueChange()"
        [disabled]="
          action === 'edit' ||
          action === 'addToProject' ||
          (!projectId && disableInputFields)
        "
        aria-label="Email"
      />
      <div
        *ngIf="
          (emailId?.dirty || emailId?.touched) &&
          !emailId.valid &&
          emailId.errors
        "
      >
        <div *ngIf="emailId.errors['required']" class="invalid-feedback">
          Email is required
        </div>
        <div *ngIf="emailId.errors['pattern']" class="invalid-feedback">
          Email is invalid
        </div>
        <div *ngIf="emailId.errors['duplicateEmail']" class="invalid-feedback">
          Duplicate entries found for email address. <br />
          Remove the duplicates and try again.
        </div>
        <div
          *ngIf="emailId.errors['unsupportedDomain']"
          class="invalid-feedback"
        >
          This domain name is not supported.
        </div>
      </div>
    </div>
    <div class="mpr-width-50">
      <div class="mpr-form-block-field-label"
        >ROLE FOR THE USER*</div
      >
      <mat-form-field
        class="mpr-mat-select-field mpr-select-field-width"
        floatLabel="never"
        appearance="outline"
        aria-label="select data source"
      >
        <mat-select
          id="roleName"
          [name]="group + 'roleName'"
          [(ngModel)]="data.roleName"
          required
          placeholder="Select Role"
          #roleName="ngModel"
          (selectionChange)="changeRole(roleName.value)"
          [disabled]="
            ((action === 'edit' || action === 'addToProject') &&
              data.emailId === loggedinUserEmail &&
              !isPlatformAdmin) ||
            (!projectId && disableInputFields)
          "
        >
          <mat-option *ngFor="let roleName of roleNames" [value]="roleName">{{
            roleName | replaceRoleName
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div
        *ngIf="
          (roleName?.dirty || roleName?.touched) &&
          !roleName.valid &&
          roleName.errors
        "
      >
        <div
          *ngIf="roleName.errors['required']"
          class="invalid-feedback-select"
        >
          Role Name is required
        </div>
      </div>
    </div>
  </div>
  <div class="mpr-form-fields">
    <div class="mpr-form-block-field mpr-width-50">
      <div class="mpr-form-block-field-label"
        >First Name*</div
      >
      <input
        id="firstName"
        class="mpr-inputFields"
        maxlength="50"
        matInput
        [name]="group + 'firstName'"
        [(ngModel)]="data.firstName"
        placeholder="Enter First Name"
        required
        #firstName="ngModel"
        autocomplete="off"
        [disabled]="
          action === 'edit' ||
          action === 'addToProject' ||
          (!projectId && disableInputFields)
        "
        aria-label="First Name"
      />
      <div class="error-message">
        <div class="mpr-float-left">
          <div
            *ngIf="
              (firstName?.dirty || firstName?.touched) &&
              !firstName.valid &&
              firstName.errors
            "
          >
            <div *ngIf="firstName.errors['required']" class="invalid-feedback">
              First Name is required
            </div>
          </div>
        </div>
        <div
          class="mpr-float-right mpr-padding-top"
          [ngClass]="data.firstName.length === 50 ? 'invalid-feedback' : ''"
        >
          {{ data.firstName.length }}/ 50 characters
        </div>
      </div>
    </div>
    <div class="mpr-form-block-field mpr-width-50">
      <div class="mpr-form-block-field-label"
        >Last Name*</div
      >
      <input
        id="lastName"
        class="mpr-inputFields"
        maxlength="50"
        matInput
        [name]="group + 'lastName'"
        [(ngModel)]="data.lastName"
        placeholder="Enter Last Name"
        required
        #lastName="ngModel"
        autocomplete="off"
        [disabled]="
          action === 'edit' ||
          action === 'addToProject' ||
          (!projectId && disableInputFields)
        "
        aria-label="Last Name"
      />
      <div class="error-message">
        <div class="mpr-float-left">
          <div
            *ngIf="
              (lastName?.dirty || lastName?.touched) &&
              !lastName.valid &&
              lastName.errors
            "
          >
            <div *ngIf="lastName.errors['required']" class="invalid-feedback">
              Last Name is required
            </div>
          </div>
        </div>
        <div
          class="mpr-float-right mpr-padding-top"
          [ngClass]="data.lastName?.length === 50 ? 'invalid-feedback' : ''"
        >
          {{ data.lastName?.length }}/ 50 characters
        </div>
      </div>
    </div>
  </div>
  <div class="mpr-form-fields" *ngIf="projectPoweruser">
    <mat-checkbox
      [name]="group + 'isPowerUser'"
      [(ngModel)]="data.isPowerUser"
      [checked]="data.isPowerUser && !isNonPowerUserRole()"
      [disabled]="
        isNonPowerUserRole() ||
        (action === 'edit' && !canChangePowerUserStatus) ||
        isExternalCollaborator
      "
      ><ng-container *ngIf="!isExternalCollaborator"
        ><b>POWER USER</b
        ><span class="mpr-power-user-note">
          (NOTE: User once promoted as power user, can't be demoted.)</span
        ></ng-container
      >
      <ng-container *ngIf="isExternalCollaborator"
        ><b>POWER USER</b
        ><span class="mpr-power-user-note mpr-remove-disable-color"
          >(NOTE:
          <ng-container *ngIf="data.roleName === ''"
            >External Collaborator</ng-container
          ><ng-container *ngIf="data.roleName !== ''">{{
            data.roleName
          }}</ng-container>
          cannot be promoted as power user.)</span
        ></ng-container
      >
    </mat-checkbox>
  </div>
  <div
    class="mpr-divider"
    *ngIf="restrictedGroups && restrictedGroups.length > 0"
  ></div>
  <div class="mpr-form-fields">
    <div
      class="mpr-form-block-field"
      *ngIf="restrictedGroups && restrictedGroups.length > 0"
    >
      <div class="mpr-form-block-field-label"
        >Restricted Data use groups</div
      >
      <section>
        <mat-checkbox
          *ngFor="let restrictedGroup of restrictedGroups"
          (change)="onCheckboxChange($event, restrictedGroup)"
          #checkboxes
          [checked]="
            (allSelected && data.roleName) ||
            (action === 'edit' &&
              (data.projectRestrictedDataUseGroups.includes(restrictedGroup) ||
                data.roleName === rolesEnum.ADMIN))
          "
          [disabled]="
            (allSelected && data.roleName) || data.roleName === rolesEnum.ADMIN
          "
        >
          {{ restrictedGroup }}
        </mat-checkbox>
      </section>
    </div>
  </div>
</div>
