import { OneTimeUploadHistoryRes } from './one-time-history-response-model';
import { RecurringUploadHistoryRes } from './recurring-history-response-model';

export interface UploadHistoryModel {
  jobOperationType: string;
  loadingUploadHistory: boolean;
  oneTimeUploadHistory: OneTimeUploadHistoryRes;
  recurringUploadHistory: RecurringUploadHistoryRes;
  searchTerm: string;
  sortKey: string;
  sortOrder: string;
}
