<div class="mpr-manage-rds" *ngIf="rdsClusterState$ | async as rdsClusterState">
  <ng-container
    *ngIf="
      (fetchRDSInProgress$ | async) === false &&
        (fetchProjectsMetadataInProgress$ | async) === false;
      else loader
    "
  >
  <ng-container *ngIf = "showRDS">
    <ng-container *ngIf="!isPlatformAdmin">
      <div class="field-container">
        <div class="field-label mpr-project-name">
          LIST OF RDS CLUSTERS
          <button
            mat-button
            #skipper
            class="mpr-button mpr-list-rds-refresh-button"
            *ngIf="rdsClusterState.rdsClusters.length !== 0 && viewOnlyForProjectAdmin()"
            (click)="doRefresh()"
          >
            <span class="mpr-refresh-img">
              <img src="assets/images/New_Refresh.svg" alt="" />
            </span>
            <span class="mpr-refresh"> Refresh </span>
          </button>
        </div>
        
      </div>
    </ng-container>
    <ng-container *ngIf="isPlatformAdmin">
      <div class="field-container">
        <div class="field-label mpr-project-name">
          PROJECT : {{ projectId }}
        </div>
      </div>
      <div class="mpr-manage-rds-top-box">
        <div class="mpr-manage-rds-top-box-content">
          Click <strong>SET UP RDS CLUSTER</strong> to create a new RDS cluster
          for this project.
        </div>
        <div fxLayout="row">
          <button
            id="btnSetupUserConnection"
            mat-button
            class="mpr-button mpr-yellow-button mat-button mat-focus-indicator"
            #skipper
            (click)="setupRDS()"
            [disabled]="disableAllButtons"
          >
            SET UP RDS CLUSTER
          </button>
          <button
            mat-button
            class="mpr-button mpr-aws-workspace-second-btn"
            *ngIf="
              rdsClusterState.rdsClusters.length !== 0 || showInprogressSetup
            "
            (click)="doRefresh()"
            [disabled]="disableAllButtons"
          >
            <span class="mpr-refresh-img">
              <img src="assets/images/New_Refresh.svg" alt="" />
            </span>
            <span class="mpr-refresh"> Refresh </span>
          </button>
        </div>
      </div>
    </ng-container>
    <div
      class="mpr-manage-rds-filler"
      id="setupUserConnection"
      *ngIf="rdsClusterState.rdsClusters.length === 0 && isPlatformAdmin"
    >
      <div class="use-connect-aws-account-image">
        <img src="./assets/images/workspace-man-with-computer.png" alt="" />
      </div>
      <div class="mpr-manage-rds-filler-content">
        <div class="mpr-manage-rds-filler-heading">SET UP RDS TO:</div>
        <div class="mpr-manage-rds-filler-list">
          <ul>
            <li>
              Store and manage structured data in a tabular relational format.
            </li>
            <li>
              Enforce data types using schemas for greater data consistency.
            </li>
            <li>
              Query and process data using SQL (structured query language).
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div *ngIf="rdsClusterState.rdsClusters.length === 0 && !isPlatformAdmin">
      <div class="mpr-dataconnector-table-noresult">
        <div class="mpr-noresult-container">
          <div class="mpr-noresutl-error">
            <span><img src="assets/images/Error.svg" alt="Error" /> </span>
            <span> No RDS clusters are created for this project.</span>
          </div>
        </div>
      </div>
    </div>
    
    <div *ngIf="rdsClusterState.rdsClusters.length > 0">
      <mat-table
        [dataSource]="rdsClusterDataSource"
        class="mpr-tables mpr-cross-accounts-table"
      >
        <ng-container matColumnDef="clusterName">
          <mat-header-cell *matHeaderCellDef>Cluster Name</mat-header-cell>
          <mat-cell *matCellDef="let element">{{
            element.clusterName
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="dbEngine">
          <mat-header-cell *matHeaderCellDef>DB Engine</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.dbEngine ? element.dbEngine : 'NA'}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="dbInstanceCount">
          <mat-header-cell *matHeaderCellDef>Instances</mat-header-cell>
          <mat-cell *matCellDef="let element">{{
            element.dbInstanceCount ? element.dbInstanceCount : 'NA'
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="dateClusterSetup">
          <mat-header-cell *matHeaderCellDef
            >Date Cluster Set Up
            <span
              class="mpr-sort-image mpr-descending-icon"
              role="img"
              aria-label="descending"
            ></span>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{{
            element.dateClusterSetup 
            ? (element.dateClusterSetup | utcDate : 'MM/dd/yyyy')
            : 'NA'
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
          <mat-cell *matCellDef="let element"
            ><span [ngClass]="element.displayStatus">{{
              element.displayStatus
            }}</span></mat-cell
          >
        </ng-container>
        <ng-container matColumnDef="actionMenu">
          <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
          <mat-cell *matCellDef="let rdscluster">
            <button
              aria-label="action"
              class="mpr-button-style mpr-btn-transparency mpr-cursor-pointer"
              [matMenuTriggerFor]="menu"
              mat-menu-trigger
              #trigger="matMenuTrigger"
            >
              <img
                src="assets/images/More.svg"
                alt="Action Menu"
                class="imgClass imgFolder mpr-cursor-pointer mpr-more-icon"
              />
            </button>
            <mat-menu #menu="matMenu" class="mpr-mat-menu-custom-view">
              <button
                mat-menu-item
                class="mpr-cursor-pointer"
                *ngIf="displayViewDetails()"
                (click)="openRDSClusterDetailsDialog(rdscluster)"
              >
                <img
                  src="assets/images/preview.svg"
                  alt=""
                  class="imgClass small-imgTooltip mpr-export"
                />
                <span>View Details</span>
              </button>
              <button
                mat-menu-item
                class="mpr-cursor-pointer"
                (click)="redirectToClusterUserList(rdscluster)"
                *ngIf="displayManageUsers()"
              >
                <img
                  src="assets/images/group.svg"
                  alt=""
                  class="imgClass small-imgTooltip mpr-export"
                />
                <span>Manage Users</span>
              </button>
              
              <button
              mat-menu-item
              class="mpr-cursor-pointer"
              *ngIf="displayStopCluster(rdscluster)"
              (click) = "triggerActionStartOrStop(rdscluster, 'stop')"
            >
              <img
                src="assets/images/stop_circle.svg"
                alt=""
                class="imgClass small-imgTooltip mpr-export"
              />
              <span>Stop</span>
            </button>
            <button
              mat-menu-item
              class="mpr-cursor-pointer"
              *ngIf="displayStartCluster(rdscluster)"
              (click) = "triggerActionStartOrStop(rdscluster, 'start')"
            >
              <img
                src="assets/images/start_circle.svg"
                alt=""
                class="imgClass small-imgTooltip mpr-export"
              />
              <span>Start</span>
            </button>
            <!-- Buttons for future sprints -->
            <!-- <button
              mat-menu-item
              class="mpr-cursor-pointer"
              *ngIf="displayEditCluster()"
            >
              <img
                src="assets/images/Rename.svg"
                alt=""
                class="imgClass imgTooltip mpr-export"
              />
              <span>Rename</span>
            </button> -->
            <button
              mat-menu-item
              class="mpr-cursor-pointer"
              *ngIf="displayDeleteCluster()"
              [disabled]="rdscluster.displayStatus !== 'Stopped'"
              (click)="showDeleteClusterDialog(rdscluster)"
            >
              <img
                src="assets/images/delete.svg"
                alt=""
                class="imgClass imgTooltip mpr-export"
              />
              <span>Delete</span>
            </button>
            </mat-menu>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="details">
          <mat-header-cell *matHeaderCellDef>Details</mat-header-cell>
          <mat-cell *matCellDef="let rdscluster">
              <button
                #skipper
                class="mpr-cursor-pointer mpr-link mpr-btn-transparency"
                (click)="openRDSClusterDetailsDialog(rdscluster)"
                title="View Details"
                aria-label="View Details"
              >
              View
              </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </ng-container>
    <div *ngIf="!showRDS">
      <div class="mpr-dataconnector-table-noresult">
        <div class="mpr-noresult-container">
          <div class="mpr-noresutl-error">
            <span><img src="assets/images/Error.svg" alt="Error" /> </span>
            <span> RDS feature is not enabled for this project</span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #showRDSClusterDetailsDialog let-data>
  <div class="mpr-mat-dialog-small">
    <div class="mpr-dialog-close" (click)="closeDialog()">
      <img src="assets/images/cancel-popup.svg" alt="Close icon" />
    </div>
    <h1 mat-dialog-title>RDS Connection Details</h1>
    <div class="note-content-box">
      <b>
        Use the details below to connect to RDS. Refer to the <a
        class="mpr-help-url"
        href="{{ rdsHelpURL }}"
        target="_blank"
        ><span>user guide</span>
        <img
          class="mpr-new-window-icon"
          src="assets/images/new-window.svg "
          alt=""
        />
        </a> for more info.<br>
        <div class="mpr-warning-text" *ngIf="viewOnlyForProjectAdmin()">
        <img
        class="mpr-red-warning-icon"
        src="assets/images/warning-icon-red-triangle.svg"
        alt=" EC2 provision failed warning"
        />
        Use your Mquiry role or a least-privileged service account whenever possible. 
        Use the RDS master user only for required administrative tasks.
      </div>
      </b>
    </div>
    <div mat-dialog-content class="content-of-popup">
        <div class="form-group" *ngIf="data.rdsCluster">
          <div class="mpr-form-block-field m-t-4">
            <div class="mpr-form-block-field-label text-uppercase">Host Name</div>
            <div class="mpr-rds-details" fxLayout="row">
              <span class="mpr-rds-details-value">{{
                data.rdsCluster.endpoint
              }}</span>
              <span class="mpr-private-space-copy">
                <button
                  class="mpr-icon-button mpr-copy-to-clipboard-icon"
                  (click)="copyToClipBoard(data.rdsCluster?.endpoint)"
                  matTooltip="Copy Host Name"
                  aria-label="Copy Host Name"
                  id="rdsHostName"
                ></button>
              </span>
            </div>
          </div>
          <div class="mpr-form-block-field m-t-4">
            <div class="mpr-form-block-field-label text-uppercase">Port</div>
            <div class="mpr-rds-details" fxLayout="row">
              <span class="mpr-rds-details-value">{{
                data.rdsCluster.port
              }}</span>
              <span class="mpr-private-space-copy">
                <button
                  class="mpr-icon-button mpr-copy-to-clipboard-icon"
                  (click)="copyToClipBoard(data.rdsCluster?.port)"
                  matTooltip="Copy Port"
                  aria-label="Copy Port"
                  id="rdsPort"
                ></button>
              </span>
            </div>
          </div>
          <div class="mpr-form-block-field m-t-4">
            <div class="mpr-form-block-field-label text-uppercase">
              DB User Name
            </div>
            <div class="mpr-rds-details" fxLayout="row">
              <span class="mpr-rds-details-value">{{
                data.rdsCluster.dbUserName
              }}</span>
              <span class="mpr-private-space-copy">
                <button
                  class="mpr-icon-button mpr-copy-to-clipboard-icon"
                  (click)="copyToClipBoard(data.rdsCluster.dbUserName)"
                  [class.disabled]="data.rdsCluster.dbUserName === 'Not added'"
                  matTooltip="Copy DB User Name"
                  aria-label="Copy DB User Name"
                  id="rdsDBUserName"
                ></button>
              </span>
            </div>
          </div>
          <!-- Display Master UserName and Password only for admin -->
          <div *ngIf="viewOnlyForProjectAdmin()">
            <div class="divider-line"></div>
            <div class="mpr-form-block-field m-t-4">
              <div class="mpr-form-block-field-label text-uppercase">Master User Name</div>
              <div class = "mpr-rds-details" fxLayout="row">
                <div class = "mpr-rds-details-value">
                  <span>{{ isUsernameVisible ? data.rdsCluster?.username : '&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;' }}</span>
                  <button class="mpr-eye-icon mpr-btn-transparency" (click)="toggleUsernameVisibility()" aria-label="Toggle Username visisbility">
                    <mat-icon>{{isUsernameVisible ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                </div>
                <span class="mpr-private-space-copy">
                  <button
                    class="mpr-icon-button mpr-copy-to-clipboard-icon"
                    (click)="copyToClipBoard(data.rdsCluster?.username)"
                    matTooltip="Copy Username"
                    aria-label="Copy Username"
                    id = "rdsUsername"
                  ></button>
                </span>
              </div>
            </div>
            <div class="mpr-form-block-field m-t-4">
              <div class="mpr-form-block-field-label text-uppercase">Password</div>
              <div class = "mpr-rds-details" fxLayout="row">
                <div class = "mpr-rds-details-value">
                  <span>{{ isPasswordVisible ? data.rdsCluster?.password : '&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;' }}</span>
                  <button class="mpr-eye-icon mpr-btn-transparency" (click)="togglePasswordVisibility()" aria-label="Toggle Password visisbility">
                    <mat-icon>{{isPasswordVisible ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                </div>
                <span class="mpr-private-space-copy">
                  <button
                    class="mpr-icon-button mpr-copy-to-clipboard-icon"
                    (click)="copyToClipBoard(data.rdsCluster?.password)"
                    matTooltip="Copy Password"
                    aria-label="Copy Password"
                    id = "rdsPassword"
                  ></button>
                </span>
              </div>
            </div>
        </div>
        </div>
    </div>
  </div>
</ng-template>
<ng-template #showWarningDialog let-data>
  <div class="mpr-mat-dialog">
    <div class="mpr-close-icon mpr-warning-close-icon" (click)="closeDialog()">
    <img class="close-icon" src="assets/images/cancel-popup.svg" alt="Close icon" />
    </div>
    <div mat-dialog-content class="content-of-popup">
      <div class="mpr-warning">
        <img
        class="mpr-red-warning"
        src="assets/images/warning-icon-red-triangle.svg"
        alt="Warning Message"
        />
        <span>
          {{data.message}}
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loader>
  <div class="d-flex mpr-justify-content-center mpr-table-loader">
    <span>
      <mat-spinner diameter="45" color="accent"></mat-spinner>
    </span>
  </div>
</ng-template>
