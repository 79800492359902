import { CommonResponseModel } from '@shared';
 
export interface ProjectSurveyModel {
  createdDate: string;
  projectId: string;
  projectSurveyId: string;
  surveyId: string;
  surveyName: string;
}
 
export interface ProjectSurveyStateModel {
  commonResponse : CommonResponseModel;
  deleteLoader: boolean;
  deleteResponse: [];
  loader: boolean;
  projectSurveys: ProjectSurveyModel[] | [];
}