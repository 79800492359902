import { DeleteUserList } from './delete-user-list-model';
import { ProjectUserList } from './project-user-list-model';

export default interface ProjectCountModel {
  count: number;
  deleteResponse?: Array<DeleteUserList>;
  loader: boolean;
  projectCount?: number;
  projectUsersList: Array<ProjectUserList>;
  userDetails?: ProjectUserList;
}
