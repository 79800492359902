export interface Contents {
  checked?: boolean;
  displayName?: string;
  folder?: string;
  icon: string;
  modified: string;
  name: string;
  path: string;
  pickerPath?: string;
  size?: number;
  type?: string;
}
