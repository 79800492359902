<ng-container *ngIf="listDetails | async as listData">
  <div class="mpr-project-name" >
    PROJECT : {{selectedProjectId}} </div>
  <div class="mpr-list-container">
    <div class="mpr-list">
      List of {{ listType }} {{ projectUserCount }} ({{ listData.length }})
    </div>
    <span class="mpr-list-container">
      <mpr-export-to-csv
        *ngIf="listData.length > 0"
        (exportMethod)="exportToCSV(listData)"
      ></mpr-export-to-csv>
      <button
        class="mpr-add-users mpr-btn-transparency"
        (click)="navigateToAddusers()"
      >
        Add New User(s)
      </button>
    </span>
  </div>
  <div class="mpr-list-details d-flex">
    <div class="flex-1 mpr-border" *ngFor="let list of listData">
      <div class="mpr-list-action">
        <div class="mpr-name">
          {{ list.firstName }} {{ list.lastName
          }}<span *ngIf="list.userStatus === userStatusInvited">
            (Invited)</span
          >
        </div>
        <div class="d-flex">
          <div>
            <button class="mpr-cursor-pointer mpr-link mpr-btn-transparency">
              <a (click)="navigateToEditUser(list)">
                <img
                  src="assets/images/Edit.svg"
                  alt=""
                  class="mpr-delete-image"
                />
                <span class="mpr-edit-text">Edit</span>
              </a>
            </button>
          </div>
          <div *ngIf="list.emailId !== loggedinUserEmail">
            <button
              (click)="showDeleteConfirmation(list)"
              class="mpr-cursor-pointer mpr-link mpr-btn-transparency"
              [ngClass]="{
                'mpr-disabled-button' : isDeleteInProgress
              }"
              [disabled]="isDeleteInProgress"
            >
              <img
                src="assets/images/delete.svg"
                alt=""
                class="mpr-delete-image"
              />
              <span class="mpr-delete-text">Delete</span>
            </button>
          </div>
        </div>
      </div>
      <div class="mpr-email">{{ list.emailId }}</div>
      <div class="mpr-restricted-group-details mpr-margin-top">
        <div class="mpr-restricted-group" *ngIf="list.roleName">
          {{ list.roleName | replaceRoleName }}
        </div>
        <div class="mpr-restricted-group" *ngIf="list.machineSize">
          {{ list.machineSize }}
        </div>
        <div class="mpr-power-user" *ngIf="list.isPowerUser">
          <img
            src="assets/images/PowerUser.svg"
            alt="PowerUser"
            class="mpr-power-image"
          />
          <span class="mpr-edit-text">Power User</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
